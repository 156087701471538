import PublishIcon from '@mui/icons-material/Publish';
import { Collapse, Tooltip } from '@mui/material';
import { RibbonButton } from '../Ribbon';
import { useFormContext } from 'react-hook-form';
import { useDashboard } from '../useDashboard';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';

export const RepublishViewButton = ({ expanded, ...buttonProps }) => {
    const { getValues, watch } = useFormContext();
    const { views, handleAddNewOrUpdate } = useDashboard();
    const isShared = watch('shared');
    const createdByGuid = watch('createdByGuid');
    const guid = useLocalGuid();
    const allowRepublish = isShared && createdByGuid === guid;

    function handleRepublish() {
        const view = views.find(v => v.id === getValues('id')) ?? {};
        //iterate over keys of view and update them with the new values; we do this as we may not want to save all the values in the form
        let updatedView = { ...view };
        Object.keys(view).forEach(key => {
            const newVal = getValues(key);
            if (!!newVal) {
                updatedView[key] = getValues(key);
            }
        });
        handleAddNewOrUpdate(updatedView);
    }

    return (
        <Collapse in={allowRepublish} orientation='horizontal'>
            <Tooltip title="Save you current view along with the grid layout.">
                <span>
                    <RibbonButton
                        onMouseDown={handleRepublish}
                        icon={<PublishIcon fontSize='small' />}
                        label={'Republish'}
                        expanded={expanded}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </Collapse>
    );
}