import { useRef, useMemo, useState, useCallback, forwardRef, useEffect } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { heatmapStyle, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { PaletteToolPanel } from "./PaletteToolPanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { colorForValue } from "../Utils"
import { useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useDashboard } from "../useDashboard"
import { logAction } from "../../../utils/auditLogger"
import { useUserGroups } from "../../../data/useUserGroups"
import { userGroups } from "../../../authConfig"
import { useRowDebounce } from "../useRowDebounce"

export const CheckoutWorksheetGrid = forwardRef(({ setSelectedRow, date, getRowId, layoutStorageKey, silentUpdate, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteStorageKey = 'checkout-worksheet-palette';
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { post, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();
    const { visible: dashboardVisible } = useDashboard();
    const { getValues } = useFormContext();
    const params = useParams();
    const viewId = params.id;
    const gridIsVisible = dashboardVisible && viewId === getValues('id').toString();
    const { userIsInGroup } = useUserGroups();
    const allowEdit = userIsInGroup(userGroups.tenantAdmin);

    const userInfo = useUserInfo();
    const { layout, } = getValues();

    const heatmapStyle = (params, paletteRef, key) => {
        let styles = {};
        if (!!paletteRef.current?.[key] && !!params.value) {
            styles = {
                backgroundColor: colorForValue(params.value),
                textShadow: 'black 1px 1px 4px',
                color: 'white',
            }
        }
        return styles;
    };

    const handleModifyDeal = useCallback(async (updatedRow) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDeal_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.dealID}`
            + `&parm=${updatedRow.flowDate}`
            + `&parm=${updatedRow.flowDateTimeZone}`

        if (updatedRow[27]) {
            enqueueSnackbar(`HE 27 detected and removed. MW: ${updatedRow[27].MW}, Price: ${updatedRow[27].MW}`, { variant: 'warning' });
            console.log('HE 27 detected and removed. MW: ', updatedRow[27].MW, 'Price: ', updatedRow[27].Price);
            logAction('HE 27 detected and removed', 'Deal Position', headers.userGuid, updatedRow);
            delete updatedRow[27];
        }

        return post(url, JSON.stringify(updatedRow)).then(response => {
            enqueueSnackbar("Deal Profile updated successfully", { variant: "success" });
        });
    }, [enqueueSnackbar, headers]);

    const { batchRowUpdate } = useRowDebounce({ onTimeExpired: handleModifyDeal });

    function handleCellValueChanged(event) {
        batchRowUpdate(event);
    }

    const baseHourColDef = useMemo(() => ({
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        cellDataType: 'number',
        editable: params => !params.node.isRowPinned() && allowEdit,
        width: 60,
        aggFunc: 'sum',
        enableRowGroup: false,
        resizable: true,
    }), [allowEdit])

    const defaultHourColChildren = useCallback((i) => ([
        {
            ...baseHourColDef,
            headerName: 'MW',
            cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
            valueGetter: (params) => {
                return params.data?.[i]?.MW;
            },
            valueSetter: (params) => {
                if (!params.data[i]) return false;

                const value = params.newValue;
                const Transaction_Type = params.data?.Transaction_Type;
                const saleAndPositive = Transaction_Type === 'Sale' && value > 0;
                const purchaseAndNegative = Transaction_Type === 'Purchase' && value < 0;
                if (saleAndPositive || purchaseAndNegative) {
                    params.data[i].MW = -value;
                } else {
                    params.data[i].MW = value;
                }
                return true;
            },
        },
        {
            ...baseHourColDef,
            field: `${i}.Price`,
            headerName: '$',
            cellStyle: params => heatmapStyle(params, paletteRef, 'showPriceHeatmap'),
        },
    ]), []);

    const baseColDefs = useMemo(() => ([
        {
            field: 'dealID',
            headerName: 'Deal ID',
            cellDataType: 'text',
        },
        { field: 'Deal_Number', headerName: 'Deal Name', },
        { field: 'Deal_Currency', headerName: 'Deal Currency', },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        { field: 'Contract', },
        { field: 'Status', },
        { field: 'Counterparty', },
        { field: 'Market', },
        { field: 'Term', },
        {
            field: 'Start_Date',
            headerName: 'Start',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'End_Date',
            headerName: 'End',
            filter: 'agDateColumnFilter',
        },
        { field: 'Type_F_P', headerName: 'Transaction', },
        { field: 'Transaction_Type', headerName: 'Transaction Type', },
        { field: 'index_name', headerName: 'Index', },
        { field: 'Creator', headerName: 'Creator', },
        { field: 'indexType', headerName: 'RT/DA', },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Price Curve',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push({
                headerName: i,
                field: i,
                minWidth: 20,
                initialWidth: 50,
                children: defaultHourColChildren(i),
            });

            if (i === 2) {
                acc.push({
                    headerName: '2*',
                    field: '2*',
                    minWidth: 20,
                    initialWidth: 50,
                    children: defaultHourColChildren('2*').map(colDef => ({
                        ...colDef,
                        //hide: true,
                    })),
                });
            }

            return acc;
        }, []),
    ]), [allowEdit]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Deal changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['dealUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo?.tenantId?.toString())
                && (obj.userName !== userInfo.userName || !gridIsVisible); //ignore own updates, unless it was caused on a different grid
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, paletteStorageKey),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleUpdateTotalRows = (api, totalColKey) => {
        const visibleRows = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            visibleRows.push(node.data);
        });

        if (!visibleRows?.length) return [];
        const totalRows = [visibleRows.reduce((acc, next) => {
            if (typeof next !== 'object') return acc;
            Array.from({ length: 26 }, (_, i) => i + 1).forEach(hour => {
                const mwSum = parseFloat(acc[hour]?.MW ?? 0) + parseFloat(next[hour]?.MW ?? 0);
                const priceSum = parseFloat(acc[hour]?.Price ?? 0) + parseFloat(next[hour]?.Price ?? 0);
                acc[hour] = {
                    MW: parseFloat(mwSum.toFixed(2)),
                    Price: parseFloat(priceSum.toFixed(2)),
                }
            });
            return acc;
        }, { [totalColKey]: 'Total' })];
        api.setPinnedBottomRowData(totalRows);
    };

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, styledColDefs[0]?.field ?? 'dealID');
    }, [styledColDefs]);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
            "sale-row": params => params.data?.Transaction_Type === 'Sale',
            "purchase-row": params => params.data?.Transaction_Type === 'Purchase',
        },
    }), []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    // // Inject CSS styles directly into the component
    // useEffect(() => {
    //     const style = document.createElement('style');

    //     // .purchase-row {
    //     //     background-color: #fffaf0; /* Very light beige for Purchase rows */
    //     // }

    //     style.innerHTML = `
    //         .sale-row {
    //             background-color: #f9f9f9 ; /* Light grey for Sale rows */
    //         }

    //     `;
    //     document.head.appendChild(style);
    //     return () => {
    //         document.head.removeChild(style);
    //     };
    // }, []);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '99%', }}
                ref={ref}
                getRowId={getRowId}
                gridOptions={gridOptions}
                enableCharts
                columnDefs={styledColDefs}
                onSelectionChanged={handleSelectionChanged}
                rowSelection="single"
                editType="fullRow"
                onCellValueChanged={handleCellValueChanged}
                stopEditingWhenCellsLoseFocus={true}
                rowMultiSelectWithClick
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={updateTotalRows}
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                groupTotalRow={"bottom"}
                suppressAggFuncInHeader={true}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                suppressLastEmptyLineOnPaste
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    )
});
