import { defaultColumnDef, defaultGridOptions } from "../../../AgGrid/defaultGridProps";
import { DateTimeCellEditor } from "../../../AgGrid/DateTimeCellEditor";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'
import '../../../../styles/gridStyleOverrides.css'
import { useColumnTypes } from "../../../AgGrid/useColumnTypes";
import { useCallback, useMemo, useRef } from "react";
import dayjs from "dayjs";
import { columnPanel, filterPanel } from "../../../ToolPanels/DefaultToolPanels";
import useGridLayout from "../../../useGridLayout";
import { LayoutToolPanel } from "../../../ToolPanels/LayoutToolPanel";

export const ProfileGrid = ({ data, refreshChart }) => {
    const layoutStorageKey = 'deal_entry_profile_grid_layout';
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const formatString = 'MM/DD/YYYY HH:mm';

    const baseColDefs = useMemo(() => [
        {
            checkboxSelection: true,
            editable: true,
            headerName: 'Start',
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            type: 'dateColumn',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.startDateTime;
            },
            valueSetter: params => {
                params.data.startDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            headerName: 'Stop',
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            type: 'dateColumn',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.endDateTime;
            },
            valueSetter: params => {
                params.data.endDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            field: 'capacityRequested',
            headerName: 'MW',
            editable: true,
        },
        {
            field: 'price',
            headerName: 'Price',
            //...priceColParams,
            editable: true,
        },
    ], [])

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        flex: 1,
    }), []);

    const { layoutPanel, colDefs, loadLayout } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
                layoutPanel,
            ],
            position: 'right',
        }
    }, []);

    const gridOptions = {
        rowClassRules: {
            "row-missing-price": params => params.data.price === '',
        },
    };

    const onGridReady = useCallback(params => {
        loadLayout();
    }, [loadLayout]);

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={gridRef}
            rowData={data}
            sideBar={sideBar}
            columnDefs={colDefs}
            columnTypes={columnTypes}
            onGridReady={onGridReady}
            onCellValueChanged={refreshChart}
            overlayNoRowsTemplate={'<span>Add blocks to view and edit profile information here.</span>'}
            rowSelection='multiple'
            gridOptions={gridOptions}
            components={{
                dateEditor: DateTimeCellEditor,
                layoutToolPanel: LayoutToolPanel,
            }}
        />
    );
}