import { useState, useEffect, } from "react";
import ProfileDialog from "../ProfileDialog";
import useFilteredOptions from "../TSRActivity/useFilteredOptions";
import { object, string, array, date, } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormAutocomplete from "../FormControls/FormAutocomplete";
import FormCheckbox from "../FormControls/FormCheckbox";
import CornerRibbon from 'react-corner-ribbon';
import { Stack, Box, Typography, Button, Grid, TextField, Tooltip, FormControl, FormControlLabel, } from "@mui/material"
import momentTimezones from "../momentTimezones";
import dayjs from "../dayjs-tz";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSnackbar } from "notistack";
import { ReservationActionForm } from "./ReservationActionForm";
import { useTheme } from "@emotion/react";

export const TransmissionForm = ({ defaultValues, onSubmit, resetDependency, scheduleDateTime, allowActions = true, disabled, formNumber = 0, fixedTimezone = false, visible = true, version = 1, onFormError, disableChangeAction, }) => {
    const [showMore, setShowMore] = useState(false);
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    useEffect(() => {
        reset(defaultValues);
    }, [resetDependency])

    const schema = object().shape({
        timezone: string().required('Timezone is required.'),
        Provider: string().required('Provider is required.'),
        PointOfReceipt: string().required('POR is required.'),
        PointOfDelivery: string().required('POD is required.'),
        Service_Increment: string().required('Service Increment is required.'),
        TSClass: string().required('TS Class is required.'),
        TSPeriod: string().required('TS Period is required.'),
        TSWindow: string().required('TS Window is required.'),
        TSType: string().required('TS Type is required.'),
        profileInfo: array().min(1, 'Please create a profile.'),
    })

    const { register, handleSubmit, reset, control, formState: { errors, }, watch, setValue, getValues } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onTouched',
    });

    const profile = watch('profileInfo');
    useEffect(() => {
        if (!profile?.length && defaultValues?.profileInfo) {
            setValue('profileInfo', defaultValues.profileInfo);
        }
    }, [visible])

    const watchFields = ['Provider', 'PointOfReceipt', 'PointOfDelivery', 'Source', 'Sink', 'PathName', 'Service_Increment', 'TSClass', 'TSWindow', 'TSPeriod', 'TSSubclass', 'TSType',];
    const currentValues = watchFields.reduce((acc, field) => {
        acc[field] = getValues(field);
        return acc;
    }, {});

    const { providers, filteredCombos, filteredTSCombos, customerCodes, relatedRefs, } = useFilteredOptions(currentValues);

    const maxProfile = watch('maxProfile');
    const timezone = watch('timezone');

    function handleProfileUpdate(profileInfo) {
        setValue('profileInfo', profileInfo);
        setOpenProfileDialog(false);
    }

    const twoMinutesFromNow = dayjs().tz(momentTimezones[timezone]).add(2, 'minute').startOf('minute')
    function handleUpdateAction(action) {
        if (!scheduleDateTime) {
            setValue('targetExecutionTime', twoMinutesFromNow);
        }
        setValue('action', action);
    }

    function onError(errors) {
        if (errors.profileInfo) {
            enqueueSnackbar(`${errors.profileInfo.message}`, { variant: 'error' });
        } else {
            const firstError = Object.keys(errors)[0];
            enqueueSnackbar(`${errors[firstError].message}`, { variant: 'error' });
        }
        onFormError && onFormError(errors);
    }

    const provider = watch('Provider');
    const path = watch('PathName');
    const warnUserAboutBPATPaths = !!provider?.includes('BPAT') && !!path;
    const pathWarningHelperText = warnUserAboutBPATPaths ? 'BPAT will generate an error in most situations if path name is included.' : '';

    return (
        <Box sx={{ p: 1, }}>
            {visible && <ProfileDialog
                open={openProfileDialog}
                closeDialog={() => setOpenProfileDialog(false)}
                defaultInfo={defaultValues?.profileInfo ?? []}
                maxInfo={maxProfile}
                handleProfileSave={handleProfileUpdate}
                disabled={disabled}
                defaultValues={{
                    ...defaultValues?.profileConfig,
                }}
                version={version}
            />}
            <form id={`tsr-transmission-form-${formNumber}`} onSubmit={handleSubmit(onSubmit, onError)}>
                {disabled && <CornerRibbon style={{ fontSize: 14 }}>
                    <Stack>
                        <Typography>Upgrade Required to Transact!</Typography>
                        <Button
                            sx={{ color: theme.palette.primary.white }}
                            href="https://webforms.pipedrive.com/f/5X4yAVMERh0y8mGszS6ntpwcPhTToxMyqwqNXhrZXfn7xl0R187lTPeBzlp0B4jxCP"
                        >Go here to request upgrade or email support@powersysops.com</Button>
                    </Stack>
                </CornerRibbon>}
                <Grid container direction={'column'}>
                    {showMore
                        ? (<Grid container justifyItems='start' alignItems='center' style={{ marginTop: '10px' }} spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    startIcon={<ChevronLeftIcon />}
                                    variant="contained"
                                    size='small'
                                    onClick={() => setShowMore(false)}
                                >Back</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    placeholder="Customer Comments"
                                    {...register('Customer_Comments')}
                                    rows={5}
                                    variant='outlined'
                                    label='Customer Comments'
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={'Sales Ref'}
                                    size='small'
                                    fullWidth
                                    {...register('SaleRef')}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>)
                        : (<Grid container justifyContent="center" alignItems='start' style={{ marginTop: '10px' }} spacing={2}>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name={'timezone'}
                                    control={control}
                                    fullWidth
                                    options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                                    disabled={disabled || fixedTimezone}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Timezone"
                                            size='small'
                                            color="success"
                                            error={!!errors.timezone}
                                            helperText={errors.timezone?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='Provider'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={providers}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Provider"
                                            color="success"
                                            placeholder="Provider"
                                            error={!!errors.Provider}
                                            helperText={errors.Provider?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormAutocomplete
                                    name={'PathName'}
                                    control={control}
                                    fullWidth
                                    disabled={disabled}
                                    options={filteredCombos.paths}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label="Path Name"
                                            //warning={warnUserAboutBPATPaths}
                                            className={warnUserAboutBPATPaths ? 'warning-text-field' : ''}
                                            helperText={pathWarningHelperText}
                                            placeholder="Path Name"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Click to set additional fields such as customer code." arrow placement="top">
                                    <Button
                                        startIcon={<ChevronRightIcon />}
                                        variant="contained"
                                        fullWidth
                                        onClick={() => setShowMore(true)}
                                    >More</Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='PointOfReceipt'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredCombos.pors}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="POR"
                                            color="success"
                                            placeholder="POR"
                                            error={!!errors.PointOfReceipt}
                                            helperText={errors.PointOfReceipt?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='PointOfDelivery'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredCombos.pods}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="POD"
                                            color="success"
                                            placeholder="POD"
                                            error={!!errors.PointOfDelivery}
                                            helperText={errors.PointOfDelivery?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='Source'
                                    control={control}
                                    freeSolo
                                    disabled={disabled}
                                    fullWidth
                                    options={filteredCombos.sources}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Source"
                                            color="success"
                                            placeholder="Source"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='Sink'
                                    control={control}
                                    freeSolo
                                    fullWidth
                                    disabled={disabled}
                                    options={filteredCombos.sinks}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Sink"
                                            color="success"
                                            placeholder="Sink"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormAutocomplete
                                    name='CustomerCode'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={customerCodes}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="Customer Code"
                                            color="success"
                                            placeholder="Customer Code"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} justifyContent='flex-start'>
                                <FormAutocomplete
                                    name='Service_Increment'
                                    control={control}
                                    options={filteredTSCombos.serviceIncrements}
                                    disabled={disabled}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Service Increment"
                                            size='small'
                                            color="success"
                                            error={!!errors.Service_Increment}
                                            helperText={errors.Service_Increment?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='TSClass'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredTSCombos.tsClasses}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="TS Class"
                                            color="success"
                                            placeholder="TS Class"
                                            error={!!errors.TSClass}
                                            helperText={errors.TSClass?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='TSType'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredTSCombos.tsTypes}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="TS Type"
                                            color="success"
                                            placeholder="TS Type"
                                            error={!!errors.TSType}
                                            helperText={errors.TSType?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='TSPeriod'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredTSCombos.tsPeriods}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="TS Period"
                                            color="success"
                                            placeholder="TS Period"
                                            error={!!errors.TSPeriod}
                                            helperText={errors.TSPeriod?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormAutocomplete
                                    name='TSWindow'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredTSCombos.tsWindows}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="TS Window"
                                            color="success"
                                            placeholder="TS Window"
                                            error={!!errors.TSWindow}
                                            helperText={errors.TSWindow?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormAutocomplete
                                    name='TSSubclass'
                                    control={control}
                                    fullWidth
                                    freeSolo
                                    disabled={disabled}
                                    options={filteredTSCombos.tsSubclasses}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            label="TS Subclass"
                                            color="success"
                                            placeholder="TS Subclass"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth size="small">
                                    <FormControlLabel label={'Pre-confirmed'} control={
                                        <FormCheckbox name='Preconfirmed' control={control} disabled={disabled} />
                                    } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    onClick={() => setOpenProfileDialog(true)}
                                    variant="contained"
                                    fullWidth
                                >Set Profile</Button>
                            </Grid>
                            {!!allowActions && <ReservationActionForm
                                timezone={timezone}
                                disabled={disableChangeAction || disabled}
                                handleActionChange={handleUpdateAction}
                                control={control}
                                errors={errors}
                                action={watch('action')}
                            />}
                        </Grid>
                        )}
                </Grid>
            </form>
        </Box>
    );
};