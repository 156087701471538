import { useImperativeHandle, useRef, forwardRef, useState, useCallback, useMemo, } from "react"
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, } from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess";
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { PositionManagementGrid } from "./PositionManagementGrid"
import { PositionManagementChart } from "./PositionManagementChart"
import { useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { PositionManagementRibbon } from "./Ribbon/PositionManagementRibbon";
import * as yup from 'yup';
import { ViewContextProvider } from "../ViewContextProvider";
import dayjs from "dayjs";
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { useDashboardFetch } from "../useDashboardFetch";
import { useApi } from "../../useApi";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    flowDate: yup.date().required('Flow date is required'),
});

export const PositionManagementView = forwardRef(({ view, }, ref) => {
    const gridRef = useRef();
    const [selectedRow, setSelectedRow] = useState();
    const { id, } = view;
    const theme = useTheme();
    const [chartData, setChartData] = useState();
    const { captureLayout } = useLayoutFunctions();
    const { headers, logAction, } = useApi();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        flowDate: dayjs(),
    }), [view]);

    const toolbarFormId = `position-management-toolbar-form-${id}`;

    const getRowId = useCallback(({ data }) => `${data.dealID}+${data.type}+${data.scheduleID}+${data.tagIdx}`, []);

    const { loadData, applyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchPositionManagement_v9',
        fetchParamKeys: ['userGuid', 'flowDate', 'timezone'],
        gridRef,
        getRowId,
    });

    function handleFetchData(data) {
        logAction('User fetched Position MGMT Data', 'Position MGMT', data);
        loadData(formatDataForRequest(data)).then(formatDataFromResponse).then(applyData).then(response => {
            if (response?.data?.length) {
                setChartData(response.data);
            }
        });
    }

    function formatDataForRequest(data) {
        return {
            ...data,
            userGuid: headers.userGuid,
            flowDate: dayjs(data.flowDate).format('MM/DD/YYYY'),
        };
    }

    function formatDataFromResponse(response) {
        const data = response?.data;
        const formatted = data?.map(row => {
            const newRow = { ...row };
            Array.from({ length: 26 }).forEach((_, i) => {
                const hour = i + 1;
                newRow[hour] = toTwoDecimalPlaces(row[hour]);
            });

            newRow['2*'] = toTwoDecimalPlaces(row['2*']);

            return newRow;
        });
        return { data: formatted };
    };

    function toTwoDecimalPlaces(value) {
        const truncated = value ? parseFloat(parseFloat(value).toFixed(2)) : null;
        return truncated;
    }

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
                <PositionManagementRibbon
                    toolbarFormId={toolbarFormId}
                    gridRef={gridRef}
                    selectedRow={selectedRow}
                    handleFetchData={handleFetchData}
                />
                <PositionManagementGrid
                    ref={gridRef}
                    context={view}
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                />
                <Accordion
                    disableGutters
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandLess />}
                        sx={{ minHeight: '24px', py: theme.spacing(0.5) }}
                    >
                        <Typography>Chart</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ p: 0, }}>
                        <AgGridContainer style={{ height: '35vh' }}>
                            <PositionManagementChart
                                data={chartData}
                            />
                        </AgGridContainer>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </ViewContextProvider>
    )
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));
