import { useState, } from "react";
import { List, Divider, ListItemButton, ListItemIcon, ListItemText, Tooltip, } from "@mui/material"
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import GroupGuard from '../Guards/GroupGuard.js';
import { userGroups } from '../../authConfig.js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import CornerRibbon from 'react-corner-ribbon';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Drawer } from '../StyledDrawer.js';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default (props) => {
  const { selected, handleSelection } = props;
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <List>
        <GroupGuard authenticatedOnly>
          <Tooltip title="View information about Fast Path" arrow placement="right">
            <ListItemButton
              selected={selected === 'fastpathhelp'}
              onClick={() => handleSelection('fastpathhelp')}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="Fast Path Help" />
            </ListItemButton>
          </Tooltip>
          <Divider />
        </GroupGuard>
      </List>
      <List>
        <ListItemButton
          selected={selected === 'fastpath'}
          onClick={() => handleSelection('fastpath')}>
          <ListItemIcon>
            <ForkLeftIcon />
          </ListItemIcon>
          <ListItemText primary="Fast Path" />
        </ListItemButton>
        <GroupGuard authenticatedOnly>
          <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
            <ListItemButton
              selected={selected === 'activity'}
              onClick={() => handleSelection('activity')}>
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="TSR Activity" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <Tooltip title="View your favorite routes." arrow placement="right">
          <ListItemButton
            selected={selected === 'dashboard'}
            onClick={() => handleSelection('dashboard')}>
            <ListItemIcon>
              <DashboardCustomizeIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Tooltip>
        <GroupGuard groups={[userGroups.fastpathpro]}>
          <Tooltip title="View and edit your transaction alerts." arrow placement="right">
            <ListItemButton
              selected={selected === 'transactionqueue'}
              onClick={() => handleSelection('transactionqueue')}>
              <ListItemIcon>
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Trans Q" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.fastpathpro]}>
          <Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
            <ListItemButton
              selected={selected === 'tariffprices'}
              onClick={() => handleSelection('tariffprices')}>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Tariff Prices" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <ListItemButton
              selected={selected === 'legacy'}
              onClick={() => handleSelection('legacy')}>
              <ListItemIcon>
                <ElectricBoltIcon />
              </ListItemIcon>
              <ListItemText primary="Old Fast Path" />
            </ListItemButton>
          </div>
        </GroupGuard>
        <GroupGuard groups={[userGroups.fastpathpro]}>
          <Tooltip title="See the audit log." arrow placement="right">
            <ListItemButton
              selected={selected === 'audit'}
              onClick={() => handleSelection('audit')}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Audit Log" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
      </List>
    </Drawer>
  )
}