import { useState, useEffect } from 'react';
import PPWTrans from '../components/TransNow/PPW/PPWTrans';
import TSRActivity from './TSRActivity';
import Box from '@mui/material/Box';
import BulkReassignmentGrid from '../components/TransNow/BulkOriginalRequests';
import TransNowAuditGrid from '../components/TransNow/TransNowAuditGrid';
import RealTimeGrids from '../components/TransNow/RealTimeGrids';
import DayAheadGrids from '../components/TransNow/DayAheadGrids';
import TransNowAppMenu from '../components/TransNow/TransNowAppMenu';
import { userGroups } from "../authConfig";
import Collapse from '@mui/material/Collapse';
import RealTimeOriginalRequests from '../components/TransNow/RealTimeOriginalRequests';
import TariffPrices from '../components/TransNow/TariffPrices';
import TransNowHelp from '../components/Help';
import '../styles/transnowStyles.css'
import DayAheadRedirects from '../components/TransNow/DayAhead/Redirects';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import DivGuard from '../components/Guards/DivGuard';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';

function TransnowContent() {

  const defaultView = localStorage.getItem('transNowView');
  const [view, setView] = useState(defaultView || 'transnowhelp');
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (view && !loadedViews[view]) {
      setLoadedViews({
        ...loadedViews,
        [view]: true
      })
    }
  }, [view]);

  const handleSelection = (viewName) => {
    //localStorage.removeItem('transNowView');
    localStorage.setItem('transNowView', viewName);
    setView(viewName);
  };

  const MenuOptions = [
    {
      name: 'tsractivity',
      component: <TSRActivity visible={view === 'tsractivity'} application='transnow' />
    },
    {
      name: 'audit',
      component: <TransNowAuditGrid />
    },
    {
      name: 'reassignments',
      component: <BulkReassignmentGrid />
    },
    {
      name: 'dayahead',
      component: <DayAheadGrids />
    },
    {
      name: 'originalrequests',
      component: <BulkReassignmentGrid />
    },
    {
      name: 'redirects',
      component: <BulkReassignmentGrid />
    },
    {
      name: 'realtime',
      component: <DivGuard groups={[userGroups.transnow, userGroups.realtimegrids, userGroups.realtimeoriginals, userGroups.tea, userGroups.teaadvanced]}>
        <RealTimeGrids visible={view === 'realtime'} />
      </DivGuard>
    },
    {
      name: 'realtimeoriginalrequests',
      component: <RealTimeOriginalRequests />
    },
    {
      name: 'tariffprices',
      component: <TariffPrices />
    },
    {
      name: 'transnowhelp',
      component: <TransNowHelp appName={'TransNow'} />
    },
    {
      name: 'ppwtrans',
      component: <PPWTrans />
    },
    {
      name: 'dayaheadredirects',
      component: <CriteriaProvider>
        <DayAheadRedirects visible={view === 'dayaheadredirects'} />
      </CriteriaProvider>
    },
    {
      name: 'transactionqueue',
      component: <CriteriaProvider>
        <PathMonitor application={'transnow'} />
      </CriteriaProvider>
    }
  ]

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <TransNowAppMenu handleSelection={handleSelection} selected={view} />
      <Box
        component="main"
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        {MenuOptions.map(option =>
          loadedViews[option.name] && <Collapse in={view === option.name}>
            {option.component}
          </Collapse>
        )}
      </Box>
    </Box>
  );
}

export default function Transnow() {
  return <TransnowContent />;
}