import { RibbonButton } from "../Ribbon";
import { LookupValuesProvider } from "../DealEntry/LookupContext";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";
import { useState } from "react";
import Publish from "@mui/icons-material/Publish";
import { useFormContext } from "react-hook-form";
import { Tooltip } from "@mui/material";

export const CreateDealButton = ({ expanded, disabled, formId, ...buttonProps }) => {
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);
    const { onSubmit, handleSubmit } = useFormContext();

    return (
        <>
            <LookupValuesProvider>
                <DealEntryDialog
                    open={openDealEntryDialog}
                    dealToEdit={null}
                    handleClose={() => setOpenDealEntryDialog(false)}
                    handleSave={() => {
                        setOpenDealEntryDialog(false);
                        //submit the form to refresh the grid
                        handleSubmit(onSubmit)();
                    }}
                />
            </LookupValuesProvider>
            <Tooltip title="Create a new deal.">
                <span>
                    <RibbonButton
                        onMouseDown={() => {
                            setOpenDealEntryDialog(true);
                        }}
                        icon={<Publish fontSize='small' />}
                        label={'Create'}
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}