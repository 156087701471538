import { useRef, useMemo, forwardRef, useCallback, useEffect } from "react"
import { AgGridReact } from "ag-grid-react"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { heatmapStyle, } from "../Utils"
import { useDashboardLayout } from "../useDashboardLayout"
import { useApi } from "../../useApi"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter, generateTotalRowData } from "../Utils"
import { useTheme } from "@mui/material"
import { useColorMode } from "../../../styles/ColorMode/useColorMode"
import { useRowDebounce } from "../useRowDebounce"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { useSearchParams } from "react-router-dom"

export const PositionManagementGrid = forwardRef(({ context, setSelectedRow, getRowId, }, ref) => {
    const paletteRef = useRef({ showHeatmap: false });
    const theme = useTheme();
    const layoutStorageKey = `deal-rizz-position-management-grid-layout-${context.id}`;
    const { post, headers, apiUrlPrefix, enqueueSnackbar, logAction } = useApi();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const [search] = useSearchParams();

    // Define your custom aggregation function in the aggFuncs object
    const aggFuncs = useMemo(() => {
        return {
            'myNetPosition': params => {
                let sum = 0;
                params.values.forEach(value => sum += value);
                return sum;
            }
        };
    }, []);

    const handleRowModification = useCallback(async (updatedRow) => {
        let url = ''

        if (updatedRow.type === 'Schedule/Tag' || updatedRow.type === 'Schedule') {
            // URL for Schedule/Tag
            url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifySchedule_v3`
                + `&parm=${headers.userGuid}`
                + `&parm=${updatedRow.scheduleID}`
                + `&parm=${updatedRow.dealID}`
                + `&parm=${updatedRow.flowDate}`
                + `&parm=${updatedRow.flowDateTimeZone}`;
        } else if (updatedRow.type === 'Deal' || updatedRow.type === 'Deal/Forecast') {
            // URL for Deal or Deal/Forecast
            url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDealMW_v3`
                + `&parm=${headers.userGuid}`
                + `&parm=${updatedRow.dealID}`
                + `&parm=${updatedRow.flowDate}`
                + `&parm=${updatedRow.flowDateTimeZone}`;
        }

        if (updatedRow[27]) {
            enqueueSnackbar(`HE 27 detected and removed. MW: ${updatedRow[27].MW}, Price: ${updatedRow[27].MW}`, { variant: 'warning' });
            console.log('HE 27 detected and removed. MW: ', updatedRow[27].MW, 'Price: ', updatedRow[27].Price);
            logAction('HE 27 detected and removed', 'Position Management', updatedRow);
            delete updatedRow[27];
        }

        return post(url, updatedRow).then(response => {
            enqueueSnackbar("Position updated successfully", { variant: "success" });
        });
    }, [enqueueSnackbar, headers]);

    const { batchRowUpdate } = useRowDebounce({ onTimeExpired: handleRowModification, });

    function handleCellValueChanged(event) {
        batchRowUpdate(event);
    }

    function toTwoDecimalPlaces(value) {
        const truncated = value ? parseFloat(parseFloat(value).toFixed(2)) : null;
        return truncated;
    }

    const defaultHourColDef = useCallback((i) => ({
        headerName: `${i}`,
        colId: `${i}`,
        aggFunc: 'sum',
        field: `${i}`,
        valueSetter: params => {
            const newValue = toTwoDecimalPlaces(params.newValue);
            const type = params.data.type;
            if (type === 'Schedule/Tag' || type === 'Schedule') {
                //make the value negative if it's a schedule/tag
                params.data[i] = -Math.abs(newValue);
            } else if (type === 'Deal/Forecast' || type === 'Deal') {
                //make the value positive if it's a deal
                params.data[i] = Math.abs(newValue);
            } else {
                params.data[i] = newValue;
            }
            return true;
        },
        editable: params => {
            const editableType = params.data.type === 'Deal' || params.data.type === 'Deal/Forecast' || params.data.type === 'Schedule/Tag' || params.data.type === 'Schedule';
            const isPinned = params.node.isRowPinned();
            return editableType && !isPinned;
        },
        flex: 1,
        enableValue: true,    // Enable value aggregation for these columns
        enableRowGroup: false,
        minWidth: 55,
        chartDataType: 'series',
        type: 'numericColumn',
        cellDataType: 'number',
        cellStyle: params => heatmapStyle(params, paletteRef),
        filter: 'agNumberColumnFilter',
    }), []);

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            //checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
            // rowGroup: true,
            initialHide: false,
        },
        // {
        //     headerName: 'Deal/Schedule',
        //     valueGetter: params => isDeal(params.data) ? `Deal` : `Schedule`,
        // },
        {
            field: 'type',
            headerName: 'Deal/Schedule/Tag',
        },
        {
            field: 'scheduleID',
            headerName: 'Schedule Id',
        },
        {
            field: 'tagIdx',
            headerName: 'TagID',
        },
        {
            field: 'tagCode',
            headerName: 'Tag Code',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Name',
            initialHide: false,
            rowGroup: true,
        },
        {
            field: 'Status',
            headerName: 'Status',
            initialHide: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: [
                    'ADJUSTED',
                    'CANCELLED',
                    'CONFIRMED',
                    'CURTAILED',
                    'DENIED',
                    'DRAFT',
                    'EXPIRED',
                    'EXTENDED',
                    'IMPLEMENTED',
                    'PENDING',
                    'RELOADED',
                    'VOIDED',
                    'WITHDRAWN'
                ]
            },
        },
        {
            field: 'startDateTime',
            headerName: 'Start Time',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterValue, cellValue) {
                    if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                        return -1;
                    } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            },
            valueFormatter: params => {
                const isValid = dayjs(params.value).isValid();
                return params.value && isValid ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            }
        },
        {
            field: 'endDateTime',
            headerName: 'End Time',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterValue, cellValue) {
                    if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                        return -1;
                    } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            },
            valueFormatter: params => {
                const isValid = dayjs(params.value).isValid();
                return params.value && isValid ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            }
        },
        // {
        //     field: 'Forecast',
        //     initialHide: true,
        // },
        {
            field: '24hourSettlement',
            headerName: '24Hr Settlement',
            initialHide: true,
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            field: 'dealFlowPath',
            headerName: 'Deal Flow Path',
        },
        {
            field: 'scheduleFlowPath',
            headerName: 'Schedule Flow Path',
        },
        {
            field: 'tagFlowPath',
            headerName: 'Tag Flow Path',
        },
        {
            initialWidth: "150px",
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            field: "Counterparty",
            initialWidth: "150px",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: "marketPath",
            initialWidth: "150px",
            headerName: 'Market Path'
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
            initialHide: true,
        },
        {
            headerName: 'Transaction',
            field: 'Type_F_P',
            initialHide: true,
        },
        ...Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => { //erik, this section needs to show negative IF IT IS A SCHEDULE but always allow it to edit as a positive... stored proc now converts everything to abs value
            acc.push(defaultHourColDef(i));
            if (i === 2) {
                acc.push(defaultHourColDef('2*'));
            }
            return acc;
        }, []),
        {
            headerName: '24hr Total',
            valueGetter: params => {
                if (!!params.data) {
                    return rowMWTotal(params.data);
                }
            },
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
            editable: false,
            type: 'numericColumn',
            rowGroup: false,
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
            minWidth: 100,
            filter: 'agNumberColumnFilter',
            colId: 'totalmw',
        },
    ]), []);

    const rowMWTotal = (rowData) => {
        return Array.from({ length: 24 }, (_, i) => i).reduce((acc, i) => {
            acc += rowData[i + 1] ?? 0;
            return toTwoDecimalPlaces(acc);
        }, 0);
    }

    const onColorModeChanged = useCallback(({ mode, }) => {
        if (ref.current.api) {
            //redraw the group rows when the theme changes
            const groupNodes = [];
            ref.current.api.forEachNode(node => {
                if (node.group) {
                    groupNodes.push(node);
                }
            });
            ref.current.api.context.mode = mode;
            ref.current.api.redrawRows({ rowNodes: groupNodes, force: true });
        }
    }, []);

    useColorMode(onColorModeChanged);

    const rowClassRules = useMemo(() => ({
        "ag-group-row-cell": params => params.api.context.mode !== 'dark' && (!!params.node.group || params.node.data?.isDealRow),
        "ag-group-row-cell-dark": params => params.api.context.mode === 'dark' && (!!params.node.group || params.node.data?.isDealRow),
    }), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context,
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'deal-rizz-position-management-palette'),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
        onColorModeChanged({ mode: theme.palette.mode });
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        const data = [];
        ref.current?.api.forEachLeafNode(node => {
            if (node.displayed) {
                data.push(node.data);
            }
        });
        const totalRow = generateTotalRowData(data, styledColDefs[0].field);
        //api.setGridOption('pinnedBottomRowData', totalRows);
        api.setPinnedBottomRowData(totalRow);
    }, [styledColDefs]);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    useEffect(() => {
        ref.current.api?.onFilterChanged();
    }, [search]);


    function isExternalFilterPresent() {
        return search.get('filterNetZeros') === 'true';
    }

    function doesExternalFilterPass(node) {
        if (node.parent?.aggData) {
            const groupNode = node.parent;
            const total = Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
                const val = groupNode.aggData[i] ?? 0;
                acc += val;
                if (i === 2) {
                    const val2 = groupNode.aggData['2*'] ?? 0;
                    acc += val2;
                }
                return acc;
            }, 0);
            return total !== 0;
        } else {
            return false;
        }
    }

    function handleRowDataUpdated(params) {
        updateTotalRows(params);
        params.api.onFilterChanged();
    }


    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%', paddingBottom: theme.spacing(1), }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ height: '100%', width: '100%' }}
                ref={ref}
                getRowId={getRowId}
                // onCellValueChanged={handleCellValueChanged}
                editType="fullRow"
                onCellValueChanged={handleCellValueChanged}
                stopEditingWhenCellsLoseFocus={true}
                rowClassRules={rowClassRules}
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={handleRowDataUpdated}
                onSelectionChanged={handleSelectionChanged}
                rowSelection='single'
                rowMultiSelectWithClick
                overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
                enableCharts
                columnDefs={styledColDefs}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                statusBar={defaultStatusBar}
                // statusBar={statusBar}
                sideBar={sideBar}
                groupDefaultExpanded={1}
                groupAggFiltering={true}
                // groupDisplayType="groupRows"
                suppressAggFuncInHeader
                suppressLastEmptyLineOnPaste
                onCellMouseOver={handleCellMouseOverHighlight}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
                aggFuncs={aggFuncs}  // Pass the custom aggregation functions here
                groupIncludeFooter={true}         // Include sum at the group level
                //groupIncludeTotalFooter={true}    // Include total sum at the bottom
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
            />
        </AgGridContainer>
    )
});
