import { userGroups } from "../../authConfig";
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Profile from "./Profile";
import OASIScredentials from "./OASIScredentials";
import GroupGuard from "../Guards/GroupGuard";
import WorkflowGrids from "./WorkflowGrids";
import TariffPrices from "../TransNow/TariffPrices";
import UserMapping from './UserMapping'
import CertificateMapping from './CertificateMapping'
import { TenantSettings } from './TenantSettings';
import SettingsAppMenu from './AppMenu';
import { ReleaseNotes } from "./ReleaseNotes";

function Settings() {
  const [selected, setSelected] = useState('profile');
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (selected && !loadedViews[selected]) {
      setLoadedViews({
        ...loadedViews,
        [selected]: true
      })
    }
  }, [selected]);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <SettingsAppMenu selected={selected} setSelected={setSelected} />
        <Box sx={{ p: '7px', flexGrow: 1, }} >
          {loadedViews['usermapping'] && <Collapse in={selected === 'usermapping'}>
            <UserMapping />
          </Collapse>}
          {loadedViews['profile'] && <Collapse in={selected === 'profile'}>
            <Profile />
          </Collapse>}
          {/*<Collapse in={selected === 'usergroups'}>
            <UserGroups />  
          </Collapse>*/}
          <GroupGuard groups={[userGroups.realtimegrids, userGroups.dayaheadgrids, userGroups.bulkoriginalrequests, userGroups.dayaheadspreadsheet, userGroups.spymonkey]}>
            {loadedViews['oasiscredentials'] && <Collapse in={selected === 'oasiscredentials'} >
              <OASIScredentials />
            </Collapse>}
          </GroupGuard>
          <GroupGuard groups={[userGroups.certificatemapping]}>
            {loadedViews['certificatemapping'] && <Collapse in={selected === 'certificatemapping'} >
              <CertificateMapping />
            </Collapse>}
          </GroupGuard>
          <GroupGuard groups={[userGroups.admins]} >
            {loadedViews['tenantsettings'] && <Collapse in={selected === 'tenantsettings'} >
              <TenantSettings />
            </Collapse>}
          </GroupGuard>
          <GroupGuard groups={[userGroups.workflow]} >
            {loadedViews['workflowgrids'] && <Collapse in={selected === 'workflowgrids'} >
              <WorkflowGrids />
            </Collapse>}
          </GroupGuard>
          <GroupGuard groups={[userGroups.realtimegrids, userGroups.bulkoriginalrequests, userGroups.realtimeoriginals, userGroups.dayaheadgrids]} >
            {loadedViews['tariffprices'] && <Collapse in={selected === 'tariffprices'} >
              <TariffPrices />
            </Collapse>}
          </GroupGuard>
          {loadedViews['releasenotes'] && <Collapse in={selected === 'releasenotes'} >
            <ReleaseNotes />
          </Collapse>}
        </Box>
      </Box>

    </div>
  );
}

export default Settings;
