import { Box, Grid, TextField, FormControl, InputLabel } from "@mui/material";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useFormContext } from "react-hook-form";

export const CreateScheduleForm = ({ formId, onSubmit, selectedRows, sourceSinkOptions, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    const defaults = useMemo(() => ({
        source: selectedRows.tag?.Source ?? '',
        sink: selectedRows.tag?.Sink ?? '',
        por: selectedRows.tag?.firstPOR ?? '',
        pod: selectedRows.tag?.lastPOD ?? '',
        timezone: timezone ?? 'Pacific Standard Time',
    }), [selectedRows, timezone]);

    const schema = yup.object().shape({
        //por: yup.string().required('POR is required'),
        //pod: yup.string().required('POD is required'),
        source: yup.string().required('Source is required'),
        sink: yup.string().required('Sink is required'),
        timezone: yup.string().required('Timezone is required'),
    })

    const { register, handleSubmit, formState: { errors, }, control, reset } = useForm({
        defaultValues: defaults,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset(defaults);
    }, [selectedRows]);

    function onError(errors) {
        const firstErrorKey = Object.keys(errors)[0];
        const firstError = errors[firstErrorKey];
        enqueueSnackbar(firstError.message, { variant: 'error' });
    }

    const defaultBreakpoints = {
        xs: 10,
        sm: 5,
        md: 2,
    }

    return (
        <Box component='form' id={formId} onSubmit={handleSubmit(onSubmit, onError)} sx={{ p: 1, width: '100%' }}>
            <Grid container spacing={1} columns={10}>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        name='por'
                        control={control}
                        options={[]}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POR"
                                error={!!errors.por}
                                helperText={errors.por?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        name='pod'
                        control={control}
                        options={[]}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POD"
                                error={!!errors.pod}
                                helperText={errors.pod?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        name='source'
                        control={control}
                        options={sourceSinkOptions.sources ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Source"
                                error={!!errors.source}
                                helperText={errors.source?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        name='sink'
                        control={control}
                        options={sourceSinkOptions.sinks ?? []}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Sink"
                                error={!!errors.sink}
                                helperText={errors.sink?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        autoHighlight
                        options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                        getOptionLabel={(option) => option.split(' ')[0]}
                        control={control}
                        name='timezone'
                        disabled
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Timezone"
                                size='small'
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        {/* <InputLabel shrink htmlFor="comments">
                            Comments
                        </InputLabel> */}
                        <TextField
                            {...register('comments')}
                            id="comments"
                            multiline
                            label="Comments"
                            maxRows={5}
                            error={!!errors.comments}
                            helperText={errors.comments?.message}
                        />
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    );
}