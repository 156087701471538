import { CreateDealButton, EditDealButton, RollbackDealButton, ConfirmDealButton, VoidDealButton, ExtendDealButton } from "../../RibbonButtons"
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from "../../Ribbon";

export const DealActionsGroup = ({ expanded, gridRef, handleFetchData, formId, selectedRow, }) => {
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <RibbonGroupContainer>

            <CreateDealButton
                expanded={expanded}
                formId={formId}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />

            <RollbackDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            />

        </RibbonGroupContainer>
    )
}