import { VoidScheduleButton } from "../../RibbonButtons/VoidScheduleButton";
import { ConfirmScheduleButton } from "../../RibbonButtons/ConfirmScheduleButton";
import { RibbonGroupContainer } from "../../Ribbon";

export const ScheduleActionsGroup = ({ expanded, selectedSchedule, gridRef, }) => {
    const toConfirm = !selectedSchedule || selectedSchedule.Status !== 'CONFIRMED';
    const toVoid = !selectedSchedule || selectedSchedule?.Status !== 'VOIDED';

    return (
        <RibbonGroupContainer>

            <ConfirmScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                gridRef={gridRef}
                toConfirm={toConfirm}
            />

            <VoidScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                gridRef={gridRef}
                toVoid={toVoid}
            />

        </RibbonGroupContainer>
    );
}