import { Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

export default ({ name, control, ...inputProps }) => {
  return (
    <Controller
      render={({ field }) =>
        <Checkbox
          {...field}
          {...inputProps}
          onChange={(_) => field.onChange(!field.value)}
          checked={!!field.value}
        />
      }
      name={name}
      control={control}
    />
  )
}