import { RibbonButton } from "../Ribbon";
import { LookupValuesProvider } from "../DealEntry/LookupContext";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { useApi } from "../../useApi";
import { useFormContext } from "react-hook-form";
import { Tooltip } from "@mui/material";

export const EditDealButton = ({ expanded, disabled, deal, formId, ...buttonProps }) => {
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);
    const [dealToEdit, setDealToEdit] = useState(deal);
    const [loading, setLoading] = useState(false);
    const { get, headers, logAction, apiUrlPrefix } = useApi();
    const { onSubmit, handleSubmit, watch } = useFormContext();
    const timezone = watch('timezone');

    async function fetchDealSummary() {
        const dealID = deal.dealID;
        setLoading(true);
        logAction('User fetched Deal Summary Data', 'Deal Summary', dealID);
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealSummaryByDealID_v3`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealID}`
            + `&parm=${timezone}`

        return get(url).then(response => {
            response?.data && setDealToEdit(response.data[0]);
        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <>
            <LookupValuesProvider>
                <DealEntryDialog
                    open={openDealEntryDialog}
                    dealToEdit={dealToEdit}
                    handleClose={() => setOpenDealEntryDialog(false)}
                    handleSave={() => {
                        setOpenDealEntryDialog(false);

                        //submit the form to refresh the grid
                        handleSubmit(onSubmit)();
                    }}
                    loading={loading}
                />
            </LookupValuesProvider>
            <Tooltip title="Select an existing deal below and click here to edit it.">
                <span>
                    <RibbonButton
                        onMouseDown={() => {
                            fetchDealSummary();
                            setOpenDealEntryDialog(true);
                        }}
                        icon={<EditIcon fontSize='small' />}
                        label={'Edit'}
                        disabled={disabled}
                        expanded={expanded}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}