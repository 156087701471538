import { useEffect, useState, createContext, useContext, useCallback, useMemo } from "react";
import { groupBy } from "lodash";
import { useApi } from "../../useApi";
import useHubAction from "../../HubContext/useHubAction_v2";

export const LookupValuesContext = createContext();

export function LookupValuesProvider({ children }) {
  const [lookupValues, setLookupValues] = useState([]);
  const { apiUrlPrefix, headers, get } = useApi();

  useEffect(() => {
    fetchLookupValues();
  }, []);

  const fetchLookupValues = useCallback(async () => {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.fetchLookupValues&parm=${headers.userGuid}`;
    return get(url).then((response) => {
      setLookupValues(response.data.map(lookup => ({
        ...lookup,
        childrenLookupValues: JSON.parse(lookup.childrenLookupValues),
      })));
    });
  }, [headers]);

  useHubAction({
    action: fetchLookupValues,
    allowedMessages: ['trg_lookupValuesInsertUpdate'],
    callbackDependencies: [headers],
  });

  const allOptions = useMemo(() => {
    const options = lookupValues.reduce((acc, lookup) => {
      if (!acc.has(lookup.lookupField)) {
        acc.set(lookup.lookupField, new Set());
      }
      acc.get(lookup.lookupField).add(lookup.value);
      return acc;
    }, new Map());

    //convert the sets to arrays
    for (let [key, value] of options) {
      options.set(key, [...value]);
    }
    return options;
  }, [lookupValues]);

  const getOptions = useCallback((formState) => {
    const restrictedOptions = Object.fromEntries(allOptions);
    for (let lookup of lookupValues) {
      if (formState[lookup.lookupField]?.includes(lookup.value) && !!lookup.childrenLookupValues?.length) {
        //group the children by lookupField
        const children = groupBy(lookup.childrenLookupValues, 'lookupField');
        for (let childField in children) {
          restrictedOptions[childField] = restrictedOptions[childField].filter(v => children[childField].some(child => child.value === v));
        }
      }
    }
    return restrictedOptions;
  }, [lookupValues]);

  const value = useMemo(() => ({ lookupValues, getOptions, fetchLookupValues, }), [lookupValues, getOptions, fetchLookupValues,]);

  return (
    <LookupValuesContext.Provider value={value}>
      {children}
    </LookupValuesContext.Provider>
  )
}

export const useLookupValues = () => useContext(LookupValuesContext);