import { useState, useEffect } from 'react';
import EmailComposer from '../components/PowerPeople/EmailComposer';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import PowerPeopleAppMenu from '../components/PowerPeople/PowerPeopleAppMenu';
import Requirements from '../components/PowerPeople/Requirements';
import GroupGuard from '../components/Guards/GroupGuard';
import { userGroups } from '../authConfig';
import SupportRequests from '../components/PowerPeople/SupportRequests';
import DBUtility from '../components/PowerPeople/DBUtility';
import HelpComposer from '../components/HelpComposer';
import DataAnalysis from '../components/PowerPeople/DataAnalysis/DataAnalysis';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { ChatbotManagement } from '../components/PowerPeople/ChatbotManagement';
import AISupportTickets from '../components/PowerPeople/AISupportTickets';
import { useUserGroups } from '../data/useUserGroups';

export default () => {
  const { userIsInGroup } = useUserGroups();
  const savedView = localStorage.getItem('powerPeopleView');

  let defaultView = '';
  if (userIsInGroup(userGroups.support)) {
    defaultView = 'support';
  } else if (userIsInGroup(userGroups.helpcomposer)) {
    defaultView = 'helpcomposer';
  } else if (userIsInGroup(userGroups.requirements)) {
    defaultView = 'requirements';
  } else if (userIsInGroup(userGroups.dataanalysis)) {
    defaultView = 'dataanalysis';
  }

  const [view, setView] = useState(savedView || defaultView);
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (view && !loadedViews[view]) {
      setLoadedViews({
        ...loadedViews,
        [view]: true
      })
    }
  }, [view]);

  const handleSelection = (viewName) => {
    localStorage.setItem('powerPeopleView', viewName);
    setView(viewName);
  };

  const MenuOptions = [
    /*{
      name: 'sandbox',
      component: <Sandbox />
    },*/
    {
      name: 'dataanalysis',
      component: <DataAnalysis />
    },
    {
      name: 'apiaudit',
      component: <TransHunterAuditGrid />
    },
    {
      name: 'emailcomposer',
      component: <EmailComposer />
    },
    {
      name: 'dbutility',
      component: <DBUtility />
    },
    {
      name: 'support',
      component: <SupportRequests visible={view === 'support'} />
    },
    {
      name: 'requirements',
      component: <Requirements />
    },
    {
      name: 'helpcomposer',
      component: <GroupGuard groups={[userGroups.helpcomposer]}>
        <HelpComposer />
      </GroupGuard>
    },
    {
      name: 'chatbot',
      component: <ChatbotManagement />
    },
    {
      name: 'aiSupport',
      component: <AISupportTickets />
    }
  ]

  return (
    <Box sx={{ display: 'flex' }}>
      <PowerPeopleAppMenu handleSelection={handleSelection} selected={view} />
      <Box
        component="main"
        sx={{
          p: '6px',
          flexGrow: 1,
        }}
      >
        {MenuOptions.map(option =>
          loadedViews[option.name] && <Collapse in={view === option.name}>
            {option.component}
          </Collapse>
        )}
      </Box>
    </Box>
  );
}