import { Dashboard } from "../useDashboard";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { PositionManagementView } from "./PositionManagementView";

export const PositionManagementDashboard = ({ visible, }) => {
    const dashboardKey = 'position-management';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={PositionManagementView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}