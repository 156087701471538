import { useRouteError, } from "react-router-dom"
import { NotFound } from "./NotFound";
import { FatalError } from "./FatalError";

export const ErrorBoundary = () => {
    const error = useRouteError();

    return (
        error?.status === 404 ? <NotFound /> : <FatalError error={error} />
    );
}