import { Box, Tooltip, Typography } from "@mui/material"

export const CurtailmentCellRenderer = (props) => {
    const isCurtailed = props.data?.tagStatus === "CURTAILED";
    let allData = props.data?.[props.hour] ?? {};
    const { Current, Market, flag, toolTip, Reliability, Original, status } = allData;

    let flagClass = '';
    if (flag === 'yellow') {
        flagClass = 'corner-flag-yellow';
    } else if (flag === 'red') {
        flagClass = 'corner-flag-red';
    } else if (flag === 'green') {
        flagClass = 'corner-flag-green';
    } else if (flag === 'red/green') {
        flagClass = 'corner-flag-split';
    }

    const hasReliability = !!Reliability || Reliability === 0;

    return (
        <Tooltip
            title={
                <div dangerouslySetInnerHTML={{ __html: toolTip }} />
            }
            arrow
            placement='top'
            enterDelay={400}
            PopperProps={{ style: { pointerEvents: 'none' } }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: hasReliability ? 'space-between' : 'flex-end', height: '100%' }} className={flagClass}>
                <Typography className='ag-cell-text'></Typography>
                <Typography className='ag-curtailed-market-text ag-cell-text-bold' align='center'>{hasReliability ? '\u{2193}' + `${Reliability}` : ''}</Typography>
                <Typography className='ag-cell-text'>{Current}</Typography>
            </Box>
        </Tooltip>
    )
}