import { useMemo, useCallback, forwardRef } from "react"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { CheckboxCellRenderer } from '../../AgGrid/CheckboxCellRenderer';
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { useFormContext } from "react-hook-form"
import { useDashboard } from "../useDashboard"
import { useParams } from "react-router-dom"
import { useColorMode } from "../../../styles/ColorMode/useColorMode"
import { useTheme } from "@mui/material"

export const DealSummaryGrid = forwardRef(({ setSelectedRow, getRowId, layoutStorageKey, silentUpdate, }, ref) => {
    const { getValues } = useFormContext();
    const { layout, } = getValues();
    const { columnTypes } = useColumnTypes();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { headers, enqueueSnackbar, } = useApi();
    const userInfo = useUserInfo();
    const { visible: dashboardVisible, } = useDashboard();
    const params = useParams();
    const viewId = params.id;
    const gridIsVisible = dashboardVisible && viewId === getValues('id').toString();
    const theme = useTheme();

    const baseColDefs = useMemo(() => ([
        {
            field: 'dealID',
            headerName: 'Deal ID',
            checkboxSelection: true,
            filter: 'agNumberColumnFilter',
        },
        {
            field: "confirmed",
            headerName: "Confirmed",
            cellRenderer: 'checkboxCellRenderer',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            field: 'Contract',
        },
        {
            field: 'Counterparty',
        },
        {
            headerName: "Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.sparklineJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            field: 'Creation_Time',
            headerName: 'Creation Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Creator',
            headerName: 'Creator'
        },
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Deal_Number',
            headerName: 'Deal Name',
        },
        {
            field: 'Start_Date',
            headerName: 'Start Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'End_Date',
            headerName: 'End Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'FlowStartDate',
            headerName: 'Flow Start',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'FlowEndDate',
            headerName: 'Flow End',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Financial_Type',
            headerName: 'Firm/Non-Firm',
        },
        {
            field: 'Formula',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            field: 'Zone',
            headerName: 'Zone'
        },
        {
            field: 'index_name',
            headerName: 'Index',
        },
        {
            field: 'Index_2',
            headerName: 'Index 2',
        },
        {
            field: 'Internal',
            headerName: 'Comments',
        },
        {
            field: 'Market',
        },
        {
            field: 'Modification_Time',
            headerName: 'Modification Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Modification_User',
            headerName: 'Modification User',
        },
        {
            field: 'Product',
        },
        {
            field: 'Status',
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED','DRAFT' ]
            },
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
        },
        {
            field: 'originalTerm',
            headerName: 'Term'
        },
        {
            field: 'indexType',
            headerName: 'Index Type',
        },
        {
            field: 'dynamic',
            headerName: 'Dynamic',
        },
        {
            field: 'forecast',
            headerName: 'Forecast', //test
        },
        {
            field: 'Time_Zone',
            headerName: 'Time Zone',
        },
        /*{
            field: "Total_MWh",
            headerName: "Total MWh",
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined && !isNaN(value)) {
                    // Format value with commas and up to 3 decimal places
                    return parseFloat(value).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                    });
                }
                return value;
            }
        },*/
        /*{
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            initialWidth: "150px",
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },*/
        {
            field: 'Trade_Date',
            headerName: 'Trade Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Trader',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
        {
            field: 'Type_F_P',
            headerName: 'Transaction',
        },
        {
            field: 'Voiding_User',
            headerName: 'Voiding User',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
        //enableCellChangeFlash: true,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Deal changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['dealUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo?.tenantId?.toString())
                && (obj.userName !== userInfo.userName || !gridIsVisible); //ignore own updates, unless it was caused on a different grid
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
        onColorModeChanged({ mode: theme.palette.mode });
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    const onColorModeChanged = useCallback(({ mode, }) => {
        if (ref.current.api) {
            //redraw the rows when the theme changes
            ref.current.api.context.mode = mode;
            ref.current.api.redrawRows({ force: true });
        }
    }, []);

    useColorMode(onColorModeChanged);

    const gridOptions = useMemo(() => {
        const rowClass = (type, mode) => (params) => {
            return params.data?.Transaction_Type?.toLowerCase() === type
                && params.api.context.mode === mode;
        }

        return {
            rowClassRules: {
                "ag-row-purchase": rowClass('purchase', 'light'),
                "ag-row-sale": rowClass('sale', 'light'),
                "ag-row-purchase-dark": rowClass('purchase', 'dark'),
                "ag-row-sale-dark": rowClass('sale', 'dark'),
            },
        }
    }, []);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                getRowId={getRowId}
                ref={ref}
                enableCharts
                columnDefs={styledColDefs}
                gridOptions={gridOptions}
                //onCellValueChanged={handleCellValueChanged}
                //onRowDataUpdated={handleRowDataUpdated}
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                rowMultiSelectWithClick
                rowSelection="single"
                onSelectionChanged={handleSelectionChanged}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                // enableAdvancedFilter={true}
                enableFilter={true}
                sideBar={sideBar}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    checkboxCellRenderer: CheckboxCellRenderer,
                }}
            />
        </AgGridContainer>
    )
});