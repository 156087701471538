import ResizingPane from 'react-resizing-pane';
import { Box, Accordion, Typography, AccordionDetails, AccordionSummary, Stack, Tooltip, Fade, Collapse, IconButton, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { ArrowDropDown, InfoOutlined, } from "@mui/icons-material";
import { useState, useRef, useEffect, useMemo } from "react";
import { useTheme } from '@emotion/react';
import ProfileChart from './ProfileChart';
import { PathGraph } from './PathGraph';
import { AvailableTsrGrid } from './AvailableTsrGrid';
import axios from 'axios';
import { apiUrlPrefix } from '../../../authConfig';
import { useSnackbar } from 'notistack';
import useHeader from '../../useHeader';
import { WorkspaceGrid } from './WorkspaceGrid';
import { AvailabilityToolbar } from './AvailabilityToolbar';
import { renderStandardTimezones } from '../../../utils/renderTimezones';
import { WorkspaceGridToolbar } from './WorkspaceGridToolbar';
import { WorkspaceToolbar } from './WorkspaceToolbar';
import dayjs from '../../dayjs-tz';
import { willCreateCycle } from 'graphology-dag'
import { ProfileDataGrid } from './ProfileDataGrid';
import { ProfileDataToolbar } from './ProfileDataToolbar';
import ExpandIcon from '@mui/icons-material/Expand';

export const PathBuilder = () => {
    const storageConfig = { name: 'ResizableDealBuilderTsrGridPane', type: localStorage };
    const defaultExpandedStorageKey = 'deal-builder-expanded-panels';
    const defaultExpanded = JSON.parse(localStorage.getItem(defaultExpandedStorageKey)) ?? ['network',];
    const theme = useTheme();
    const tsrGridRef = useRef();
    const workspaceGridRef = useRef();
    const profileGridRef = useRef();
    const [expanded, setExpanded] = useState(new Set(defaultExpanded));
    const detailHeight = 75 / expanded.size;
    const { enqueueSnackbar } = useSnackbar();
    const headers = useHeader();
    const [availableTsrData, setAvailableTsrData] = useState([]);
    const [targetProfile, setTargetProfile] = useState([]);
    const [profileData, setProfileData] = useState(new Map());
    const [workspaceData, setWorkspaceData] = useState([]);
    const [defaultGraphNodes, setDefaultGraphNodes] = useState([]);
    const abortControllerRef = useRef(new AbortController());
    const [showAvailability, setShowAvailability] = useState(true);
    const [selections, setSelections] = useState(new Set());
    const [actives, setActives] = useState([]);
    const workspaceGraphRef = useRef();

    const tsrKey = (tsr) => `${tsr.PointOfReceipt}-${tsr.Provider}-${tsr.PointOfDelivery}-${tsr.RequestType}-${tsr.PathName}-${tsr.TSType}-${tsr.TSPeriod}-${tsr.TSPeriod}-${tsr.TSClass}-${tsr.TSWindow}-${tsr.TSSubclass}-${tsr.relatedRef}-${tsr.ServiceIncrement}`;

    useEffect(() => {
        localStorage.setItem(defaultExpandedStorageKey, JSON.stringify(Array.from(expanded)));
    }, [expanded]);

    function handleToggleAccordion(name) {
        return (event, isExpanded) => {
            const newExpanded = new Set(expanded);
            if (isExpanded) {
                newExpanded.add(name);
            } else {
                if (newExpanded.size === 1) { return; } //don't allow all panels to be closed
                newExpanded.delete(name);
            }
            setExpanded(newExpanded);
        };
    }

    function moveSelectedToWorkspace() {
        const selected = tsrGridRef.current?.api.getSelectedRows();
        const currentDataKeys = new Set(workspaceData.map(row => tsrKey(row)));
        const newRows = selected.filter(row => !currentDataKeys.has(tsrKey(row)));

        if (!selected.length) {
            enqueueSnackbar('Please select rows to move to the workspace.', { variant: 'info' });
        } else {
            handleMoveRows(newRows);
        }
    }

    const dropZoneParams = {
        getContainer: function () {
            return document.querySelector('#pathway-builder-workspace-container');
        },
        onDragStop: function (params) {
            handleMoveRows([...params.nodes.map(node => node.data)]);
        }
    }

    function handleMoveRows(data) {
        const wouldCreateCycle = data.some(row => {
            const graph = workspaceGraphRef.current.getGraph();
            if (!graph) return false; //no graph to check against; may happen if network accordion has not been expanded
            return willCreateCycle(graph, row.PointOfReceipt, row.PointOfDelivery);
        });

        if (wouldCreateCycle) {
            enqueueSnackbar('The selected rows cannot be moved to the workspace because they form a loop back.', { variant: 'error' });
        } else {
            handleSetWorkspaceData([...workspaceData, ...data]);
            tsrGridRef.current?.api?.deselectAll();
        }
    }

    const firstStartDateTime = useMemo(() => {
        return targetProfile.length ? targetProfile[0].startDateTime : null;
    }, [targetProfile]);

    const lastEndDateTime = useMemo(() => {
        return targetProfile.length ? targetProfile[targetProfile.length - 1].endDateTime : null;
    }, [targetProfile]);

    function generateDefaultRowProfile(row) {
        let start = dayjs(firstStartDateTime);
        const end = dayjs(lastEndDateTime);
        const priceCurve = JSON.parse(row.OfferPrice);

        //get blocks in the start/end time frame and truncate the ends to fit the window
        const blocksInWindow = priceCurve.filter(block => {
            const blockStart = dayjs(block.OfferStartTimeUtc);
            const blockEnd = dayjs(block.OfferStopTimeUtc);
            return blockStart.isSameOrBefore(end) && blockEnd.isSameOrAfter(start);
        }).map(block => ({
            start: dayjs.max(dayjs(block.OfferStartTimeUtc), start),
            end: dayjs.min(dayjs(block.OfferStopTimeUtc), end),
            price: block.OfferPrice,
        }));

        //now split the blocks into hourly blocks
        const hourlyBlocks = [];
        blocksInWindow.forEach(block => {
            const blockEnd = dayjs(block.end).startOf('hour');
            let next = dayjs(block.start).startOf('hour');
            while (blockEnd.isAfter(next, 'hour')) {
                hourlyBlocks.push({
                    key: next.add(1, 'hour'),
                    //capacity: block.capacity,
                    price: block.price,
                });
                next = next.add(1, 'hour');
            }
        });

        const newProfile = {};
        hourlyBlocks.forEach(block => {
            const blockEnd = block.key.format('MM/DD/YY HH:mm');
            newProfile[blockEnd] = {
                Q: 0,
                P: block.price,
            };
        });

        return newProfile;
    }

    function formatProfileInfo(profileInfo) {
        return Object.keys(profileInfo).map(key => {
            const capacity = profileInfo[key].Q;
            return {
                startDateTime: dayjs(key).subtract(1, 'hour'),
                endDateTime: dayjs(key),
                capacityRequested: capacity,
            };
        });
    }

    function aggregateProfileData(data) {
        //sum up capacities for each time period
        return data.reduce((acc, next) => {
            next.forEach(block => {
                const key = block.startDateTime.toISOString();
                let capacity = parseInt(block.capacityRequested);
                if (acc.has(key)) {
                    capacity = acc.get(key).capacityRequested + parseInt(block.capacityRequested);
                }
                acc.set(key, { ...block, capacityRequested: capacity, });
            });
            return acc;
        }, new Map());
    }

    function rollUpProfileData(data) {
        return Array.from(data.values()).reduce((rolledUp, next) => {
            const last = rolledUp[rolledUp.length - 1];
            if (last && (last.endDateTime.isSame(next.startDateTime)) && (last.capacityRequested === next.capacityRequested)) {
                last.endDateTime = next.endDateTime;
            } else {
                rolledUp.push(next);
            }
            return rolledUp;
        }, [])
    }

    function updateProfileChart(data) {
        if (!data.every(row => row.ProfileInfo)) { return; }

        const formatted = data.map(row => ({ ...row, ProfileInfo: formatProfileInfo(row.ProfileInfo) }));

        //group profiles by POR and POD
        const grouped = formatted.reduce((acc, next) => {
            const key = `${next.PointOfReceipt}-${next.PointOfDelivery}`;
            if (!acc.has(key)) {
                acc.set(key, []);
            }
            acc.get(key).push(next.ProfileInfo);
            return acc;
        }, new Map());

        //aggregate profiles for each group and roll up the data
        grouped.forEach((value, key, map) => {
            const profile = rollUpProfileData(aggregateProfileData(value)).sort((a, b) => a.startDateTime.diff(b.startDateTime));
            map.set(key, profile);
        });

        setProfileData(grouped);
    }

    function handleCancelFetch() {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    function fetchTsrGridData(data) {
        setTargetProfile(data.profile);
        tsrGridRef.current?.api.showLoadingOverlay();

        const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/postFetchArray?name=PowerStationMetaData.UI_dealRizzAvailableTrans&parm=${headers.userGuid}`
            + `&parm=${data.por ?? ''}`
            + `&parm=${data.pod ?? ''}`
            + `&parm=${renderStandardTimezones[data.timezone]}`
            + `&parm=${data.tsClass ?? ''}`
            + `&parm=${data.tsIncrement ?? ''}`
            + `&parm=${data.tsType ?? ''}`
            + `&parm=${data.tsPeriod ?? ''}`
            + `&parm=${data.tsWindow ?? ''}`
            + `&parm=${data.tsSubclass ?? ''}`
            + `&parm=${data.excludeTp ?? ''}`
            + `&parm=${data.excludePoints ?? ''}`

        const options = {
            method: 'POST',
            headers: headers,
            url: uri,
            data: data.profile,
            signal: abortControllerRef.current.signal,
        }

        axios(options).then(response => {
            setAvailableTsrData(response.data);
            tsrGridRef.current?.api.hideOverlay();
            updateDefaultGraphNodes(data);
        }).catch(error => {
            if (error.message === 'canceled') {  //don't show an error if the user canceled the fetch
                enqueueSnackbar('Fetch canceled.', { variant: 'info' });
            } else {
                enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
            }
            tsrGridRef.current?.api.hideOverlay();
            setAvailableTsrData([]);
            tsrGridRef.current.api.showNoRowsOverlay();
        });
    }

    function updateDefaultGraphNodes(data) {
        const { por, pod } = data;
        setDefaultGraphNodes([{
            id: por,
            label: `${por} - Source`,
            fill: theme.palette.primary.green,
        }, {
            id: pod,
            label: `${pod} - Sink`,
            fill: theme.palette.primary.red,
        }]);
    };

    const detailHeightProps = (name) => expanded.has(name) ? { height: `${detailHeight}vh` } : { height: 0 };

    const defaultAccordionProps = (name) => ({
        expanded: expanded.has(name),
        onChange: handleToggleAccordion(name),
        disableGutters: true,
        slots: { transition: Fade },
        slotProps: { transition: { timeout: 0 } },
    });

    function handleGridSelectionChanged(params) {
        const selected = params.api.getSelectedRows();
        const keys = new Set(selected.map(row => tsrKey(row)));
        setSelections(keys);

        //select the corresponding rows in the other grid
        const otherGrid = params.api === workspaceGridRef.current.api ? profileGridRef : workspaceGridRef;
        otherGrid.current.api.forEachNode(node => {
            if (keys.has(node.id) && !node.isSelected()) {
                node.setSelected(true);
            } else if (!keys.has(node.id) && node.isSelected()) {
                node.setSelected(false);
            }
        });
    }

    function setRowHighlight(id, highlight) {
        toggleGridRowHighlight(id, highlight, workspaceGridRef);
        toggleGridRowHighlight(id, highlight, profileGridRef);
    }

    function setLegHighlight(id, highlight) {
        setRowHighlight(id, highlight);
        //set edge activity in the graph
        setActives(highlight ? [id] : []);
    }

    function toggleGridRowHighlight(id, highlight, ref) {
        const node = ref.current.api.getRowNode(id);
        ref.current.api.applyTransaction({
            update: [{ ...node.data, _highlighted: highlight }],
        });
    }

    function handleEdgeClick(edge) {
        //find the corresponding row in the workspace grid and toggle the selection
        const node = workspaceGridRef.current.api.getRowNode(edge.id);
        node.setSelected(!node.isSelected());
        //add to selections
        const newSelections = new Set(selections);
        if (node.isSelected()) {
            newSelections.add(edge.id);
        } else {
            newSelections.delete(edge.id);
        }
        setSelections(newSelections);
    }

    function clearSelections() {
        setSelections(new Set());
        workspaceGridRef.current?.api?.deselectAll();
        profileGridRef.current?.api?.deselectAll();
    }

    function handleSetWorkspaceData(data) {
        const dataWithProfile = data.map(row => ({
            ...row,
            ProfileInfo: row.ProfileInfo ?? generateDefaultRowProfile(row),
        }));
        setWorkspaceData(dataWithProfile);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
            <TsrGridContainer>
                {showAvailability
                    ? <ResizingPane
                        sides={['right']}
                        storageId={'deal-builder-tsr-grid-pane'}
                        storageConfig={storageConfig}
                        height="100%"
                        width={500}
                        style={{
                            border: '0px solid blue',
                            minWidth: 300
                        }}
                    >
                        <AvailabilityToolbar
                            fetch={fetchTsrGridData}
                            moveSelected={moveSelectedToWorkspace}
                            setShowAvailability={setShowAvailability}
                            gridRef={tsrGridRef}
                        />
                        <AgGridContainer style={{ height: '96%', paddingTop: theme.spacing(1), }}>
                            <AvailableTsrGrid
                                ref={tsrGridRef}
                                rowData={availableTsrData}
                                handleCancelFetch={handleCancelFetch}
                                dropZoneParams={dropZoneParams}
                            />
                        </AgGridContainer>
                    </ResizingPane>
                    : <Tooltip title='Expand the availability grid.' arrow placement='right'>
                        <IconButton size='large' onClick={() => setShowAvailability(prev => !prev)} sx={{ position: 'relative', top: '50%', }}>
                            <ExpandIcon sx={{ transform: 'rotate(90deg)' }} />
                        </IconButton>
                    </Tooltip>}
            </TsrGridContainer>
            <WorkspaceContainer id='pathway-builder-workspace-container'>
                <WorkspaceToolbar
                    workspaceGridRef={workspaceGridRef}
                    data={workspaceData}
                    setData={handleSetWorkspaceData}
                    tsrKey={tsrKey}
                />
                <StyledAccordion {...defaultAccordionProps('profile')}>
                    <StyledSummary
                        expandIcon={<ArrowDropDown />}
                    >
                        <Typography>Profile</Typography>
                    </StyledSummary>
                    <StyledDetails sx={detailHeightProps('profile')}>
                        <ProfileChart chartData={profileData} targetData={targetProfile} />
                    </StyledDetails>
                </StyledAccordion>
                <StyledAccordion {...defaultAccordionProps('profiledata')}>
                    <StyledSummary
                        expandIcon={<ArrowDropDown />}
                    >
                        <Typography>Profile Data</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {expanded.has('profiledata') &&
                            <ProfileDataToolbar
                                ref={profileGridRef}
                                workspaceData={workspaceData}
                                startDateTime={firstStartDateTime}
                                endDateTime={lastEndDateTime}
                                setWorkspaceData={handleSetWorkspaceData}
                                tsrKey={tsrKey}
                                targetProfile={targetProfile}
                            />}
                    </StyledSummary>
                    <StyledDetails sx={detailHeightProps('profiledata')}>
                        <AgGridContainer style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
                            <ProfileDataGrid
                                ref={profileGridRef}
                                tsrKey={tsrKey}
                                startDateTime={firstStartDateTime}
                                endDateTime={lastEndDateTime}
                                data={workspaceData}
                                updateProfileChart={updateProfileChart}
                                onSelectionChanged={handleGridSelectionChanged}
                                setLegHighlight={setLegHighlight}
                            />
                        </AgGridContainer>
                    </StyledDetails>
                </StyledAccordion>
                <StyledAccordion {...defaultAccordionProps('network')}>
                    <StyledSummary
                        expandIcon={<ArrowDropDown />}
                    >
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Typography>Network</Typography>
                            <Tooltip placement='right' arrow title={
                                <Stack spacing={2} >
                                    <Typography variant='caption'>Click a node to view information and actions in the menu.</Typography>
                                    <Typography variant='caption'>Click and hold a node to drag to a new location.</Typography>
                                    <Typography variant='caption'>Hold Shift and drag you cursor to select edges.</Typography>
                                    <Typography variant='caption'>Right click a node or edge to view a menu with additional information.</Typography>
                                </Stack>
                            }>
                                <InfoOutlined />
                            </Tooltip>
                        </Stack>
                    </StyledSummary>
                    <StyledDetails sx={detailHeightProps('network')}>
                        <PathGraph
                            data={workspaceData}
                            gridRef={workspaceGridRef}
                            availableTsrs={availableTsrData}
                            defaultNodes={defaultGraphNodes}
                            ref={workspaceGraphRef}
                            tsrKey={tsrKey}
                            setRowHighlight={setRowHighlight}
                            handleEdgeClick={handleEdgeClick}
                            setData={handleSetWorkspaceData}
                            selections={selections}
                            setSelections={setSelections}
                            clearSelections={clearSelections}
                            actives={actives}
                            setActives={setActives}
                        />
                    </StyledDetails>
                </StyledAccordion>
                <StyledAccordion {...defaultAccordionProps('workspace')}>
                    <StyledSummary
                        expandIcon={<ArrowDropDown />}
                    >
                        <Typography>Workspace</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {/*expanded.has('workspace') &&
                            <WorkspaceGridToolbar
                                gridRef={workspaceGridRef}
                                data={workspaceData}
                                setData={handleSetWorkspaceData}
                                tsrKey={tsrKey}
                        />*/}
                    </StyledSummary>
                    <StyledDetails sx={detailHeightProps('workspace')}>
                        <AgGridContainer style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
                            <WorkspaceGrid
                                ref={workspaceGridRef}
                                data={workspaceData}
                                onSelectionChanged={handleGridSelectionChanged}
                                tsrKey={tsrKey}
                                setLegHighlight={setLegHighlight}
                            />
                        </AgGridContainer>
                    </StyledDetails>
                </StyledAccordion>
            </WorkspaceContainer>
        </Box>
    )
}

const TsrGridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(1),
}));

const WorkspaceContainer = styled(Box)(({ theme }) => ({
    height: `100%`,
    width: '100%',
    paddingLeft: theme.spacing(1),
}));

const StyledAccordion = styled(Accordion)(({ theme, }) => ({
    elevation: 3,
    '& .MuiAccordionSummary-root': {
        minHeight: '24px',
        paddingTop: theme.spacing(1),
    },
}));

const StyledDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
}));

const StyledSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
}));