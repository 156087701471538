import { CheckoutWorksheetView } from "./CheckoutWorksheetView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { Dashboard } from "../useDashboard";

export const CheckoutWorksheetDashboard = ({ visible, }) => {
    const dashboardKey = 'checkout-worksheet';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CheckoutWorksheetView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}