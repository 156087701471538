import { useEffect, useState, useCallback, } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import AddNewOrUpdateDialog from '../components/TSRActivity/AddNewDialog'
import { apiUrlPrefix, subscriptionKey, userGroups } from '../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import TSRActivityGrid from '../components/TSRActivity/TSRActivityGrid.js';
import "react-tabs/style/react-tabs.css"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';
import useHeader from '../components/useHeader';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../components/TSRActivity/ConfirmationDialog';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import DivGuard from '../components/Guards/DivGuard';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';


export default ({ visible, application = 'transnow', }) => {
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const [tabIndex, setTabIndex] = useState(0);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [views, setViews] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [viewToUpdate, setViewToUpdate] = useState({});

  useEffect(() => {
    fetchGridViews();
  }, []);

  async function fetchGridViews() {
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch&parm=${guid}&parm=tsractivity`;
    const options = {
      headers: headers,
      url: loadUri,
    }
    axios(options).then(response => {
      setViews(response.data.map(viewData => {
        const view = JSON.parse(viewData.json ?? '{}');
        return {
          ...view,
          id: viewData.userLayoutID,
        }
      }));
    }).catch(err => { enqueueSnackbar(`Error getting view data in real time tsrs.  ${err}`) });
  }

  function saveNewGridView(criteria) {
    if (!criteria?.label) {
      enqueueSnackbar('Label is required to save a new view.', { variant: 'error' });
      return;
    }

    const newView = {
      grid: 'tsractivity',
      json: {
        ...criteria,
        startDate: criteria.startDate ? dayjs(criteria.startDate).format('MM/DD/YYYY') : undefined,
        stopDate: criteria.stopDate ? dayjs(criteria.stopDate).format('MM/DD/YYYY') : undefined,
      },
      template: criteria.label,
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate&parm=${guid}&parm=${newView.grid}&parm=${newView.template}`;

    const options = {
      method: 'POST',
      headers: headers,
      data: newView.json,
      url: saveUri,
    }

    axios(options).then(response => {
      fetchGridViews()
    }).catch(err => { enqueueSnackbar(`Error saving TSR Activity grid data.  ${err.response?.data}`) });
  }

  function handleTabSelect(event, newIndex) {
    if (newIndex <= views.length) { //last tab handles deletes, don't switch to it
      setTabIndex(newIndex);
    }
  }

  const handleDeleteCurrentTab = useCallback(debounce(async (allViews, currentTab, header) => {
    const current = allViews[currentTab];
    const remaining = allViews?.filter(v => v.id !== current.id);
    if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
      setOpenAddNew(true);
    }
    setViews(remaining ?? []);
    setTabIndex(0);
    setOpenConfirmDelete(false);

    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete&parm=${header.userGuid}&parm=tsractivity&parm=${current.label}`;
    const options = {
      headers: header,
      url: deleteUri,
    }

    axios(options).catch(err => { enqueueSnackbar(`Error deleting view(s).  ${err}`) });
  }, 2000, { leading: true, trailing: false, }), []);

  function handleAddNew(criteria) {
    !viewToUpdate.label && setTabIndex(views.length); //Stay on the same tab if we are updating
    setOpenAddNew(false);
    saveNewGridView(criteria);
  }

  function handleAddNewClick() {
    setViewToUpdate({});
    setOpenAddNew(true);
  }

  function handleViewUpdate() {
    setViewToUpdate(views[tabIndex]);
    setOpenAddNew(true);
  }

  function handleConfirmDelete() {
    handleDeleteCurrentTab(views, tabIndex, headers);
  }

  return (
    <DivGuard groups={[userGroups.tsractivity]} >
      <CriteriaProvider>
        <Box
          component="main"
          sx={{
            p: '7px',
            flexGrow: 1,
          }}
        >
          <AddNewOrUpdateDialog
            open={openAddNew}
            viewToUpdate={viewToUpdate}
            handleCancel={() => setOpenAddNew(false)}
            handleAddNew={handleAddNew}
          />
          <ConfirmationDialog
            open={openConfirmDelete}
            message={`You are about to delete the current tab${views[tabIndex]?.label ? ' ' + views[tabIndex].label : ''}. Continue?`}
            onCancel={() => setOpenConfirmDelete(false)}
            onConfirmation={handleConfirmDelete}
          />
          <Box
            sx={{
              borderBottom: 1,
              bgcolor: 'background.paper',
              p: '0px',
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabSelect}
              indicatorColor="primary"
              textColor="primary"
              maxHeight="10px"
              color="primary"
              backgroundColor="transparent"
              sx={{ maxHeight: '40px', minHeight: '40px' }}
            >
              {views.map((view) => (
                <Tab
                  sx={{ maxHeight: '40px', minHeight: '40px' }}
                  backgroundColor="transparent"
                  label={view.label}
                  id={`tsr-activity-tab-${view.id}`} />
              ))}
              <Tab id='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
              <Tab id='editCurrentTab' label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleViewUpdate} sx={{ maxHeight: '40px', minHeight: '40px' }} />
              <Tab id='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} />
            </Tabs>
            {views.map((view, i) => (
              <Collapse in={tabIndex === i} orientation={'vertical'}>
                <TSRActivityGrid
                  visible={(tabIndex === i) && visible}
                  view={view}
                  application={application}
                />
              </Collapse>
            ))}
          </Box>
        </Box>
      </CriteriaProvider>
    </DivGuard>
  );
}