import { TagReportView } from "./TagReportView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { Dashboard } from "../useDashboard";

export const TagReportDashboard = ({ visible, }) => {
    const dashboardKey = 'tag-report';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={TagReportView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}