import { useRef, useMemo, useCallback, forwardRef } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { tooltipRenderer, redPastMarkerFormatter, } from "../Utils"
import { DealDetailCellRenderer } from "./DealDetailCellRenderer"
import useHubObject from "../../HubContext/useHubObject"
import { useUserInfo } from "../../UserInfoContext"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import { useFormContext } from "react-hook-form"
import { useDashboard } from "../useDashboard"
import { useParams } from "react-router-dom"

export const ScheduleSummaryGrid = forwardRef(({ setSelectedDeal, setSelectedRow, getRowId, layoutStorageKey, silentUpdate, }, ref) => {
    const { getValues } = useFormContext();
    const { layout, } = getValues();
    const { columnTypes } = useColumnTypes();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const userInfo = useUserInfo();
    const { enqueueSnackbar, headers, } = useApi();
    const { visible: dashboardVisible } = useDashboard();
    const params = useParams();
    const viewId = params.id;
    const gridIsVisible = dashboardVisible && viewId === getValues('id').toString();

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Schedule changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['scheduleUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo?.tenantId?.toString())
                && (obj.userName !== userInfo.userName || !gridIsVisible); //ignore own updates, unless it was caused on a different grid
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const baseColDefs = useMemo(() => ([
        {
            field: "scheduleID",
            headerName: "Schedule ID",
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 150,
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
        },
        { 
            field: "Schedule_Status", 
            initialWidth: "150px", 
            headerName: "Schedule Status",
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED','DRAFT' ]
            },
        },
        { field: "followTag", initialHide: true, initialWidth: "100px", headerName: "FollowTag?" },
        { field: "linkedTags", initialHide: true, initialWidth: "200px", headerName: "Linked Tags" },
        {
            headerName: "Net Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.scheduleProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        { field: "Tag_Code", headerName: "Tag Code" },
        { field: "Req_Type", headerName: "Req Type" },
        { field: "Req_Status", headerName: "Req Status" },
        { field: "GPE", headerName: "GPE" },
        { field: "GCA", headerName: "GCA" },
        { field: "LCA", headerName: "LCA" },
        { field: "LSE", headerName: "LSE" },
        { field: "TPs", headerName: "TPs" },
        { field: "Sched_Entities", headerName: "Sched Entities" },
        { field: "Tag_Last_Action", headerName: "Tag Last Action" },
        { field: "Tag_Status", headerName: "Tag Status" },
        { field: "Tag_Error", headerName: "Tag Error" },
        // { field: "Tag_Start_Time_MPT", headerName: "Tag Start Time MPT", type: 'dateColumn', },
        // { field: "Tag_Stop_Time_MPT", headerName: "Tag Stop Time MPT", type: 'dateColumn', },
        {
            field: "Tag_Start_Time",
            headerName: "Tag Start Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "Tag_Stop_Time",
            headerName: "Tag Stop Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        { field: "Schedule_Comment", headerName: "Schedule Comment" },
        { field: "Creator", headerName: "Creator" },
        { field: "Modifier", headerName: "Modifier" },
        {
            field: "Start_Date",
            headerName: "Start Date",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "End_Date",
            headerName: "End Date",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "Creation_Time",
            headerName: "Creation Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "Modified_Time",
            headerName: "Modified Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        { field: "POR", headerName: "POR" },
        { field: "POD", headerName: "POD" },
        { field: "PSE_Comment", headerName: "PSE Comment" },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        flex: 1,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    const detailCellRenderer = useMemo(() => {
        return (props) => DealDetailCellRenderer(props);
    }, []);

    const detailRendererParams = useMemo(() => ({
        setSelectedRow: setSelectedDeal,
    }), [setSelectedDeal]);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                ref={ref}
                getRowId={getRowId}
                columnDefs={styledColDefs}
                enableCharts
                onFirstDataRendered={handleFirstDataRendered}
                rowSelection="single"
                rowMultiSelectWithClick
                onSelectionChanged={handleSelectionChanged}
                grandTotalRow={"bottom"}
                onCellMouseOver={handleCellMouseOverHighlight}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                isRowMaster={(rowData) => !!rowData.dealJson}
                masterDetail
                detailCellRenderer={'detailCellRenderer'}
                detailCellRendererParams={detailRendererParams}
                detailRowAutoHeight
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    detailCellRenderer: detailCellRenderer,
                }}
            />
        </AgGridContainer>
    )
});