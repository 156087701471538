import { memo, useState } from "react";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SecurityIcon from '@mui/icons-material/Security';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Drawer } from "../StyledDrawer";
import { List, Tooltip, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import GroupGuard from "../Guards/GroupGuard";
import { userGroups } from "../../authConfig";
import NotesIcon from '@mui/icons-material/Notes';

const SettingsAppMenu = (props) => {
  const { setSelected, selected } = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Drawer variant="permanent" open={openDrawer} onMouseEnter={() => setOpenDrawer(true)} onMouseLeave={() => setOpenDrawer(false)}>
      {/*<Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleClick} size="large" onMouseEnter={handleMouseOver} >
          {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar> 
      <Divider />*/}
      <List>
        <Tooltip title="Manage personal profile information." arrow placement="right">
          <ListItemButton
            selected={selected === 'profile'}
            onClick={() => setSelected('profile')}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </Tooltip>
        <GroupGuard groups={[userGroups.usermapping]}>
          <Tooltip title="Manage user list and mapping to groups." arrow placement="right">
            <ListItemButton
              selected={selected === 'usermapping'}
              onClick={() => setSelected('usermapping')}>
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary="User Mapping" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.realtimegrids, userGroups.dayaheadgrids, userGroups.bulkoriginalrequests, userGroups.dayaheadspreadsheet, userGroups.spymonkey]}>
          <Tooltip title="Manage your OASIS user credentials." arrow placement="right">
            <ListItemButton
              selected={selected === 'oasiscredentials'}
              onClick={() => setSelected('oasiscredentials')}>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText primary="OASIS Credentials" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.certificatemapping]}>
          <Tooltip title="Manage your OASIS certificate mapping." arrow placement="right">
            <ListItemButton
              selected={selected === 'certificatemapping'}
              onClick={() => setSelected('certificatemapping')}>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Certificate Mapping" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="Manage tenant settings." arrow placement="right">
            <ListItemButton
              selected={selected === 'tenantsettings'}
              onClick={() => setSelected('tenantsettings')}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary="Tenant Settings" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.workflow]}>
          <Tooltip title="Use the workflow launcher utility." arrow placement="right">
            <ListItemButton
              selected={selected === 'workflowgrids'}
              onClick={() => setSelected('workflowgrids')}>
              <ListItemIcon>
                <QueuePlayNextIcon />
              </ListItemIcon>
              <ListItemText primary="Workflow Launcher" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.realtimegrids, userGroups.bulkoriginalrequests, userGroups.realtimeoriginals, userGroups.dayaheadgrids]}>
          <Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
            <ListItemButton
              selected={selected === 'tariffprices'}
              onClick={() => setSelected('tariffprices')}>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Tariff Prices" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        {/*<Tooltip title="See the notes for the latest release of Power Station Platform." arrow placement="right">
          <ListItemButton
            selected={selected === 'releasenotes'}
            onClick={() => setSelected('releasenotes')}>
            <ListItemIcon>
              <NotesIcon />
            </ListItemIcon> 
            <ListItemText primary="Release Notes" />
          </ListItemButton>
        </Tooltip>*/}
      </List>
    </Drawer>
  )
}

export default memo(SettingsAppMenu);