import { useState, } from "react";
import { List, Divider, ListItemButton, ListItemIcon, ListItemText, Tooltip, } from "@mui/material"
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupGuard from "../Guards/GroupGuard";
import AddIcon from '@mui/icons-material/Add';
import { useSearchParams } from "react-router-dom";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        alignItems: 'center',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export const Menu = ({ tags, handleAddNew, }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useSearchParams();

  function handleSelection(tag) {
    window.scrollTo(0, 0);
    setSearch({ tag: tag, });
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {open ? (<>
        <Divider sx={{ pt: 2, }}>Filter by Tag</Divider>
        <List>
          <ListItemButton
            selected={search.get('tag') === 'All'}
            onClick={() => handleSelection('All')}>
            <ListItemText primary="All" />
          </ListItemButton>
          {tags.toSorted().map(tag => (
            <ListItemButton
              selected={search.get('tag') === tag}
              onClick={() => handleSelection(tag)}>
              <ListItemText primary={tag} />
            </ListItemButton>
          ))}
          <GroupGuard groups={[]}>
            <Divider />
            <ListItemButton
              onClick={handleAddNew}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add New" />
            </ListItemButton>
          </GroupGuard>
        </List>
      </>) : (
        <FilterAltIcon sx={{ mt: 2, }} />
      )}
    </Drawer>
  )
}