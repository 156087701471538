import { Grid, } from '@mui/material';
import UpdateCard from './UpdateCard';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

export const UpdatesList = (props) => {
  const { updates=[], handleCardSelection, } = props;
  const [search, setSearch] = useSearchParams();

  const cardSizes = useMemo(() => {
    const cardNum = !!updates.length ? updates.length : 1;
    const colsPerTile = Math.floor(12 / cardNum);
    return {
        xs: 12,
        sm: Math.max(colsPerTile, 12),
        md: Math.max(colsPerTile, 6),
        lg: Math.max(colsPerTile, 4),
        xl: Math.max(colsPerTile, 4),
    }
  }, [updates]);

  function byTag(update) {
    const searchTag = search.get('tag');
    if(!searchTag || searchTag === 'All'){
      return true;
    }
    return update.tags.includes(searchTag);
  }

  function handleTagClick(tag, event) {
    event.stopPropagation();
    window.scrollTo(0, 0);
    setSearch({ tag: tag,});
  }

  return (
    <Grid container spacing={3} sx={{ p: 1, }}>
      {updates.filter(byTag).map(update => (
        <Grid item {...cardSizes} key={update.contentID}>
          <UpdateCard content={update} handleSelection={handleCardSelection} handleTagClick={handleTagClick}/>
        </Grid>
      ))}
    </Grid>
  )
}