import { AgGridReact } from "ag-grid-react"
import { forwardRef, useCallback, useMemo, useRef, } from "react";
import { defaultColumnDef, defaultGridOptions, } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { heatmapStyle, tooltipRenderer, handleUpdateTotalRows, redPastMarkerFormatter, } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { Box } from "@mui/material";
import isEqual from 'lodash/isEqual'
import { useDashboardLayout } from "../useDashboardLayout";

export const TagReportGrid = forwardRef(({ setSelectedRows, data, selectedRows, context, }, ref) => {
    const layoutStorageKey = `deal-rizz-scheduling-tag-report-grid-layout-${context.id}`;
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);

    const defaultHourColDef = useCallback((i) => ({
        headerName: `${i}`,
        field: `${i}`,
        enableRowGroup: false,
        aggFunc: 'sum',
        minWidth: 25,
        chartDataType: 'series',
        type: 'numericColumn',
        cellDataType: 'number',
        filter: 'agNumberColumnFilter',
        cellStyle: params => heatmapStyle(params, paletteRef),
    }), []);

    const hourColDefs = useMemo(() => {
        return Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(defaultHourColDef(i));

            if (i === 2) {
                acc.push(defaultHourColDef('2*'));
            }

            return acc;
        }, []);
    }, []);

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Tag Code',
            field: 'tagCode',
            checkboxSelection: true,
            // cellDataType: 'text',
        },
        {
            headerName: 'Tag Idx',
            field: 'tagIdx',
            // checkboxSelection: true,
            cellDataType: 'text',  //why is this this way?
            // cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Tag Status',
            field: 'tagStatus',
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: [
                    'ADJUSTED',
                    'CANCELLED',
                    'CONFIRMED',
                    'CURTAILED',
                    'DENIED',
                    'DRAFT',
                    'EXPIRED',
                    'EXTENDED',
                    'IMPLEMENTED',
                    'PENDING',
                    'RELOADED',
                    'WITHDRAWN'
                ]
            },
        },
        {
            headerName: 'Date',
            field: 'tagDate',
            type: 'dateColumn',
            dateFormat: 'MM/DD/YYYY',
            filter: 'agDateColumnFilter',
        },
        { field: 'firstPOR', headerName: 'First POR', },
        { field: 'lastPOD', headerName: 'Last POD', },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        {
            field: 'Scheduled',
            initialWidth: 100
        },
        {
            headerName: "Full Tag Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            initialHide: true,
            valueGetter: (params) => {
                const json = params.data?.fullTagProfile;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.StartTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.StopTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Tag Profile',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialWidth: 300,
            valueGetter: (params) => {
                const { data, } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(data.tagDate).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(data.tagDate).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...hourColDefs,
        {
            headerName: 'OASIS #',
            field: 'OASIS Number',
        },
        {
            headerName: 'Deal Type',
            field: 'dealType',
        },
        {
            headerName: 'Counterparty',
            field: 'counterParty',
        },
        {
            headerName: 'Composite State',
            field: 'compositeState',
        },
        {
            headerName: 'PSE Contact',
            field: 'PSE Contact',
        },
        {
            headerName: 'Profile Type',
            field: 'ProfileType',
        },
        {
            headerName: 'CC List',
            field: 'CC List',
            valueFormatter: params => {
                //insert spaces after each comma for readability
                return params.value?.split(',').join(', ');
            },
        },
        {
            field: 'LCA',
        },
        {
            field: 'GCA',
        },
        {
            field: 'CPSE',
        },
        {
            field: 'Buy/Sell',
        },
        {
            field: 'Start Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Stop Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'LastUpdated',
            headerName: 'Last Updated',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            aggFunc: 'max',
            enableValue: true,
            enableRowGroup: false,
            valueFormatter: params => {
                return params.value ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            },
        },
        {
            headerName: 'Schedule ID',
            field: 'scheduleId',
        },
        {
            headerName: 'Deal Id',
            field: 'dealId',
        },
        {
            field: 'Notes',
        },
        {
            field: 'Market Path',
        },
        {
            field: 'Total',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: context,
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback((params) => {
        const selectedRows = params.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    function handleRowDataUpdated(params) {
        updateTotalRows(params);
        const currentSelection = ref.current.api.getSelectedRows();
        if (!isEqual(currentSelection, selectedRows)) {
            const id = getRowId({ data: selectedRows[0] });
            const node = ref.current.api.getRowNode(id);
            if (node) {
                node.setSelected(true);
            }
        }
    }

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'tagIdx');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'tag-report-palette'),
        ]
    }), []);

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data?.tagIdx}-${data?.ProfileType}`;
    }, []);

    const handleFilterChanged = useCallback((params) => {
        updateTotalRows(params);
        const api = params.api;
        const selectedNodes = api.getSelectedNodes();
        const displayedNodeIds = new Set();

        api.forEachNodeAfterFilter(node => {
            displayedNodeIds.add(node.id);
        });

        selectedNodes.forEach(node => {
            if (!displayedNodeIds.has(node.id)) {
                node.setSelected(false);
            }
        });
    }, []);

    return (
        <Box onMouseLeave={clearHighlighting} sx={{ width: '100%', height: '100%' }}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ width: '100%', height: '100%' }}
                getRowId={getRowId}
                ref={ref}
                rowData={data}
                columnDefs={styledColDefs}
                gridOptions={gridOptions}
                onSelectionChanged={handleSelectionChanged}
                onRowDataUpdated={handleRowDataUpdated}
                rowSelection="single"
                rowMultiSelectWithClick
                enableCharts
                onFirstDataRendered={handleFirstDataRendered}
                suppressAggFuncInHeader={true}
                groupTotalRow={"bottom"}
                groupSuppressBlankHeader={false}
                onFilterChanged={handleFilterChanged}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                sideBar={sideBar}
                onCellMouseOver={handleCellMouseOverHighlight}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </Box>
    );
});
