import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix } from '../authConfig';
import axios from 'axios';
import useHeader from './useHeader';
import { useSnackbar } from 'notistack';

export default () => {
  const { id } = useParams();
  const header = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [html, setHtml] = useState();

  useEffect(() => {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_EmailHTMLFetch&parm=${id}`

    let options = {
      headers: header,
      method: 'GET',
      url: url, 
    };

    axios(options).then(response => {
      if(response.data) {
        const { emailTemplateID, name, html } = response.data[0];
        setHtml(html);
      }
    }).catch(err => { enqueueSnackbar(`Error loading page. ${err.message}`) });
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} dangerouslySetInnerHTML={{__html: html}}/>
  )
}