import { useMemo, useRef, useCallback } from "react";
import { useTheme } from "@mui/material";

export const useGridCrossHighlight = (gridRef) => {
    const theme = useTheme();
    const highlightRef = useRef({
        nodeId: null,
        colId: null,
    });

    //callback for the onCellMouseOver event of the grid
    const handleCellMouseOverHighlight = useCallback((params) => {
        const oldColId = highlightRef.current.colId;
        const oldNodeId = highlightRef.current.nodeId;
        const colId = params.column.colId;
        const nodeId = params.node.id;
        if (nodeId === oldNodeId && colId === oldColId) {
            return;
        }

        highlightRef.current.nodeId = nodeId;
        highlightRef.current.colId = colId;
        gridRef.current.api.refreshCells({ force: true, rowNodes: [params.node] });
        gridRef.current.api.refreshCells({ force: true, columns: [colId] });
        oldColId && gridRef.current.api.refreshCells({ force: true, columns: [oldColId] });
        if (oldNodeId) {
            const oldNode = gridRef.current.api.getRowNode(oldNodeId);
            if (!oldNode) { //it's the total row
                const pinnedNode = gridRef.current.api.getPinnedBottomRow(0);
                pinnedNode && gridRef.current.api.refreshCells({ force: true, rowNodes: [pinnedNode] });
            } else {
                gridRef.current.api.refreshCells({ force: true, rowNodes: [oldNode] });
            }
        }
    }, []);

    //add to the cell class rules in the default column definition
    const highlightingCellClassRules = useMemo(() => ({
        "ag-cross-highlight-dark": params => {
            return theme.palette.mode === 'dark' && ((highlightRef.current.nodeId === params.node.id) || (highlightRef.current.colId === params.column.colId));
        },
        "ag-cross-highlight-light": params => {
            return theme.palette.mode !== 'dark' && ((highlightRef.current.nodeId === params.node.id) || (highlightRef.current.colId === params.column.colId));
        }
    }), [theme.palette.mode]);

    const clearHighlighting = useCallback(() => {
        highlightRef.current.nodeId = null;
        highlightRef.current.colId = null;
        gridRef.current.api.refreshCells({ force: true });
    }, []);

    return {
        highlightingCellClassRules,
        handleCellMouseOverHighlight,
        clearHighlighting,
    };
};