import { useMemo, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Box, Grid, Tooltip, TextField, Autocomplete, FormControlLabel, Typography, IconButton, } from '@mui/material';
import FormCheckbox from '../../FormControls/FormCheckbox';
import FormAutocomplete from '../../FormControls/FormAutocomplete';
import FormDateTimePicker from '../../FormControls/FormDateTimePicker';
import dayjs from 'dayjs';
import useFilteredCriteria from '../../CriteriaContext/useFilteredCriteria';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array, date, number } from 'yup';
import momentTimezones from '../../momentTimezones';
import { renderStandardTimezones } from '../../../utils/renderTimezones';
import CloseIcon from '@mui/icons-material/Close';

const AddNewDialog = ({ open, viewToUpdate, handleCancel, handleAddNew, showDatePickers }) => {
  const { pors, pods, providers, filteredTSCombos, } = useFilteredCriteria({});
  const formatString = 'MM/DD/YYYY HH:mm';
  //const criteriaStorageKey = 'fast-path-criteria';

  dayjs.extend(utc);
  dayjs.extend(tz);

  const schema = object().shape({
    label: string().required('Label is required').max(50, 'Label must be 50 characters or less').min(3, 'Label must be at least 3 characters'),
    timezone: string().required('Timezone is required'),
    startDate: date().required('Start Date is required').min(dayjs().startOf('day'), 'Start Date cannot be before today.'),
    stopDate: date().required('Start Date is required').min(dayjs().startOf('day'), 'Start Date cannot be before today.').when('startDate', (startDate, schema) => {
      return startDate && schema.min(startDate, 'Stop Date cannot be before Start Date.');
    }),
    por: array().required('POR is required'),
    pod: array().required('POD is required'),
    maxLegs: string().required('Max Legs is required'),
    tsClass: array().min(1, 'TS Class is required'),
    tsIncrement: array().min(1, 'TS Increment is required'),
    tsType: array().min(1, 'TS Type is required'),
    tsPeriod: array().min(1, 'TS Period is required'),
    tsWindow: array().min(1, 'TS Window is required'),
    minCapacity: number().typeError('Value must be an integer.').required('Min Capacity is required').min(0, 'Min Capacity must be at least 0'),
  })

  /*const storedValues = useMemo(() => {
    return JSON.parse(localStorage.getItem(criteriaStorageKey) ?? '{}');
  }, []);*/
  const storedValues = {};

  const defaultValues = useMemo(() => ({
    /*maxLegs: '4',
    por: ['CSWS'],
    pod: ['AECI'],
    hardLimit: false,*/
    tsClass: ['*'],
    tsIncrement: ['*'],
    tsType: ['*'],
    tsPeriod: ['*'],
    tsWindow: ['*'],
    ...storedValues,
    ...viewToUpdate,
    timezone: handleSetTimezone(viewToUpdate?.timezone ?? storedValues.timezone ?? 'Pacific'),
    startDate: dayjs(viewToUpdate?.startDate).tz(momentTimezones[storedValues.timezone ?? 'Pacific'], true),//.startOf('hour').add(1, 'hour'),
    stopDate: dayjs(viewToUpdate?.stopDate).tz(momentTimezones[storedValues.timezone ?? 'Pacific'], true),//.startOf('hour').add(2, 'hour'),
  }), [viewToUpdate]);

  function handleSetTimezone(tz) {
    return tz ? tz.split(' ')[0] : 'Pacific'; //If user has e.g. Mountain Standard Time in local storage, we need to convert it to Mountain
  }

  const { register, handleSubmit, control, formState: { errors, }, watch, setValue, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [viewToUpdate]);

  const maxLegs = watch('maxLegs');
  const minCapacity = watch('minCapacity');
  const timezone = watch('timezone');

  function handleWildcardUpdate(key, value) {
    const [lastAdded] = value?.slice(-1);
    if (lastAdded === '*') {
      setValue(key, ['*'])
    } else {
      setValue(key, value.filter(option => option !== '*'))
    }
  }

  const maxLegOptions = [...Array(10).keys()].map(i => `${i + 1}`)

  let maxLegHelperText = '';
  if (errors.maxLegs) {
    maxLegHelperText = errors.maxLegs.message;
  } else if (maxLegs > 3) {
    maxLegHelperText = 'Load times may be slower when including large numbers of legs.'
  }

  let minCapacityHelperText = '';
  if (errors.minCapacity) {
    minCapacityHelperText = errors.minCapacity.message;
  } else if (minCapacity < 50) {
    minCapacityHelperText = 'Load times may be slower with a low min capacity.'
  }

  function onSubmit(data) {
    const { startDate, stopDate, ...rest } = data;
    handleAddNew({
      startDate: dayjs(startDate).tz(momentTimezones[rest.timezone]).format(formatString),
      stopDate: dayjs(stopDate).tz(momentTimezones[rest.timezone]).format(formatString),
      ...rest,
      timezone: rest.timezone.includes('Standard') ? rest.timezone : renderStandardTimezones[rest.timezone],
    });
  }

  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{viewToUpdate?.id ? 'Edit View Criteria' : 'Add New View'}</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={handleCancel} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form id='add-new-map-view' onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" sx={{ p: 1, }} spacing={2}>

            <Grid item xs={6}>
              <Tooltip title="The name of the view." arrow placement="top">
                <TextField
                  {...register('label')}
                  variant="outlined"
                  size='small'
                  fullWidth
                  label="Label"
                  color="success"
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <FormAutocomplete
                fullWidth
                options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                control={control}
                name='timezone'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Timezone"
                    size='small'
                    color="success"
                    error={!!errors.timezone}
                    helperText={errors.timezone?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <FormAutocomplete
                name='maxLegs'
                control={control}
                fullWidth
                options={maxLegOptions}
                renderInput={(params) => (
                  <Tooltip title="The maximum number of legs in a path." arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      sx={{ '& p': { color: 'orangered', } }}
                      variant="outlined"
                      label="Max Legs"
                      color="success"
                      error={!!errors.maxLegs}
                      helperText={maxLegHelperText}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            {showDatePickers && <Grid item xs={3}>
              <FormDateTimePicker
                name='startDate'
                control={control}
                disablePast
                fullWidth
                label="Start Date"
                format="MM/DD/YYYY HH:mm"
                views={['year', 'month', 'day', 'hours']}
                timezone={momentTimezones[timezone]}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: !!errors.startDate,
                    helperText: errors.startDate?.message,
                    fullWidth: true,
                  }
                }}
              />
            </Grid>}

            {showDatePickers && <Grid item xs={3}>
              <FormDateTimePicker
                name='stopDate'
                control={control}
                disablePast
                label="Stop Date"
                fullWidth
                format="MM/DD/YYYY HH:mm"
                views={['year', 'month', 'day', 'hours']}
                timezone={momentTimezones[timezone]}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: !!errors.stopDate,
                    helperText: errors.stopDate?.message,
                    fullWidth: true,
                  }
                }}
              />
            </Grid>}

            <Grid item xs={6}>
              <FormAutocomplete
                name='por'
                control={control}
                fullWidth
                autoHighlight
                multiple
                options={pors ?? []}
                renderInput={(params) => (
                  <Tooltip title="Choose a Point of Receipt." arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POR"
                      error={!!errors.por}
                      helperText={errors.por?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name='pod'
                control={control}
                fullWidth
                autoHighlight
                multiple
                options={pods ?? []}
                renderInput={(params) => (
                  <Tooltip title="Choose a Point of Delivery." arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POD"
                      error={!!errors.pod}
                      helperText={errors.pod?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name='tsClass'
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsClass', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.tsClasses]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Class" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Class"
                      color="success"
                      error={!!errors.tsClass}
                      helperText={errors.tsClass?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name='tsIncrement'
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsIncrement', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.serviceIncrements]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Increment" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Increment"
                      color="success"
                      error={!!errors.tsIncrement}
                      helperText={errors.tsIncrement?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'tsType'}
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsType', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.tsTypes]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Type" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Type"
                      color="success"
                      error={!!errors.tsType}
                      helperText={errors.tsType?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'tsPeriod'}
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsPeriod', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.tsPeriods]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Period" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Period"
                      color="success"
                      error={!!errors.tsPeriod}
                      helperText={errors.tsPeriod?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'tsWindow'}
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsWindow', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.tsWindows]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Window" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Window"
                      color="success"
                      error={!!errors.tsWindow}
                      helperText={errors.tsWindow?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'tsSubclass'}
                control={control}
                onChange={(_, newVal) => {
                  handleWildcardUpdate('tsSubclass', newVal);
                }}
                fullWidth
                multiple
                options={['*', ...filteredTSCombos.tsSubclasses]}
                renderInput={(params) => (
                  <Tooltip title="Select * to include any TS Subclass" arrow placement="top">
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Subclass"
                      color="success"
                      error={!!errors.tsSubclass}
                      helperText={errors.tsSubclass?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'excludeTp'}
                control={control}
                fullWidth
                multiple
                options={providers ?? []}
                renderInput={(params) => (
                  <Tooltip title="Selected providers will not appear in results." arrow placement="top">
                    <TextField
                      {...params}
                      variant="outlined"
                      size='small'
                      label="Excluded Providers"
                      color="success"
                      error={!!errors.excludeTp}
                      helperText={errors.excludeTp?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormAutocomplete
                name={'excludePoints'}
                control={control}
                fullWidth
                multiple
                options={pors ?? []}
                renderInput={(params) => (
                  <Tooltip title="Selected points will not appear in results." arrow placement="top">
                    <TextField
                      {...params}
                      variant="outlined"
                      size='small'
                      label="Excluded Points"
                      color="success"
                      error={!!errors.excludePoints}
                      helperText={errors.excludePoints?.message}
                    />
                  </Tooltip>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Tooltip title="The minimum available MW across all legs for the first hour of the specified range." arrow placement="top">
                <TextField
                  {...register('minCapacity')}
                  sx={{ '& p': { color: 'orangered', } }}
                  variant="outlined"
                  size='small'
                  fullWidth
                  label="Min Capacity"
                  color="success"
                  error={!!errors.minCapacity}
                  helperText={minCapacityHelperText}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip title='If selected, results will only contain trans with the minimum capacity available across the entire profile.' arrow placement='top'>
                <FormControlLabel
                  control={
                    <FormCheckbox name={'hardLimit'} control={control} />
                  }
                  label="Hard Limit"
                />
              </Tooltip>
            </Grid>
          </Grid>

        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant='contained'>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button type='submit' form='add-new-map-view' variant='contained'>Save & Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewDialog;