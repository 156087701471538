import { useImperativeHandle, useRef, useMemo, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { useDashboardFetch } from "../useDashboardFetch"
import { useDashboardLayout } from "../useDashboardLayout"
import { IndexPricingRibbon } from "./Ribbon/IndexPricingRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { useGridCrossHighlight } from "../useGridCrossHighlight"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    date: yup.date().required('Date is required'),
});

export const IndexPricingView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-index-pricing-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const headers = useHeader();
    const { logAction } = useActionAudit();
    const { captureLayout } = useLayoutFunctions();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);

    const baseColDefs = useMemo(() => [
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerName: 'Index Name',
            field: 'indexName',
        },
        {
            headerName: 'Market',
            field: 'market',
        },
        {
            headerName: 'Market Type',
            field: 'marketType',
        },
        {
            headerName: 'Zone',
            field: 'zone',
        },
        {
            headerName: 'Original Currency',
            field: 'originalCurrency',
        },
        {
            headerName: 'Date',
            field: 'date',
        },
        {
            headerName: 'Description',
            field: 'description',
        },
        {
            headerName: 'Name',
            field: 'name',
        },
        {
            headerName: 'Source',
            field: 'source',
        },
        {
            headerName: 'Type',
            field: 'type',
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
        })),
    ], []);

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        date: dayjs(),
    }), [view]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const getRowId = useCallback(({ data }) => `${data.description}-${data.name}-${data.source}-${data.type}`, []);

    const { loadAndApplyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchIndexPrices',
        fetchParamKeys: ['userGuid', 'date', 'timezone'],
        gridRef,
        getRowId,
    });

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef,
        layoutStorageKey,
        context: view,
        baseColDefs,
        defaultColDef,
    });

    function handleFetchData(data) {
        logAction('User fetched Index Pricing Data', 'Index Pricing', data);
        loadAndApplyData({
            ...data,
            userGuid: headers.userGuid,
            date: dayjs(data.date).format('MM/DD/YYYY'),
        });
    }

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const toolbarFormId = 'index-pricing-toolbar-form';

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
                <IndexPricingRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                />
                <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                    <AgGridReact
                        {...defaultGridOptions}
                        containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                        ref={gridRef}
                        enableCharts
                        getRowId={getRowId}
                        columnDefs={styledColDefs}
                        onFirstDataRendered={handleFirstDataRendered}
                        onGridReady={onGridReady}
                        columnTypes={columnTypes}
                        statusBar={defaultStatusBar}
                        sideBar={sideBar}
                        onCellMouseOver={handleCellMouseOverHighlight}
                        components={{
                            layoutToolPanel: LayoutToolPanel,
                        }}
                    />
                </AgGridContainer>
            </Box>
        </ViewContextProvider>
    )
});