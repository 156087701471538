import { useMemo } from "react";
import { Helmet } from "react-helmet-async"
import { useLocation } from 'react-router-dom';
import { routeConfigs } from "../routeConfig";

//Uses react helmet to inject metadata relevant to our openly accessible pages, e.g. Fast Path
export const SEO = () => {
    const { pathname } = useLocation();

    const Meta = useMemo(() => {
        const key = Object.keys(routeConfigs).find(config => pathname === routeConfigs[config].path);
        if(key && routeConfigs[key]?.meta) {
            return routeConfigs[key].meta;
        } else {
            return <DefaultMeta />
        } 
    }, [pathname])

    return Meta; 
}

export const FastPathMeta = () => {
    return <Helmet>
        <title>Fast Path - Least Cost Transmission Routes</title>
        <meta name='description' content='Fast Path finds the lowest cost transmission routes for a given POR and POD. Easily find least cost pathways based on your organizations search criteria including risk factors and tenor.'/>
        <meta name='robots' content='index, nofollow' />
    </Helmet>
}

export const TransHunterMeta = () => {
    return <Helmet>
        <title>Trans Hunter - Energy Transmission Availability Alerts</title>
        <meta name='description' content='Trans Hunter lets you visualize transmission availability across all segments and then create alerts to tell you and your team when transmission becomes available.'/>
        <meta name='robots' content='index, nofollow' />
    </Helmet>
}

export const TransNowMeta = () => {
    return <Helmet>
        <title>Trans Now - Power Transmission Bulk Automation</title>
        <meta name='description' content='Trans Now is bulk automation for day-ahead and real-time transmission trading. Drag and drop redirects and reassignments allow for lightning fast trading operations.'/>
        <meta name='robots' content='index, nofollow' />
    </Helmet>
}

export const DefaultMeta = ({ robotsContent='noindex, nofollow' }) => {
    return <Helmet>
        <title>PowerStation - Solutions for Energy Trading and Transmission</title>
        <meta name='description' content='For energy traders and trading managers, PowerStation Platform is your one-stop point solution for bulk automation of trading operations.'/>
        <meta name='robots' content={robotsContent} />
    </Helmet>
}