import { useState, useEffect, useRef } from 'react';
import { userGroups } from "../authConfig";
import DivGuard from "../components/Guards/DivGuard";
import { FastPath, TSRActivity } from '.';
import Collapse from '@mui/material/Collapse';
import FastPathMenu from '../components/FastPathPro/FastPathMenu';
import FastPathPro from '../components/FastPathPro/FastPath';
import Help from '../components/Help';
import { Box } from '@mui/material';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';
import TariffPrices from '../components/TransNow/TariffPrices';

export default () => {
  const viewStorageKey = 'fast-path-pro-view';
  const savedView = localStorage.getItem(viewStorageKey);
  const [view, setView] = useState(savedView ?? 'fastpath');
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (view && !loadedViews[view]) {
      setLoadedViews({
        ...loadedViews,
        [view]: true
      })
    }
  }, [view]);

  useEffect(() => {
    if (view) {
      localStorage.setItem(viewStorageKey, view);
    }
  }, [view])

  return (
    <CriteriaProvider>
      <Box sx={{ display: 'flex', }}>
        <FastPathMenu selected={view} handleSelection={(newView) => setView(newView)} />
        <Box
          component="main"
          sx={{
            p: '0px',
            flexGrow: 1,
          }}
        >
          {loadedViews['fastpathhelp'] && <Collapse in={view === 'fastpathhelp'}>
            <Help appName={'FastPath'} />
          </Collapse>}
          {(loadedViews['fastpath'] || loadedViews['dashboard']) && <Collapse in={['fastpath', 'dashboard'].includes(view)}>
            <FastPathPro view={view} />
          </Collapse>}
          {loadedViews['activity'] && <Collapse in={view === 'activity'}>
            <TSRActivity visible={view === 'activity'} application='fastpathpro' />
          </Collapse>}
          {loadedViews['legacy'] && <Collapse in={view === 'legacy'}>
            <DivGuard groups={[userGroups.admins]}>
              <FastPath />
            </DivGuard>
          </Collapse>}
          {loadedViews['audit'] && <Collapse in={view === 'audit'}>
            <TransHunterAuditGrid />
          </Collapse>}
          {loadedViews['transactionqueue'] && <Collapse in={view === 'transactionqueue'}>
            <PathMonitor application={'fastpathpro'} />
          </Collapse>}
          {loadedViews['tariffprices'] && <Collapse in={view === 'tariffprices'} >
            <DivGuard groups={[userGroups.fastpathpro]} >
              <TariffPrices />
            </DivGuard>
          </Collapse>}
        </Box>
      </Box>
    </CriteriaProvider>
  )
}