import { Typography, Stack, } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { MessageChip } from './MessageChip';

export const Message = (props) => {
  const { message, error = false, } = props;
  const theme = useTheme();

  const StyledStack = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxWidth: '100%',
    width: 'fit-content',
    maxWidth: '90%',
    marginBottom: '5px',
    ...(message.sender === 'user' && {
      float: 'right',
      marginLeft: 'auto',
    }),
    ...(message.sender !== 'user' && {
      float: 'left',
      marginRight: 'auto',
    }),
  }));

  const messageDetailColor = error ? theme.palette.error.main : 'textSecondary';
  const messageIcon = error && <WarningAmberIcon />;

  let stamp = null;
  if (error) {
    stamp = 'Message failed to send.';
  } else if (message.timeStamp) {
    stamp = message.timeStamp.toLocaleString();
  }

  return (
    <StyledStack>
      <MessageChip
        label={message.content}
        icon={messageIcon}
        sender={message.sender}
        error={error}
      />
      <Typography variant='caption' color={messageDetailColor} float='right' marginLeft='auto'>
        {stamp}
      </Typography>
    </StyledStack>
  )
}