import { useEffect, useMemo, useState, } from 'react';
import AppsIcon from "@mui/icons-material/Apps";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppBar, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { PopoverNav, AppMenu, SettingsMenu } from "./index";
import { apiUrlPrefix, userGroups, } from '../authConfig.js';
import { useMsal, } from "@azure/msal-react";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SupportMenu from './SupportMenu';
import axios from "axios";
import useHeader from './useHeader';
import useErrorMessages from './useErrorMessages';
import { routeConfigs } from '../routeConfig';
import { useLocation, useParams } from 'react-router-dom';
import Confetti from './Confetti';
import { useActionAudit } from './useActionAudit';
import GroupGuard from './Guards/GroupGuard';
import UserMessages from './UserMessages';
import { InteractionStatus } from "@azure/msal-browser";
import { ToggleColorModeButton } from './ToggleColorModeButton';
import { viewTitles as dealRizzTitles, } from './DealRizz/DealRizz';
import { viewTitles as brainTitles, } from './Brain/Brain';
import { routePaths } from '../routePaths';

const viewTitles = { [routePaths.dealrizz]: dealRizzTitles, [routePaths.brain]: brainTitles };

export default () => {
  const { parseError, parseResponseForError } = useErrorMessages();
  const headers = useHeader();
  const { logAction } = useActionAudit();
  const { accounts, inProgress, } = useMsal();
  const [ip, setIp] = useState(null);

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json")
      .then((response) => {
        setIp(response.data.ip);
      }).catch(error => {
        console.log(error);
        setIp('error');
        const userName = localStorage.getItem('DefaultUserName');
        logAction(`Failed to get IP address${userName ? ' for user' + userName : ''}`, 'Header', JSON.stringify(error));
      });
  }, []);

  useEffect(() => {
    if (InteractionStatus.None === inProgress && ip) {
      if (accounts.length > 0) {
        logUserSignIn();
      } else if (ip !== 'error') {
        logUserIp();
      }
    }
  }, [inProgress, accounts, ip]);

  function logUserSignIn() {
    const acc = accounts[0];
    const guid = acc.localAccountId ?? headers.userGuid ?? '';
    const first = acc.idTokenClaims.given_name;
    const last = acc.idTokenClaims.family_name;
    const href = window.location.href;

    let emailAdress = acc.username;
    if (!emailAdress) {//if there is no userName, try to read out of local storage
      const userName = localStorage.getItem('DefaultUserName');
      emailAdress = userName;
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_AuditInsert&parm=${guid}&parm=${encodeURIComponent(emailAdress ?? '')}&parm=UserIsAuthenticated&parm=PowerStationHeader (${encodeURIComponent(href)})&parm=${first ?? ''}&parm=${last ?? ''}`

    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    }

    ip !== 'error' && logUserIp();
    axios(options).then(response => {
      parseResponseForError(response);
    }).catch(error => {
      parseError(error, url);
    });
  }

  function logUserIp() {
    logAction(`User with IP address ${ip} is using PowerStation. Their default user name is ${localStorage.getItem('DefaultUserName')}.`, 'Header', JSON.stringify({ IP: ip }));
  }

  const location = useLocation();
  const params = useParams();
  const title = useMemo(() => {
    const path = location.pathname;
    const appPath = '/' + path.split('/')[1];
    const key = Object.keys(routeConfigs).find(config => path === routeConfigs[config].path);
    if (key && routeConfigs[key]?.name) {
      return `Power Station Platform - ${routeConfigs[key].name}`;
    } else if ([routePaths.dealrizz, routePaths.brain].includes(appPath)) {
      const viewName = viewTitles[appPath][params.view];
      return `Power Station Platform${viewName ? ' - ' + viewName : ''}`;
    } else {
      return 'Power Station Platform';
    }
  }, [location]);

  return (
    <AppBar position="sticky" >
      <GroupGuard groups={[userGroups.admins]}>
        <Confetti />
      </GroupGuard>
      <UserMessages />
      <Toolbar
        variant="dense"
        sx={{
          background: "transparent linear-gradient(90deg, #272360 0%, #10A9FF 29%, #CEDA28 52%, #1C66B0 75%, #272360 100%) 0% 0% no-repeat padding-box",
          opacity: "1.0",
        }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
          <ToggleColorModeButton />
          <PopoverNav icon={
            <AppsIcon sx={{ color: "white" }} fontSize="large" />
          }>
            <AppMenu />
          </PopoverNav>
          <PopoverNav icon={
            <AccountCircleIcon sx={{ color: "#CEDA28" }} fontSize="large" />
          }>
            <SettingsMenu />
          </PopoverNav>
          <PopoverNav icon={
            <LiveHelpIcon sx={{ color: "white" }} fontSize="large" />
          }>
            <SupportMenu />
          </PopoverNav>
        </Box>
      </Toolbar>
    </AppBar>
  );
}