import { useState, useCallback } from "react";
import { apiUrlPrefix, userGroups } from "../../authConfig";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Button, Stack, Switch, Tooltip, Box, FormGroup, FormControlLabel } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import ConfirmationDialog from "./ConfirmationDialog";
import UndoIcon from '@mui/icons-material/Undo';
import PublishIcon from '@mui/icons-material/Publish';
import { TransmissionDialog } from "./TransmissionDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useActionAudit } from "../useActionAudit";
import convertTimezones from "../convertTimezones";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { TransAlertDialog } from "./TransAlertDialog";
import { useUserGroups } from "../../data/useUserGroups";
import { debounce } from 'lodash';

export default (props) => {
  const { onRefresh, clearSelected, selectedRows, disableAllButtons, handleTriggerClick, autoRefresh, setAutoRefresh, timezone = 'Pacific Standard Time', application = 'transnow', } = props;
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [actOnConfirm, setActOnConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [openTransmissionDialog, setOpenTransmissionDialog] = useState(false);
  const [transmissionData, setTransmissionData] = useState({});
  const { logAction } = useActionAudit();
  const { userIsInGroup } = useUserGroups();
  const isAdmin = userIsInGroup(userGroups.admins);
  const allowTrans = isAdmin || userIsInGroup(userGroups.fastpathpro, userGroups.realtimegrids, userGroups.realtimeoriginals);

  function handleConfirmAction(action) {
    const { AssignmentRef } = selectedRows[0];
    const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ConfirmApplicationAction&parm=${guid}&parm=${AssignmentRef}&parm=${action}&parm=transnowtsractivity&parm=`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      if (response.data && response.data[0]) {
        const { goForward, userMessage } = response.data[0];
        setActOnConfirm(!!goForward);
        setConfirmationMessage(userMessage);
        setConfirmationAction(action);
        setOpenConfirmationDialog(true);
      } else {
        enqueueSnackbar('Error- api fetch returned no data.')
      }
    }).catch(error => {
      enqueueSnackbar(`Error confirming TSR. ${error.message}`)
    });
  }

  //adding a comment
  function handleConfirmation() {
    const { AssignmentRef, Provider } = selectedRows[0];
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/${confirmationAction}?userGuid=${guid}&aref=${AssignmentRef}&primaryProvider=${Provider}`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      enqueueSnackbar(`Success`)
      setOpenConfirmationDialog(false);
    }).catch(error => {
      // enqueueSnackbar(`${JSON.stringify(error)}`)
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
      // enqueueSnackbar(`${error.message}`)
      setOpenConfirmationDialog(false);
    });
  }

  async function handleInsertAlert(data) {
    const params = {
      userGuid: headers.userGuid,
      action: `transAlert`,
      timeZone: '',
      TargetExecutionTime: '',
      application: application,
      data: data,
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${headers.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=1&parm=${params.application}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    return axios(options).catch(error => {
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
    });
  }

  const handleCloseTransmissionDialog = useCallback(() => {
    setOpenTransmissionDialog(false);
  }, [])

  function handleReserveClick() {
    logAction('User clicked to reserve a new TSR', 'TSR Activity');
    setOpenTransmissionDialog(true);
    setTransmissionData({});
  }

  function handleDuplicateClick() {
    logAction(`User clicked to duplicate a TSR with Aref ${selectedRows[0].AssignmentRef}`, 'TSR Activity');
    setTransmissionData(selectedRows[0]);
    setOpenTransmissionDialog(true);
  }

  const allowConfirm = (
    allowTrans &&
    (selectedRows.length === 1) &&
    ['ACCEPTED', 'COUNTEROFFER',].includes(selectedRows[0].Status)
  )

  const allowWithdraw = (
    allowTrans &&
    (selectedRows.length === 1) &&
    ['QUEUED', 'RECEIVED', 'STUDY', 'COUNTEROFFER', 'REBID', 'ACCEPTED'].includes(selectedRows[0].Status)
  )

  const handleTransAlertClick = useCallback(debounce(() => {
    logAction('User clicked to set a transmission alert', 'TSR Activity', selectedRows[0]);
    handleInsertAlert(selectedRows[0]).then(response => {
      enqueueSnackbar('Alert successfully created.', { variant: 'success' });
    });
    clearSelected();
  }, 2000, { leading: true }), [selectedRows])

  return (
    <Box sx={{ display: 'flex', p: 1, paddingTop: 2 }}>
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleConfirmation}
        confirmText={actOnConfirm ? 'YES' : 'OK'}
        singleAction={!actOnConfirm}
      />
      <TransmissionDialog
        open={openTransmissionDialog}
        data={transmissionData}
        closeDialog={handleCloseTransmissionDialog}
        application={application}
      />
      <Stack direction='row' spacing={1} useFlexGap flexWrap="wrap">
        <Tooltip title="Create a new TSR." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            variant='contained'
            onClick={handleReserveClick}
            disabled={disableAllButtons}
          >Reserve</Button>
        </Tooltip>
        <Tooltip title="Select a TSR below and click here to create a similar TSR." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant='contained'
            onClick={handleDuplicateClick}
            disabled={(selectedRows.length !== 1) || disableAllButtons}
          >Duplicate</Button>
        </Tooltip>
        <Tooltip title='Get an alert when selected TSRs have a status change.' arrow placement="top">
          <Button
            endIcon={<ChangeHistoryIcon />}
            variant={'contained'}
            color={'primary'}
            onClick={handleTriggerClick}
            disabled={!(selectedRows.length === 1) || disableAllButtons}
          >Alert on Status Change</Button>
        </Tooltip>
        <Button
          endIcon={<TroubleshootIcon />}
          variant={'contained'}
          color={'primary'}
          onClick={handleTransAlertClick}
          disabled={!(selectedRows.length === 1) || disableAllButtons}
        >Alert on Transmission</Button>
        <Button
          endIcon={<CheckIcon />}
          variant={'contained'}
          color={'primary'}
          onClick={() => handleConfirmAction('confirm')}
          disabled={!allowConfirm || disableAllButtons}
        >CONFIRM</Button>
        <Button
          endIcon={<UndoIcon />}
          variant={'contained'}
          color={'primary'}
          onClick={() => handleConfirmAction('withdraw')}
          disabled={!allowWithdraw || disableAllButtons}
        >WITHDRAW</Button>
        <b style={{ paddingRight: 7, display: 'flex', alignItems: 'center', marginLeft: '100px' }}>Timezone: {convertTimezones[timezone]}</b>
        <FormGroup>
          <FormControlLabel control={<Switch checked={autoRefresh} onChange={() => setAutoRefresh(!autoRefresh)} />} label="Auto-refresh" />
        </FormGroup>
        <Tooltip title='Reload data with the current filter criteria.' arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            size={'small'}
            variant={'contained'}
            color={'primary'}
            onClick={onRefresh}
            disabled={disableAllButtons}
          >Refresh</Button>
        </Tooltip>
        <Tooltip title='Deselect all rows in the grid.' arrow placement="top">
          <Button
            endIcon={<RemoveDoneIcon />}
            size={'small'}
            variant={'contained'}
            color={'primary'}
            onClick={clearSelected}
            disabled={!selectedRows.length || disableAllButtons}
          >Clear Selected</Button>
        </Tooltip>
      </Stack>
    </Box>
  )
}