import { Dashboard } from "../useDashboard";
import { LMPGrid } from "./LMPGrid";
import AddNewOrUpdateDialog from "./AddNewOrUpdateDialog";

export const LMPs = ({ visible, }) => {
    const dashboardKey = 'LMPGrids';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={LMPGrid}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}