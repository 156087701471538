import { useState } from 'react';
import CornerRibbon from 'react-corner-ribbon';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import { Tooltip } from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { userGroups } from '../../authConfig.js';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import GroupGuard from '../Guards/GroupGuard.js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default ({ handleSelection, selected }) => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <List>
        <GroupGuard authenticatedOnly>
          <Tooltip title="View information about TransHunter." arrow placement="right">
            <ListItemButton
              selected={selected === 'transhunterhelp'}
              onClick={() => handleSelection('transhunterhelp')}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="TransHunter Help" />
            </ListItemButton>
          </Tooltip>
          <Divider />
        </GroupGuard>
        <Tooltip title={`${open ? '' : 'Availability Alerts'}`} arrow placement="right">
          <ListItemButton
            selected={selected === 'Availability Alerts'}
            onClick={() => handleSelection('Availability Alerts')}>
            <ListItemIcon>
              <AddAlarmIcon />
            </ListItemIcon>
            <ListItemText primary="Availability Alerts" />
          </ListItemButton>
        </Tooltip>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title={`${open ? '' : 'Long Term Availability'}`} arrow placement="right">
              <ListItemButton
                selected={selected === 'Long Term Availability'}
                onClick={() => handleSelection('Long Term Availability')}>
                <ListItemIcon>
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary="Long Term Availability" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard><GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title={`${open ? '' : 'All Daily Segments'}`} arrow placement="right">
              <ListItemButton
                selected={selected === 'All Daily Segments'}
                onClick={() => handleSelection('All Daily Segments')}>
                <ListItemIcon>
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary="All Daily Segments" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title={`${open ? '' : 'Flowgates'}`} arrow placement="right">
              <ListItemButton
                selected={selected === 'Flowgates'}
                onClick={() => handleSelection('Flowgates')}>
                <ListItemIcon>
                  <ForkLeftIcon />
                </ListItemIcon>
                <ListItemText primary="Flowgates" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title={`${open ? '' : 'Trans Offerings'}`} arrow placement="right">
              <ListItemButton
                selected={selected === 'Trans Offerings'}
                onClick={() => handleSelection('Trans Offerings')}>
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="Trans Offerings" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title={`${open ? '' : 'Summary Statistics'}`} arrow placement="right">
              <ListItemButton
                selected={selected === 'Summary Statistics'}
                onClick={() => handleSelection('Summary Statistics')}>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Summary Statistics" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <Tooltip title={`${open ? '' : 'Outages'}`} arrow placement="right">
          <ListItemButton
            selected={selected === 'Outages'}
            onClick={() => handleSelection('Outages')}>
            <ListItemIcon>
              <AppsOutageIcon />
            </ListItemIcon>
            <ListItemText primary="Outages" />
          </ListItemButton>
        </Tooltip>
        <Divider />
        {/*<GroupGuard groups={[userGroups.pathmonitor]}>
          <Tooltip title="Get alerts on activity on paths." arrow placement="right">
            <ListItemButton 
              selected={selected === 'pathmonitor'}
              onClick={() => handleSelection('pathmonitor')}>
              <ListItemIcon>
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Path Monitor" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>*/}
        <GroupGuard authenticatedOnly>
          <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
            <ListItemButton
              selected={selected === 'tsractivity'}
              onClick={() => handleSelection('tsractivity')}>
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="TSR Activity" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.transhunter]}>
          <Tooltip title="View and edit your transaction alerts." arrow placement="right">
            <ListItemButton
              selected={selected === 'transactionqueue'}
              onClick={() => handleSelection('transactionqueue')}>
              <ListItemIcon>
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Trans Q" />
            </ListItemButton>
          </Tooltip>
          <Divider />
        </GroupGuard>
      </List>
      <List>
        <GroupGuard groups={[userGroups.transhunter]}>
          <Tooltip title="See the audit log." arrow placement="right">
            <ListItemButton
              selected={selected === 'audit'}
              onClick={() => handleSelection('audit')}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Audit Log" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
      </List>
    </Drawer>
  );
}