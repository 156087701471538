import { useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { SignOnDialog } from './SignOnDialog';
import { useLocation } from "react-router-dom";
import { useActionAudit } from './useActionAudit';

export default (props) => {
    const [openLogin, setOpenLogin] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();
    const app = location.pathname.split('/')[1];
    const { logAction } = useActionAudit();
    
    function handleSignIn() {
        logAction('User clicked Sign In', `${app}`);
        setOpenLogin(true);
    }
    
    return (
        <>
            <SignOnDialog 
                open={openLogin}
                onClose={() => setOpenLogin(false)}
            />
            <Button
                disabled={isAuthenticated}
                {...props}
                onClick={handleSignIn}
            />
        </>
    )
};