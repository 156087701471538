import { useState, useContext } from "react";
import { AppBar, Button, Stack, ToggleButtonGroup, ToggleButton, List, Divider, ListItemButton, ListItemIcon, ListItemText, Tooltip, IconButton, Toolbar, } from "@mui/material"
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupGuard from '../Guards/GroupGuard.js';
import { userGroups } from '../../authConfig.js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TableChartIcon from '@mui/icons-material/TableChart';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      overflowX: 'hidden',
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default (props) => {
  const { selected, handleSelection } = props;
  const storageKey = 'power-folio-menu-open';
  const defaultOpen = JSON.parse(localStorage.getItem(storageKey) ?? 'true');
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <List>
        <Tooltip title="View information about Power Folio" arrow placement="right">
          <ListItemButton
            selected={selected === 'powerfoliohelp'}
            onClick={() => handleSelection('powerfoliohelp')}>
            <ListItemIcon>
              <QuestionMarkIcon />
            </ListItemIcon>
            <ListItemText primary="PowerFolio Help" />
          </ListItemButton>
        </Tooltip>
      </List>
      <Divider />
      <List>
        <ListItemButton
          selected={selected === 'powerfolio'}
          onClick={() => handleSelection('powerfolio')}>
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText primary="PowerFolio" />
        </ListItemButton>
        <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
          <ListItemButton
            selected={selected === 'activity'}
            onClick={() => handleSelection('activity')}>
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary="TSR Activity" />
          </ListItemButton>
        </Tooltip>
      </List>
    </Drawer>
  )
}