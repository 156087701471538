import { useState, useEffect, forwardRef, useContext } from 'react';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { userGroups } from "../authConfig";
import Collapse from '@mui/material/Collapse';
import Help from '../components/Help';
import TransHunterAppMenu from '../components/TransHunter/TransHunterAppMenu';
import TransAlerts from '../components/TransHunter/TransAlerts';
import GroupGuard from '../components/Guards/GroupGuard';
import { UserContext } from "../data/fetchUserGroups";
import TSRActivity from './TSRActivity';
import CornerRibbon from 'react-corner-ribbon';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import { Box, Button, Stack, Fade } from '@mui/material';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';

const MyRibbon = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <CornerRibbon
        style={{ fontSize: 20, p: 5 }}
        position="top-right"
      >
        <Stack>
          <pre style={{ fontFamily: ["Roboto", "Helvetica", "Arial"] }}>                          Search results are limited in demo version.                      </pre>

          <Button
            size="large"
            sx={{ color: '#FFF', }}
            href="https://webforms.pipedrive.com/f/5X4yAVMERh0y8mGszS6ntpwcPhTToxMyqwqNXhrZXfn7xl0R187lTPeBzlp0B4jxCP"
          >Click here to request upgrade</Button>

        </Stack>
      </CornerRibbon>
    </div>
  );
})


export default () => {
  const defaultView = localStorage.getItem('transHunterView');
  const [view, setView] = useState(defaultView || 'Availability Alerts');
  const [loaded, setLoaded] = useState({});
  const groups = useContext(UserContext);

  const proUser = groups.includes(userGroups.transhunterpro) || groups.includes(userGroups.admins);

  useEffect(() => {
    if (view && !loaded[view]) {
      setLoaded({
        ...loaded,
        [view]: true
      })
    }
  }, [view])

  const handleSelection = (viewName) => {
    localStorage.setItem('transHunterView', viewName);
    setView(viewName);
  };

  const transAlertsViews = ['Availability Alerts', 'Long Term Availability', 'All Daily Segments', 'Flowgates', 'Trans Offerings', 'Summary Statistics', 'Outages',];

  return (
    <Box sx={{ display: 'flex' }}>
      {!proUser && <Fade timeout={3000}><MyRibbon /></Fade>}
      <TransHunterAppMenu handleSelection={handleSelection} selected={view} />
      <Box
        component="main"
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        {loaded['audit'] && <GroupGuard groups={[userGroups.transhunter]}>
          <Collapse in={view === 'audit'} key={'audit_collapse'}>
            <TransHunterAuditGrid />
          </Collapse>
        </GroupGuard>}
        {transAlertsViews.find(v => loaded[v]) && <Collapse in={transAlertsViews.includes(view)} key={'transalerts_collapse'}>
          <TransAlerts view={view} />
        </Collapse>}
        {loaded['transhunterhelp'] && <Collapse in={view === 'transhunterhelp'} key={'transhunterhelp_collapse'}>
          <Help appName={'TransHunter'} />
        </Collapse>}
        {loaded['tsractivity'] && <Collapse in={view === 'tsractivity'} key={'tsractivity_collapse'}>
          <TSRActivity visible={view === 'tsractivity'} application='transhunter' />
        </Collapse>}
        {loaded['pathmonitor'] && <Collapse in={view === 'pathmonitor'} key={'pathmonitor_collapse'}>
          <PathMonitor />
        </Collapse>}
        {loaded['transactionqueue'] && <Collapse in={view === 'transactionqueue'} key={'transactionqueue_collapse'}>
          <CriteriaProvider>
            <PathMonitor application={'transhunter'} />
          </CriteriaProvider>
        </Collapse>}
      </Box>
    </Box>
  );
}