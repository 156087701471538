import { TransDealSummaryView } from "./TransDealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { Dashboard } from "../useDashboard";

export const TransDealSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'trans-deal-summary';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={TransDealSummaryView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}