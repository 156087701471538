import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Box, IconButton, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DealHistoryGrid } from "./DealHistoryGrid";

export const DealHistoryDialog = ({ open, onClose, dealId, }) => {
    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth
            maxWidth='xl'
        >
            <DialogTitle sx={{ py: 0.25 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Deal History for {dealId}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={onClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '75vh', py: 0, px: 1, }}>
                <DealHistoryGrid
                    dealId={dealId}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                <Box sx={{ flexGrow: 1, }} />
            </DialogActions>
        </Dialog>
    );
};
