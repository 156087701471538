import { Stack } from "@mui/material"
import { RibbonButton, RibbonGroupContainer } from "../../Ribbon"
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { AddTsrDialog } from '../AddTsrDialog/AddTsrDialog';
import { useState } from "react";
import { apiUrlPrefix } from "../../../../authConfig";
import { useApi } from "../../../useApi";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

export const TSRActionsRibbonGroup = ({ expanded, selectedTags, }) => {
    const selectedTag = selectedTags?.[0];
    const [openAddTsrDialog, setOpenAddTsrDialog] = useState(false);
    const { get, post, headers, enqueueSnackbar, logAction } = useApi();
    const { watch, } = useFormContext();
    const timezone = watch('timezone');
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');

    async function handleSaveTsrs(productRef, data) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=dealrizz.UI_TagReport_AddTransmissionToDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedTag.tagIdx}`
            + `&parm=${productRef.id}`
            + `&parm=${timezone}`
            + `&parm=${selectedTag.tagDate ?? ''}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`

        return post(url, data).then(response => {
            enqueueSnackbar('Transmission added successfully.', { variant: 'success' });
            logAction(`User added transmission to tag ${selectedTag.tagIdx}`, 'Tag Report', data);
            return response;
        });
    }

    async function addTsrs(productRef, data) {
        setOpenAddTsrDialog(false);
        return handleSaveTsrs(productRef, data);
    }

    return (
        <RibbonGroupContainer>

            <AddTsrDialog
                open={openAddTsrDialog}
                handleSaveAndClose={addTsrs}
                handleCancel={() => setOpenAddTsrDialog(false)}
                selectedTag={selectedTag}
            />

            <RibbonButton
                label="Add TSR"
                expanded={expanded}
                onMouseDown={() => setOpenAddTsrDialog(true)}
                icon={<MergeTypeIcon
                    fontSize='small'
                />}
            />

        </RibbonGroupContainer>
    )
}