import { useState, useCallback, } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import debounce from 'lodash/debounce';
import { useApi } from '../../useApi';
import { RibbonButton } from '../Ribbon';
import { Tooltip } from '@mui/material';

export const VoidDealButton = ({ deal, disabled, expanded, gridRef, ...buttonProps }) => {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false });
    const { apiUrlPrefix, headers, get, enqueueSnackbar, logAction, } = useApi();
    const buttonDebounce = 1500;

    const toVoid = deal?.Status !== 'VOIDED';
    const voidButtonText = toVoid ? 'Void' : 'Unvoid';

    function handleConfirmVoid(toVoid) {
        const action = toVoid ? 'void' : 'unvoid';
        setConfirmationDialogProps({
            open: true,
            message: `You are about to ${action} the selected deal (${deal.dealID}). Continue?`,
            onConfirmation: () => handleVoid(deal),
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleVoid = useCallback(debounce(async (dealData) => {
        setConfirmationDialogProps({ open: false });
        const toVoid = dealData.Status === 'VOIDED' ? 0 : 1;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_voidDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealData.dealID}`
            + `&parm=${toVoid}`

        return get(url).then(response => {
            enqueueSnackbar(`Deal ${toVoid ? '' : 'un'}voided successfully.`, { variant: 'success' });
            logAction(`User ${toVoid ? '' : 'un'}voided deal with id ${dealData.dealID}`, 'Deal Summary');
            gridRef?.current.api?.applyTransaction({ update: [{ ...dealData, Status: toVoid ? 'VOIDED' : 'DRAFT' }] });
            gridRef?.current.api?.deselectAll();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix]);

    return (
        <>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={toVoid ? 'Void the selected deal.' : 'Unvoid the selected deal.'}>
                <span>
                    <RibbonButton
                        onMouseDown={() => handleConfirmVoid(toVoid)}
                        label={voidButtonText}
                        icon={<HighlightOffIcon fontSize='small' />}
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    );
}