import { apiUrlPrefix, } from '../authConfig.js';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import useHeader from './useHeader';

export const useActionAudit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();

  async function logAction(action, userInterface, data = {}) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UseabilityAuditInsert`
      + `&parm=${headers.userGuid ?? 'NULL'}&parm=${action}&parm=${userInterface}`

    const options = {
      method: 'POST',
      headers: headers,
      data: data,
      url: url,
    }

    return axios(options).catch(error => { enqueueSnackbar(`${error}`) });
  }

  return { logAction };
}