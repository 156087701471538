import { Dashboard } from "../useDashboard";
import { DealSummaryView } from "./DealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const DealSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'deal-summary';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={DealSummaryView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}