import { useCallback } from "react";

export const useLayoutFunctions = () => {
    const fields = ['field', 'width', 'hide', 'sort', 'order', 'pinned', 'headerName', 'sortIndex', 'rowGroup', 'rowGroupIndex', 'pivot', 'pivotIndex', 'aggFunc'];

    const captureDefLayout = useCallback((def) => {
        const defLayout = {};
        fields.forEach(field => {
            defLayout[field] = def[field];
        });
        return defLayout;
    }, []);

    const captureLayout = useCallback((gridRef) => {
        const defs = gridRef.current.api.getColumnDefs();
        const state = defs.reduce((acc, def, i) => {
            const defLayout = captureDefLayout(def);
            defLayout.order = i;
            defLayout.hide = def.hide ? def.hide : false; //set undefined value to false so it displays properly after saving/loading
            acc.push(defLayout);

            if (def.children) {
                def.children.forEach((childDef) => {
                    const childDefLayout = captureDefLayout(childDef);
                    childDefLayout.hide = childDef.hide ? childDef.hide : false;
                    acc.push(childDefLayout);
                });
            }

            return acc;
        }, []);
        const filters = gridRef.current.api.getFilterModel();

        return {
            filters: filters,
            state: state,
        }
    }, [captureDefLayout]);

    const applyLayout = useCallback((layout, baseColDefs, defaultDef) => {
        const defs = baseColDefs.map(def => {
            const newDef = { ...defaultDef, ...def };
            const defLayout = layout?.state?.find(lyt => {
                return def.field ? lyt.field === def.field : lyt.headerName === def.headerName; //match on headerName if field is not defined
            });
            if (defLayout) {
                fields.forEach(field => {
                    if (!['field', 'headerName'].includes(field)) {
                        newDef[field] = defLayout[field];
                    }
                });

                if (def.children) {
                    newDef.children = def.children.map(childDef => {
                        const childDefLayout = layout?.state?.find(lyt => {
                            return childDef.field ? lyt.field === childDef.field : lyt.headerName === childDef.headerName;
                        });
                        if (childDefLayout) {
                            fields.forEach(field => {
                                if (!['field', 'headerName'].includes(field)) {
                                    childDef[field] = childDefLayout[field];
                                }
                            });
                        }
                        return childDef;
                    });
                }
            }
            return newDef;
        });

        const widthAdjustedDefs = defs.map(def => ({ //remove flex property if width is set to persist the users width setting
            ...def,
            flex: def.width ? undefined : def.flex,
        }));
        const sortedDefs = widthAdjustedDefs.sort((def1, def2) => def1.order - def2.order);

        return sortedDefs;
    }, []);

    return {
        captureLayout,
        captureDefLayout,
        applyLayout,
    };
};