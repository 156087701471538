import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";

export default ({ name, control, ...inputProps }) => {
  const defaultValue = inputProps?.multiple ? [] : null;

  return (
    <Controller
      render={({ field }) =>
        <Autocomplete
          autoComplete
          autoSelect
          {...field}
          value={field.value ?? defaultValue}
          onChange={(_, data) => field.onChange(data)}
          {...inputProps}
        />
      }
      name={name}
      control={control}
    />
  )
}