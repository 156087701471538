import { PowerPeople, TransHunter, UserGroupsView, CrystalBall, AboutPowerStation, SpyMonkey, Dashboard, Transnow, TSRActivity, BulkOriginalRequestsView, Bidman } from "./views";
import Settings from './components/Settings/Settings.js'
import { userGroups } from "./authConfig.js";
import { createBrowserRouter, } from "react-router-dom";
import { RouteGuard } from "./components";
import { UserContext } from "./data/fetchUserGroups";
import React from "react";
import PowerstationInfo from "./components/PowerstationInfo";
import FastPathPro from "./views/FastPathPro";
import PowerFolio from "./views/PowerFolio";
import Marketplace from "./components/Marketplace/Marketplace";
import { DefaultMeta, FastPathMeta, TransHunterMeta, TransNowMeta } from "./components/SEO";
import { DealRizz, DealRizzViews } from "./components/DealRizz/DealRizz";
import { ProductUpdates } from "./components/ProductUpdates/ProductUpdates.js";
import { Main } from "./components/Main";
import { TariffAF } from "./components/TariffAF/TariffAF";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { FastPathMap } from "./components/FastPathMap";
import Brain, { BrainViews } from "./components/Brain/Brain";
// import AIsupport from "./components/PowerPeople/AIsupport";
import { routePaths } from "./routePaths";

//an empty groups array indicates that no groups are required to access the route.
//users are added to the default group on login.
//if the groups array is non empty, a user must belong to one of the listed groups.
export const routeConfigs = {

  fastpath: {
    component: <FastPathPro />,
    path: routePaths.fastpath,
    groups: [],
    name: 'FastPath',
    meta: <FastPathMeta />,
  },
  fastpathmap: {
    component: <FastPathMap />,
    path: routePaths.fastpathmap,
    groups: [],
    name: 'Fast Path Map',
  },
  crystalball: {
    component: <CrystalBall />,
    path: routePaths.crystalball,
    groups: [userGroups.crystalball,],
    name: 'Crystal Ball',
  },
  aboutpowerstation: {
    component: <AboutPowerStation />,
    path: routePaths.aboutpowerstation,
    groups: [],
    name: 'About Power Station',
  },
  spymonkey: {
    component: <SpyMonkey />,
    path: routePaths.spymonkey,
    groups: [userGroups.spymonkey,],
    name: 'Spy Monkey',
  },
  dashboard: {
    component: <Dashboard />,
    path: routePaths.dashboard,
    groups: [userGroups.dashboard,],
    name: 'Dashboard',
  },
  bidman: {
    component: <Bidman />,
    path: routePaths.bidman,
    groups: [userGroups.bidman, userGroups.engie,],
    name: 'Bidman',
  },
  transnow: {
    component: <Transnow />,
    path: routePaths.transnow,
    groups: [],
    name: 'Transnow',
    meta: <TransNowMeta />,
  },
  transhunter: {
    component: <TransHunter />,
    path: routePaths.transhunter,
    groups: [],
    name: 'TransHunter',
    meta: <TransHunterMeta />,
  },
  settings: {
    component: <Settings />,
    path: routePaths.settings,
    groups: [userGroups.default],
    name: 'Settings',
  },
  tsractivity: {
    component: <TSRActivity visible={true} application="tsractivity" />,
    path: routePaths.tsractivity,
    groups: [userGroups.tsractivity],
    name: 'TSR Activity',
  },
  bulkoriginalrequestsview: {
    component: <BulkOriginalRequestsView />,
    path: routePaths.bulkoriginalrequestsview,
    groups: [userGroups.bulkoriginalrequestsview],
    name: 'Bulk Original Requests',
  },
  userGroupsview: {
    component: <UserGroupsView />,
    path: routePaths.usergroupsview,
    groups: [userGroups.usergroupsview,],
    name: 'User Groups'
  },
  powerpeople: {
    component: <PowerPeople />,
    path: routePaths.powerpeople,
    groups: [userGroups.support, userGroups.requirements, userGroups.helpcomposer,],
    name: 'Power People'
  },
  marketplace: {
    component: <Marketplace />,
    path: routePaths.marketplace,
    groups: [userGroups.admins],
    name: 'Marketplace',
  },
  productupdates: {
    component: <ProductUpdates />,
    path: routePaths.productupdates,
    groups: [],
    name: 'Product Updates',
  },
  tariffaf: {
    component: <TariffAF />,
    path: routePaths.tariffaf,
    groups: [userGroups.tariffaf],
    name: 'TariffAF',
  },
};

export function IsAuthorized(config) {
  const groups = React.useContext(UserContext);
  const intersection = config.groups.filter(grp => groups.includes(grp));
  return (intersection.length > 0 || config.groups.length === 0);
}

const Guard = (config) => (
  <RouteGuard groups={config.groups}>
    {config.component}
  </RouteGuard>
);

export const router = createBrowserRouter([
  {
    element: <Main />,
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <>
          <DefaultMeta robotsContent="index, follow" />
          <AboutPowerStation />
        </>,
      },
      {
        path: "aboutpowerstation/:id",
        element: <PowerstationInfo />,
      },
      {
        path: "aboutpowerstation",
        element: <AboutPowerStation />,
      },
      ...Object.keys(routeConfigs).map(cfg => ({
        path: routeConfigs[cfg].path,
        element: Guard(routeConfigs[cfg]),
      })),
      {
        path: routePaths.dealrizz,
        element: <DealRizz />,
        children: [{
          path: ':view/:id?',
          element: <DealRizzViews />,
        }],
      },
      {
        path: routePaths.brain,
        element: <Brain />,
        children: [{
          path: ':view/:id?',
          element: <BrainViews />,
        }],
      }
    ],
  },
]);