import { useApi } from "../useApi";
import useGridLayout from "../useGridLayout";
import { useRef, useCallback, } from "react";
import axios from "axios";
import { diffData } from "./Utils";

export const useDashboardFetch = ({ fetchProcName, fetchParamKeys, gridRef, getRowId, dataFormatter, }) => {
    const { headers, apiUrlPrefix, handleErrorResponse } = useApi();
    const abortControllerRef = useRef(new AbortController());

    function cancelPreviousFetch() {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    const loadData = useCallback(async (data) => {
        gridRef.current?.api?.showLoadingOverlay();
        gridRef.current?.api?.deselectAll();
        return fetchData(data);
    }, [headers]);

    const fetchData = useCallback(async (data) => {
        const formattedData = dataFormatter ? dataFormatter(data) : data;
        cancelPreviousFetch();

        const paramString = fetchParamKeys.reduce((acc, key) => {
            return `${acc}&parm=${formattedData[key] ?? ''}`;
        }, '');
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=${fetchProcName}${paramString}`

        return axios.get(url, { headers, signal: abortControllerRef.current.signal }).catch(err => handleErrorResponse(err, url));
    }, [headers]);

    const applyData = useCallback((response) => {
        if (response?.data) {
            const newData = response?.data ?? [];

            //clear old data
            const oldData = [];
            gridRef.current.api.forEachLeafNode(node => {
                oldData.push(node.data);
            });

            const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, (data) => getRowId({ data, }));
            gridRef.current.api.applyTransaction({ add: toAdd, update: toUpdate, remove: toDelete });
        }
        return response;
    }, [getRowId]);

    const loadAndApplyData = useCallback(async (data) => {
        return loadData(data).then(applyData);
    }, [loadData, applyData]);

    const silentUpdate = useCallback(async (data) => {
        return fetchData(data).then(applyData);
    }, [fetchData, applyData]);

    return {
        loadData,
        fetchData,
        loadAndApplyData,
        applyData,
        silentUpdate,
    }
}