import { Tooltip, FormControlLabel, TextField, FormGroup, Grid, Stack, FormHelperText, } from "@mui/material"
import { useEffect, } from "react";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormCheckbox from "../../FormControls/FormCheckbox";

export const AddNewOrUpdateForm = (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, allViewNames = [], formKey, } = props;

    const schema = yup.object().shape({
        label: yup.string().required('Label is required.').test(
            'is-unique',
            'View label already exists. Please choose a unique label.',
            value => !allViewNames.includes(value)
        ).max(50, 'Label must be less than 50 characters.'),
        timezone: yup.string().required('Timezone is required'),
        showMWColumns: yup.bool().when('showPriceColumns', {
            is: (showPriceColumns) => !showPriceColumns,
            then: () => yup.bool().required('At least one column type must be selected.').oneOf([true], 'At least one column type must be selected.'),
        }),
    });

    const { handleSubmit, formState: { errors }, control, reset, register, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    const showMWColumns = watch('showMWColumns');
    const showPriceColumns = watch('showPriceColumns');

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    function onSubmit(data) {
        handleAddNew(data);
    }

    return (
        <form id={formKey} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} alignItems='center' sx={{ p: 1, }}>
                <Grid item xs={12}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        fullWidth
                        options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                        getOptionLabel={(option) => option.split(' ')[0]}
                        control={control}
                        name='timezone'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Timezone"
                                size='small'
                                color="success"
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                                sx={{ minWidth: 150, }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={8} sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                    <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FormGroup
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            error={!!errors.showMWColumns}
                        >
                            <FormControlLabel
                                label="Default MW Columns: "
                                labelPlacement="start"
                                control={<FormCheckbox
                                    name={'showMWColumns'}
                                    disabled={!showPriceColumns && !!showMWColumns}
                                    control={control}
                                />}
                            />
                        </FormGroup>

                        <FormGroup
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            error={!!errors.showMWColumns}
                        >
                            <FormControlLabel
                                label="Default Price Columns: "
                                labelPlacement="start"
                                control={<FormCheckbox
                                    disabled={!showMWColumns && !!showPriceColumns}
                                    name={'showPriceColumns'}
                                    control={control}
                                />}
                            />
                        </FormGroup>
                    </Stack>
                    <FormHelperText error={!!errors.showMWColumns}>At least one column type must be selected.</FormHelperText>
                </Grid>

                {viewToUpdate?.id && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            label="Share View: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'shared'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>}
            </Grid>
        </form>
    );
}