import { Dashboard } from "../useDashboard";
import { UnscheduledMWsView } from "./UnscheduledMWsView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const UnscheduledMWsDashboard = ({ visible, }) => {
    const dashboardKey = 'unscheduled-mws';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={UnscheduledMWsView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}