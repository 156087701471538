import { Tooltip, FormControlLabel, TextField, FormGroup, Grid, } from "@mui/material"
import { useEffect, useState } from "react";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormCheckbox from "../../FormControls/FormCheckbox";
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import { timeTypes, conversionOptions, } from "../../../constants";
import { dealStatuses } from "../constants";

export const AddNewOrUpdateForm = (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, allViewNames = [], } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [bookOptions, setBookOptions] = useState([]);
    const headers = useHeader();

    const schema = yup.object().shape({
        label: yup.string().required('Label is required.').test(
            'is-unique',
            'View label already exists. Please choose a unique label.',
            value => !allViewNames.includes(value)
        ).max(50, 'Label must be less than 50 characters.'),
        timezone: yup.string().required('Timezone is required'),
        timeType: yup.string().required('Time Type is required'),
        // convertTo: yup.string().required('Convert To is required'),
        // dealStatus: yup.string().required('Deal Status is required'),
    });

    const { handleSubmit, formState: { errors }, control, reset, getValues, setValue, register } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    function onSubmit(data) {
        handleAddNew(data);
    }

    // useEffect(() => {
    //     fetchBookOptions();
    // }, []);

    // async function fetchBookOptions() {
    //     const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchBookName`

    //     return axios.get(url, { headers: headers }).then(response => {
    //         const newData = response?.data?.map(obj => obj.book) ?? [];
    //         setBookOptions(newData);
    //     }).catch((error) => {
    //         enqueueSnackbar(error.message, { variant: 'error' });
    //     })
    // }

    return (
        <form id='deal-rizz-deal-summary-add-or-update-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} alignItems='center' sx={{ p: 1, }}>
                <Grid item xs={12}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                        getOptionLabel={(option) => option.split(' ')[0]}
                        control={control}
                        name='timezone'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Timezone"
                                size='small'
                                color="success"
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                {/* <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={timeTypes}
                        getOptionLabel={(option) => {
                            return option.charAt(0).toUpperCase() + option.slice(1);
                        }}
                        control={control}
                        name='timeType'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Time Type"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid> */}

                {/* <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={bookOptions}
                        control={control}
                        name='book'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Book"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid> */}

                {/* <Grid item xs={3}>
                    <FormAutocomplete
                        autoHighlight
                        options={conversionOptions}
                        control={control}
                        name='convertTo'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Convert To"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid> */}

                {/* <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={dealStatuses}
                        control={control}
                        name='dealStatus'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Deal Status"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid> */}

                {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            label="Show Internal Deals:"
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'showInternalDeals'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid> */}

                {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            label="Show Zero Megawatt Deals:"
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'showZeroMegawattDeals'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid> */}

                {viewToUpdate?.id && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            label="Share View: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'shared'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>}
            </Grid>
        </form>
    );
}