import { Grid, TextField, MenuItem, } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState, } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array, } from 'yup';
import FormAutocomplete from '../../FormControls/FormAutocomplete';
import useHeader from '../../useHeader';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix } from '../../../authConfig';

export default (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, } = props;
    const [locationOptions, setLocationOptions] = useState([]);
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fetchLocations();
    }, []);

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    const schema = object().shape({
        label: string().required('Label is required.').max(50, 'Label must be less than 50 characters.'),
        timezone: string().required('Please select a timezone.'),
        locations: array().min(1, 'Please select at least one location.'),
    });

    const { register, control, handleSubmit, formState: { errors }, reset, } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    function onSubmit(data) {
        handleAddNew(data);
    }

    function fetchLocations() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchCurrentLMPLocations`;

        const options = {
            headers: headers,
            url: url,
        }

        axios(options).then(response => {
            setLocationOptions(response.data.map(obj => obj.Location));
        }).catch(error => {
            enqueueSnackbar(`Error fetching LMP Locations. Editing LMP views may be impossible. ${error}`);
        });

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id='add-new-lmp-view-form'>
            <Grid container spacing={2} alignItems='center' sx={{ p: 1, }}>
                <Grid item xs={8}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <FormAutocomplete
                        name={'timezone'}
                        control={control}
                        fullWidth
                        getOptionLabel={(option) => option.split(' ')[0]}
                        options={['Pacific Standard Time', 'Mountain Standard Time', 'Central Standard Time', 'Eastern Standard Time']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label="Timezone"
                                color="success"
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormAutocomplete
                        name={'locations'}
                        control={control}
                        fullWidth
                        multiple
                        options={locationOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label="Locations"
                                color="success"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </form>
    )
}