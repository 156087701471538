import { useState } from 'react';
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import DirectionsIcon from '@mui/icons-material/Directions';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tooltip from '@mui/material/Tooltip';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import GroupGuard from '../Guards/GroupGuard.js';
import { userGroups } from '../../authConfig.js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import CornerRibbon from 'react-corner-ribbon';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      overflowX: 'hidden',
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export default function TransNowAppMenu({ handleSelection, selected }) {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <List>
        <GroupGuard authenticatedOnly>
          <Tooltip title="View information about TransNow." arrow placement="right">
            <ListItemButton
              selected={selected === 'transnowhelp'}
              onClick={() => handleSelection('transnowhelp')}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="TransNow Help" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard authenticatedOnly>
          <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
            <ListItemButton
              selected={selected === 'tsractivity'}
              onClick={() => handleSelection('tsractivity')}>
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="TSR Detail" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <Divider textAlign='left'>{open ? 'Day Ahead' : ''}</Divider>
        {/*<ListItemButton> //ecz 10/7 label is part of the divider above, don't need these functionless buttons
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Day Ahead" />
        </ListItemButton>*/}
        {/* <Tooltip title="Submit an original request for Network Transmission to OASIS." arrow placement="right">
          <ListItemButton
            selected={selected === 'originalrequests'}
            onClick={() => handleSelection('originalrequests')}>
            <ListItemIcon>
              <PodcastsIcon />
            </ListItemIcon> 
            <ListItemText primary="Network Trans" />
          </ListItemButton>
        </Tooltip> */}
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title="Submit an orignal request for a new TSR." arrow placement="right">
              <ListItemButton
                selected={selected === 'originalrequests'}
                onClick={() => handleSelection('originalrequests')}>
                <ListItemIcon>
                  <RequestPageIcon />
                </ListItemIcon>
                <ListItemText primary="Original Reqs" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <div style={{ position: 'relative' }}>
            {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
            <Tooltip title="Day ahead trading interface." arrow placement="right">
              <ListItemButton
                selected={selected === 'dayahead'}
                onClick={() => handleSelection('dayahead')}>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="Resale/Redirects" />
              </ListItemButton>
            </Tooltip>
          </div>
        </GroupGuard>
        <Tooltip title="Day ahead interface for redirecting transmission." arrow placement="right">
          <ListItemButton
            selected={selected === 'dayaheadredirects'}
            onClick={() => handleSelection('dayaheadredirects')}>
            <ListItemIcon>
              <DirectionsIcon />
            </ListItemIcon>
            <ListItemText primary="Redirects" />
          </ListItemButton>
        </Tooltip>
        <Divider textAlign='left'>{open ? 'Real Time' : ''}</Divider>
        {/*<ListItemButton>  //ecz 10/7 label is part of the divider above, don't need these functionless buttons
          <ListItemIcon>
            <QueryBuilderIcon />
          </ListItemIcon>
          <ListItemText primary="Real Time" />
      </ListItemButton>*/}
        <Tooltip title="Real time original requests made simple." arrow placement="right">
          <ListItemButton
            selected={selected === 'realtimeoriginalrequests'}
            onClick={() => handleSelection('realtimeoriginalrequests')}>
            <ListItemIcon>
              <AddAlarmIcon />
            </ListItemIcon>
            <ListItemText primary="Original Reqs" />
          </ListItemButton>
        </Tooltip>
        {/* <Tooltip title="Bulk submit deirects as new TSRs." arrow placement="right">
          <ListItemButton
            selected={selected === 'redirects'}
            onClick={() => handleSelection('redirects')}>
            <ListItemIcon>
              <DirectionsIcon />
            </ListItemIcon> 
            <ListItemText primary="Redirects" />
          </ListItemButton>
        </Tooltip> */}
        <Tooltip title="Get in to some real time trading action." arrow placement="right">
          <ListItemButton
            selected={selected === 'realtime'}
            onClick={() => handleSelection('realtime')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Resale/Redirect" />
          </ListItemButton>
        </Tooltip>
        <GroupGuard groups={[userGroups.ppwtrans]}>
          <Tooltip title="Reserve bundled PPW trans and receive discounted 2nd and 3rd legs...." arrow placement="right">
            <ListItemButton
              selected={selected === 'ppwtrans'}
              onClick={() => handleSelection('ppwtrans')}>
              <ListItemIcon>
                <MultipleStopIcon />
              </ListItemIcon>
              <ListItemText primary="PPW Trans" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
      </List>
      <Divider />
      <List>
        <GroupGuard groups={[userGroups.dayaheadredirects]}>
          <Tooltip title="View and edit your transaction alerts." arrow placement="right">
            <ListItemButton
              selected={selected === 'transactionqueue'}
              onClick={() => handleSelection('transactionqueue')}>
              <ListItemIcon>
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Trans Q" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.transnowauditgrid]}>
          <Tooltip title="See the audit log for all the OASIS transactions processed by your organization." arrow placement="right">
            <ListItemButton
              selected={selected === 'audit'}
              onClick={() => handleSelection('audit')}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Transaction Log" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
          <ListItemButton
            selected={selected === 'tariffprices'}
            onClick={() => handleSelection('tariffprices')}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Tariff Prices" />
          </ListItemButton>
        </Tooltip>



      </List>
    </Drawer>
  );
}