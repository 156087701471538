import { SEO } from './SEO.js';
import CssBaseline from "@mui/material/CssBaseline";
import Header from './Header.js';
import { Outlet } from 'react-router-dom';

export const Main = () => {
  return (
    <main>
      <SEO />
      <CssBaseline />
      <Header />
      <Outlet />
    </main>
  )
}