import { Tooltip, FormControlLabel, TextField, FormGroup, Grid, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import { useEffect, useCallback, useState } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormDatePicker from "../../FormControls/FormDatePicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { debounce } from "lodash";
import FormCheckbox from "../../FormControls/FormCheckbox";
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import { timeTypes } from "../../../constants";

export const AddNewOrUpdateForm = (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, allViewNames = [], } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [bookOptions, setBookOptions] = useState([]);
    const headers = useHeader();

    const schema = yup.object().shape({
        label: yup.string().required('Label is required.').test(
            'is-unique',
            'View label already exists. Please choose a unique label.',
            value => !allViewNames.includes(value)
        ).max(50, 'Label must be less than 50 characters.'),
        timezone: yup.string().required('Timezone is required'),
        timeType: yup.string().required('Time Type is required'),
    });

    const { handleSubmit, formState: { errors }, control, reset, getValues, setValue, register } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    function onSubmit(data) {
        handleAddNew(data);
    }

    useEffect(() => {
        fetchBookOptions();
    }, []);

    async function fetchBookOptions() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchBookName`

        return axios.get(url, { headers: headers }).then(response => {
            const newData = response?.data?.map(obj => obj.book) ?? [];
            const uniqueBooks = [...new Set(newData.reduce((acc, book) => {
                //this is a bit of a mess, but the values can be in a few different formats
                //values may be comma separated lists, like 'MyBook,MyOtherBook'
                //they may also be stringified arrays, like'[{"label":"MyBook","percentage":"100"}]' 
                //try to parse, if it fails, split
                try {
                    const parsed = JSON.parse(book);
                    parsed.forEach((item) => {
                        acc.push(item.label);
                    });
                } catch (e) {
                    const books = book?.split(',');
                    acc.push(...books);
                }
                return acc;
            }, []))];
            setBookOptions(uniqueBooks);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        })
    }

    return (
        <form id='deal-rizz-transmission-position-add-or-update-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} alignItems='center' sx={{ p: 1, }}>
                <Grid item xs={12}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>

                {/* <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={timeTypes}
                        getOptionLabel={(option) => {
                            return option.charAt(0).toUpperCase() + option.slice(1);
                        }}
                        control={control}
                        name='timeType'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Time Type"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid> */}

                <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        options={bookOptions}
                        control={control}
                        name='book'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Book"
                                size='small'
                                color="success"
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormAutocomplete
                        autoHighlight
                        fullWidth
                        options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                        getOptionLabel={(option) => option.split(' ')[0]}
                        control={control}
                        name='timezone'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Timezone"
                                size='small'
                                color="success"
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                                sx={{ minWidth: 150, }}
                            />
                        )}
                    />
                </Grid>

                {viewToUpdate?.id && <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            label="Share View: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'shared'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>}
            </Grid>
        </form>
    );
}