import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import {userGroups} from '../authConfig.js';
import GroupGuard from './Guards/GroupGuard.js';
import { SignOnDialog } from './SignOnDialog';
import { useState } from 'react';

function SettingsMenu() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [openLogin, setOpenLogin] = useState(false);

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const handleLogin = () => {
    setOpenLogin(true);
  };

  function SSO () {
    isAuthenticated
    ? handleLogout()
    : handleLogin()
  }

  const loginText = isAuthenticated ? 'Sign Out' : 'Sign In';
  //const SignInSignOut = isAuthenticated ? handleLogout : handleLogin;

  return (
    <>
      <SignOnDialog 
        open={openLogin} 
        onClose={() => setOpenLogin(false)}
      />
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>
          <GroupGuard groups={[userGroups.default, userGroups.admins]}>
            <MenuItem onClick={handleSettings}>
              <ListItemIcon>
                <SettingsIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          </GroupGuard>
          <MenuItem onClick={SSO}>
            <ListItemIcon>
              <LoginIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>{loginText}</ListItemText>
          </MenuItem>
          {/*<MenuItem onClick={SSO}>
            <ListItemIcon>
              <LoginIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>SSO</ListItemText>
  </MenuItem>*/}
          {/*<GroupGuard groups={[userGroups.admins, userGroups.default, userGroups.tea]}>
              <Popup trigger={
              <MenuItem >
                <ListItemIcon>
                  <KeyboardIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Hot Keys</ListItemText>
            </MenuItem>
            } position="left center" closeOnDocumentClick on={['hover', 'focus']} nested>
              <DisplayHotKeys />
            </Popup>
          </GroupGuard>*/}
        </MenuList>
      </Paper>
    </>
  );
}

export default SettingsMenu;