import { IconButton, Divider, Grid, InputLabel, TextField, Autocomplete, Button, Table, TableHead, TableRow, TableCell, TableBody, Collapse } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../../authConfig";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { useData } from "../useData";
import { useEffect, useState } from "react";
import { apiUrlPrefix } from "../../authConfig";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useUserInfo } from "../useUserInfo";

export default function Profile() {
  const headers = useHeader();
  const { instance, } = useMsal();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const dataUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsFetchForUser&parm=${guid}`;
  const { rowData, getData } = useData([]);
  const [userInfo, setUserInfo] = useState({});
  const [showSupportRequestTable, setShowSupportRequestTable] = useState(false);
  const defaultUserInfo = useUserInfo();

  useEffect(() => {
    getData(dataUri);
  }, []);

  useEffect(() => {
    setUserInfo(defaultUserInfo);
  }, [defaultUserInfo]);

  const {
    firstName,
    lastName,
    userName,
    phone,
    carrier,
  } = userInfo;

  function handleChange(key, value) {
    setUserInfo({
      ...userInfo,
      [key]: value,
    })
  }

  const name = () => {
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else return '';
  }

  function handleProfileEdit() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_saveUserPhoneCarrier&parm=${guid}&parm=${phone}&parm=${encodeURIComponent(carrier)}`,
    }

    axios(options).then(response => {
      enqueueSnackbar('User info successfully updated.')
    }).catch(error => {
      enqueueSnackbar(`Error fetching user info. ${error.message}`);
    })
  }

  async function handlePasswordReset() {
    await instance.handleRedirectPromise();
    instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
  }

  function toggleSupportRequestGrid() {
    setShowSupportRequestTable(!showSupportRequestTable);
  }

  return (<>
    <Grid container style={{ padding: "30px" }} justifyContent="center">
      <Grid
        container
        item
        spacing={1}
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item xs={1}>
          <InputLabel>Name</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled variant="outlined" size="small" value={name()} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item xs={1}>
          <InputLabel>Username</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled variant="outlined" size="small" value={userName ?? ''} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item xs={1}>
          <InputLabel>User GUID</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled variant="outlined" size="small" value={guid ?? ''} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item xs={1}>
          <InputLabel>Phone Number</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            onChange={(e) => handleChange('phone', e.target.value)}
            size="small"
            value={phone ?? ''}
          />
        </Grid>
        {/*<Grid item xs={1}>
          <Autocomplete
            fullWidth
            options={['Verizon', 'AT&T', 'Virgin', 'T-Mobile']}
            onChange={(_, newValue) => handleChange('carrier', newValue)}
            value={carrier ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Carrier"
                color="success"
                placeholder="Carrier"
              />
            )}
          />
        </Grid>*/}
      </Grid>
      <Grid
        container
        item
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item xs={2}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleProfileEdit}
          >Save Changes</Button>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid container item style={{ margin: '10px', marginTop: '20px' }} alignItems='center'>
        <Grid item xs={2}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePasswordReset}
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid container style={{ padding: "10px" }} justifyContent="flex-start">
      <Grid
        container
        spacing={1}
        style={{ margin: "10px" }}
        alignItems="center"
      >
        <Grid item>
          <InputLabel>User Submitted Support Requests</InputLabel>
        </Grid>
        <Grid item>
          <IconButton onClick={toggleSupportRequestGrid}>
            {showSupportRequestTable ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={8}
        style={{ margin: "10px", display: "flex", justifyContent: "flex-start" }}
      >
        <Collapse in={showSupportRequestTable}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Resolution</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map(row => (
                <TableRow key={row.supportRequestID}>
                  <TableCell>{row.supportRequestID}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{row.resolution}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </Grid>
    </Grid>
  </>
  );
}