import { Button, Grid, TextField, InputAdornment, Tooltip, ListItem, ListItemText } from '@mui/material';
import FormDateTimePicker from "../../../FormControls/FormDateTimePicker";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import FormAutocomplete from '../../../FormControls/FormAutocomplete';
import { renderDayjsTimezones } from '../../../../utils/renderTimezones';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProfileTemplates } from './useProfileTemplates_v2';

export const ProfileCreationForm = ({ disabled, setProfile, }) => {
    const { register, formState: { errors, }, getValues, setValue, control, watch } = useFormContext();
    const { profileOptions, } = useProfileTemplates();
    const indexType = watch('indexType');
    const dynamic = watch('dynamic');
    const term = watch('Term');

    function handleDateUpdate(key, value) {
        //make sure start date is before stop date
        const start = getValues('startDateTime');
        const stop = getValues('stopDateTime');
        if ((key === 'startDateTime' && dayjs(stop).isBefore(value)) || (key === 'stopDateTime' && dayjs(start).isAfter(value))) {
            setValue('startDateTime', value);
            setValue('stopDateTime', value);
        } else {
            setValue(key, value);
        }
    }

    function handleTermUpdate(_, newTerm) {
        setValue('Term', newTerm);
        if (newTerm?.toLowerCase().includes('rockies')) {
            setValue('timezone', 'America/Denver');
        }
    }

    const templateOptions = useMemo(() => profileOptions.map(profile => profile.name), []);

    return (
        <Grid container alignItems='center' sx={{ p: 2, }} spacing={2} columns={10}>

            <Grid item md={2}>
                <FormAutocomplete
                    autoHighlight
                    openOnFocus
                    options={templateOptions}
                    control={control}
                    name="Term"
                    disableClearable
                    disabled={disabled}
                    onChange={handleTermUpdate}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Template"
                            size='small'
                            error={!!errors.Term}
                            helperText={errors.Term?.message}
                            fullWidth
                        />
                    )}
                    renderOption={(props, option) => {
                        const tooltip = profileOptions.find(profile => profile.name === option)?.tooltip;
                        return (
                            <Tooltip placement='right' title={tooltip}>
                                <ListItem {...props} disablePadding>
                                    <ListItemText primary={option} />
                                </ListItem>
                            </Tooltip>
                        );
                    }}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('mwOn')}
                    onBlur={e => {
                        if (!e.target.value) {
                            setValue('mwOn', 0);
                        }
                    }}
                    label="MW On"
                    size='small'
                    fullWidth
                    disabled={disabled}
                    error={!!errors.mwOn}
                    helperText={errors.mwOn?.message}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">MW</InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: !!getValues('mwOn') || getValues('mwOn') === 0
                    }}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('priceOn')}
                    label="Price On"
                    size='small'
                    fullWidth
                    disabled={disabled}
                    error={!!errors.priceOn}
                    helperText={errors.priceOn?.message}
                    onBlur={e => {
                        if (!e.target.value) {
                            setValue('priceOn', 0);
                        }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: !!getValues('priceOn') || getValues('priceOn') === 0
                    }}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('mwOff')}
                    label="MW Off"
                    size='small'
                    fullWidth
                    disabled={disabled}
                    error={!!errors.mwOff}
                    helperText={errors.mwOff?.message}
                    onBlur={e => {
                        if (!e.target.value) {
                            setValue('mwOff', 0);
                        }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">MW</InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: !!getValues('mwOff') || getValues('mwOff') === 0
                    }}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('priceOff')}
                    label="Price Off"
                    size='small'
                    fullWidth
                    disabled={disabled}
                    error={!!errors.priceOff}
                    helperText={errors.priceOff?.message}
                    onBlur={e => {
                        if (!e.target.value) {
                            setValue('priceOff', 0);
                        }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: !!getValues('priceOff') || getValues('priceOff') === 0
                    }}
                />
            </Grid>

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormDateTimePicker
                        name='startDateTime'
                        control={control}
                        disabled={disabled}
                        label="Profile Start"
                        onAccept={(newValue) => handleDateUpdate('startDateTime', newValue?.startOf('hour'))}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.startDateTime,
                                helperText: errors.startDateTime?.message,
                                onBlur: (e) => {
                                    handleDateUpdate('startDateTime', dayjs(e.target.value).startOf('hour'));
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormDateTimePicker
                        name='stopDateTime'
                        control={control}
                        label="Profile Stop"
                        onAccept={(newValue) => handleDateUpdate('stopDateTime', newValue?.startOf('hour'))}
                        disabled={disabled}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.stopDateTime,
                                helperText: errors.stopDateTime?.message,
                                onBlur: (e) => {
                                    handleDateUpdate('stopDateTime', dayjs(e.target.value).startOf('hour'));
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <FormAutocomplete
                    autoHighlight
                    openOnFocus
                    disabled={disabled || term?.toLowerCase().includes('rockies')}
                    options={['America/Detroit', 'America/Chicago', 'America/Denver', 'America/Los_Angeles',]}
                    getOptionLabel={(option) => renderDayjsTimezones[option]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                        />
                    )}
                />
            </Grid>

            {/* abitrary comments */}

            {/*<Grid item xs={5}>
                    <ButtonGroup fullWidth>
                        {profileOptions.map(profile => (
                            <Tooltip key={profile.name} title={profile.tooltip} arrow>
                                <Button
                                    key={profile.name}
                                    variant='contained'
                                    onMouseDown={handleSubmit(handleUpdateProfile(profile.name), onError)}
                                    disabled={disabled}
                                    sx={{ backgroundColor: selectedProfile === profile.name ? palette.dark : palette.main, }}
                                >{profile.name}</Button>
                            </Tooltip>
                        ))}
                    </ButtonGroup>
                </Grid>*/}

            <Grid item md={2}>
                <TextField
                    {...register('adder')}
                    onBlur={e => {
                        const value = e.target.value;
                        setValue('adder', value ? parseFloat(value).toFixed(2) : '');
                    }}
                    label="Adder"
                    size='small'
                    fullWidth
                    disabled={(indexType === 'None') || !dynamic || disabled}
                    error={!!errors.adder}
                    helperText={errors.adder?.message}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        setValue('Term', '');
                        setProfile([]);
                    }}
                    disabled={disabled}
                >Clear All</Button>
            </Grid>

        </Grid>
    );
}