import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack'
import { HubProvider } from './components/HubContext/hubProvider.js';
import { HelmetProvider } from 'react-helmet-async';
import { IPProvider } from './components/IPContext/IPProvider.js';
import { RouterProvider, } from 'react-router-dom';
import './styles/cardStyles.css';
import './styles/gridRowStyles.css';
import './styles/muiStyles.css';
import { ColorThemeProvider } from './styles/ColorMode/ColorThemeProvider.js';
import { router } from './routeConfig.js';
import './styles/powerstationStyles.css';
import { StyledSnackbar } from './components/StyledSnackbar.js';
import { DurableFunctionProvider } from './components/DurableFunctionContext/durableFunctionProvider.js';
import { UserInfoProvider } from './components/UserInfoContext.js';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfigAzureAD } from './authConfig';

const msalInstanceAzureAD = new PublicClientApplication(msalConfigAzureAD);

msalInstanceAzureAD.handleRedirectPromise().then((response) => {
  if (response !== null) {
    msalInstanceAzureAD.setActiveAccount(response.account);
  }
}).catch((error) => {
  console.error(error);
});

const SnackbarComponents = {
  success: StyledSnackbar,
  error: StyledSnackbar,
  info: StyledSnackbar,
};


function App() {
  return (
    <div>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ColorThemeProvider>
            <SnackbarProvider maxSnack={5} Components={SnackbarComponents} preventDuplicate>
              <HubProvider>
                <DurableFunctionProvider>
                  <IPProvider>
                    <UserInfoProvider>
                      <RouterProvider router={router} />
                    </UserInfoProvider>
                  </IPProvider>
                </DurableFunctionProvider>
              </HubProvider>
            </SnackbarProvider>
          </ColorThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
