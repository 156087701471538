import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { b2cPolicies, msalConfig } from "../authConfig";
import { useLocation } from "react-router-dom";
import { useActionAudit } from './useActionAudit';

export const RegisterButton = (props) => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();
    const app = location.pathname.split('/')[1];
    const { logAction } = useActionAudit();

    async function handleSignUp() {
        logAction('User clicked Sign Up', `${app}`);
        await instance.handleRedirectPromise();
        instance.loginRedirect({
            authority: b2cPolicies.authorities.signUp.authority,
            redirectUri: setRedirectUri(),
        }).catch(e => {
            console.error(e);
        });
    }

    //try to redirect users back to the page they were on before signing in
    //currently we can only redirect back to fastpath, transhunter, or the home page since these are the only ones configured in Azure
    //add URIs in azure before adding more here. -ecz 9/1/22
    function setRedirectUri() {
        const href = window.location.href;
        const base = msalConfig.auth.redirectUri;
        if (href.includes('/fastpath')) {
            return (`${base}/fastpath`);
        } else if (href.includes('transhunter')) {
            return (`${base}/transhunter`);
        } else {
            return base;
        }
    }

    return (
        <Button
            disabled={isAuthenticated}
            {...props}
            onClick={handleSignUp}
        />
    )
};