import { useMemo, useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle, } from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { UnscheduledMWsGrid } from "./UnscheduledMWGrid";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { TagReportGrid } from "./TagReportGrid";
import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { styled } from '@mui/material/styles';
import { useApi } from "../../useApi";
import { SchedulingRibbon } from "./Ribbon/SchedulingRibbon";
import * as yup from 'yup';
import { ViewContextProvider } from "../ViewContextProvider";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useAbortController } from "../../useAbortController";
import { useLayoutFunctions } from "../../useLayoutFunctions";
import { isLongDay } from '../Utils';
import { useUserGroups } from "../../../data/useUserGroups";
import { userGroups } from "../../../authConfig";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
});

export const SchedulingView = forwardRef(({ view, }, ref) => {
    const theme = useTheme();
    const controlPanelFormId = 'deal-rizz-scheduling-control-panel-form';
    const [selectedPurchases, setSelectedPurchases] = useState([]);
    const [selectedSales, setSelectedSales] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [sourceSinkOptions, setSourceSinkOptions] = useState({});
    const { get, headers, apiUrlPrefix, logAction, handleErrorResponse } = useApi();
    const [unscheduleMwData, setUnscheduleMwData] = useState();
    const [tagData, setTagData] = useState();
    const tagReportGridRef = useRef();
    const purchaseGridRef = useRef();
    const saleGridRef = useRef();
    const [search] = useSearchParams();
    const [marketPathFilterData, setMarketPathFilterData] = useState([]);
    const { abort: abortTagFetch, abortControllerRef: tagAbortControllerRef } = useAbortController();
    const { abort: abortUnscheduledMWFetch, abortControllerRef: unscheduledMWAbortControllerRef } = useAbortController();
    const { captureLayout } = useLayoutFunctions();
    const { userIsInGroup } = useUserGroups();
    const isPlatinumUser = true;//userIsInGroup(userGroups.dealrizzplatinum); //Later we will restrict this to platinum users
    const filterHelper = isPlatinumUser && (search.get('filterHelper') === 'true');

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        startDate: dayjs(),
    }), []);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: captureAllLayouts, //TODO
        };
    });

    function captureAllLayouts() {
        return {
            tags: captureLayout(tagReportGridRef),
            sales: captureLayout(saleGridRef),
            purchases: captureLayout(purchaseGridRef),
        }
    }

    useEffect(() => {
        fetchMarketPathFilterHelper();
    }, []);

    async function fetchMarketPathFilterHelper() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchMarketPathFilterHelper`
            + `&parm=${headers.userGuid}`

        return get(url).then(response => {
            let newData = response?.data ?? [];
            setMarketPathFilterData(newData);
        }).catch(e => handleErrorResponse(e, url));
    }

    function handleFetchData(data) {
        const { startDate, } = data;
        toggleExtraHourColBasedOnDate(startDate);
        fetchTagData(data);
        fetchUnscheduledMWData(data);
    }

    function toggleExtraHourColBasedOnDate(date) {
        const isLong = isLongDay(date);
        [purchaseGridRef, saleGridRef, tagReportGridRef].forEach(gridRef => {
            gridRef.current?.columnApi?.setColumnVisible('2*', isLong);
        });
    }

    const fetchTagData = useCallback(async (data) => {
        logAction('User fetched Tag Report Data', 'Tag Reports', data);
        const { timezone, startDate, stopDate, } = data;
        tagReportGridRef.current?.api?.showLoadingOverlay();
        abortTagFetch(); //Cancel any pending fetch

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_FetchSummary_for_scheduling_v5`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate ?? startDate).format('MM/DD/YYYY')}`;

        return axios.get(url, { headers, signal: tagAbortControllerRef.current.signal }).then(response => {
            const newData = response?.data ?? [];
            setTagData(newData);

            if (!!setSourceSinkOptions) {
                const options = newData.reduce((acc, next) => {
                    acc.sources.add(next.Source);
                    acc.sinks.add(next.Sink);
                    return acc;
                }, { sources: new Set(), sinks: new Set() });
                setSourceSinkOptions({ sources: Array.from(options.sources), sinks: Array.from(options.sinks) });
            }
        }).catch(err => handleErrorResponse(err, url)).finally(() => {
            tagReportGridRef.current.api?.deselectAll();
        });
    }, [headers]);

    const fetchUnscheduledMWData = useCallback(async (data) => {
        logAction('User fetched Unscheduled MWs Data on Scheduling screen', 'Unscheduled MWs', data);
        const { timezone, startDate, } = data;
        purchaseGridRef.current?.api?.showLoadingOverlay();
        saleGridRef.current?.api?.showLoadingOverlay();
        abortUnscheduledMWFetch(); //Cancel any pending fetch

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchUnscheduledMWForScheduling_v5`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(startDate).format('YYYY-MM-DD')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers, signal: unscheduledMWAbortControllerRef.current.signal }).then(response => {
            let newData = response?.data ?? [];
            setUnscheduleMwData(newData);
        }).catch(err => handleErrorResponse(err, url));
    }, [headers]);

    const selectedRows = useMemo(() => {
        return {
            purchases: selectedPurchases,
            sales: selectedSales,
            tag: selectedTag[0],
        }
    }, [selectedPurchases, selectedSales, selectedTag]);

    const purchaseData = useMemo(() => {
        return unscheduleMwData?.filter(row => row.Transaction_Type === 'Purchase');
    }, [unscheduleMwData]);

    const saleData = useMemo(() => {
        return unscheduleMwData?.filter(row => row.Transaction_Type === 'Sale');
    }, [unscheduleMwData]);

    const filteredPurchaseData = useMemo(() => {
        if (!filterHelper) return purchaseData;
        let filteredData = purchaseData;
        if (selectedTag.length > 0) {
            const marketPath = selectedTag[0]['Market Path'];
            const allowedCounterparties = marketPathFilterData.filter(data => data.MarketPath === marketPath && data.Transaction_Type === 'Purchase').map(data => data.Counterparty);
            filteredData = filteredData?.filter(row => allowedCounterparties.includes(row.Counterparty));
        }
        return filteredData;
    }, [purchaseData, selectedTag, selectedSales, filterHelper, marketPathFilterData]);

    const filteredSaleData = useMemo(() => {
        if (!filterHelper) return saleData;
        let filteredData = saleData;
        if (selectedTag.length > 0) {
            const marketPath = selectedTag[0]['Market Path'];
            const allowedCounterparties = marketPathFilterData.filter(data => data.MarketPath === marketPath && data.Transaction_Type === 'Sale').map(data => data.Counterparty);
            filteredData = filteredData?.filter(row => allowedCounterparties.includes(row.Counterparty));
        }
        return filteredData;
    }, [saleData, selectedTag, selectedPurchases, filterHelper, marketPathFilterData]);

    const filteredTagData = useMemo(() => {
        if (!filterHelper) return tagData;
        let filteredData = tagData;
        if (selectedPurchases.length > 0) {
            const allowedMarketPaths = marketPathFilterData.filter(data => selectedPurchases.some(purchase => data.Counterparty === purchase.Counterparty && data.Transaction_Type === 'Purchase')).map(data => data.MarketPath);
            filteredData = filteredData?.filter(row => allowedMarketPaths.includes(row['Market Path']));
        }
        if (selectedSales.length > 0) {
            const allowedMarketPaths = marketPathFilterData.filter(data => selectedSales.some(sale => data.Counterparty === sale.Counterparty && data.Transaction_Type === 'Sale')).map(data => data.MarketPath);
            filteredData = filteredData?.filter(row => allowedMarketPaths.includes(row['Market Path']));
        }
        return filteredData;
    }, [tagData, selectedPurchases, selectedSales, filterHelper, marketPathFilterData]);

    function handleClearSelections() {
        tagReportGridRef.current.api?.deselectAll();
        purchaseGridRef.current.api?.deselectAll();
        saleGridRef.current.api?.deselectAll();
    }

    const unscheduleMWPurchaseContext = useMemo(() => ({
        ...view,
        layout: view.layout?.purchases
    }), [view]);

    const unscheduleMWSaleContext = useMemo(() => ({
        ...view,
        layout: view.layout?.sales
    }), [view]);

    const tagReportContext = useMemo(() => ({
        ...view,
        layout: view.layout?.tags
    }), [view]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 0.5, height: '86vh' }}>
                <SchedulingRibbon
                    formId={controlPanelFormId}
                    selectedRows={selectedRows}
                    sourceSinkOptions={sourceSinkOptions}
                    onSubmit={handleFetchData}
                    handleClearSelections={handleClearSelections}
                />
                <Box sx={{ width: '100%', display: 'flex', flexGrow: 1, }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                        <Typography variant='title' fontSize='0.75rem' fontWeight='bold'>Purchases</Typography>
                        <Box style={{ paddingRight: theme.spacing(0.5), height: '100%' }}>
                            <UnscheduledMWsGrid
                                id='purchases'
                                setSelectedRows={setSelectedPurchases}
                                data={filteredPurchaseData}
                                ref={purchaseGridRef}
                                selectedRows={selectedPurchases}
                                context={unscheduleMWPurchaseContext}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                        <Typography variant='title' fontSize='0.75rem' fontWeight='bold'>Sales</Typography>
                        <Box style={{ paddingLeft: theme.spacing(0.5), height: '100%' }}>
                            <UnscheduledMWsGrid
                                id='sales'
                                setSelectedRows={setSelectedSales}
                                data={filteredSaleData}
                                ref={saleGridRef}
                                selectedRows={selectedSales}
                                context={unscheduleMWSaleContext}
                            />
                        </Box>
                    </Box>
                </Box>
                <Accordion
                    defaultExpanded
                    disableGutters
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandLess />}
                        sx={{ minHeight: '24px', py: theme.spacing(0.5) }}
                    >
                        <Typography>Tags</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ p: 0, }}>
                        <AgGridContainer style={{ height: '35vh' }}>
                            <TagReportGrid
                                ref={tagReportGridRef}
                                setSelectedRows={setSelectedTag}
                                selectedRows={selectedTag}
                                data={filteredTagData}
                                context={tagReportContext}
                            />
                        </AgGridContainer>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </ViewContextProvider>
    );
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));
