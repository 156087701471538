import { TextField, Dialog, Stack, FormControlLabel, DialogContent, DialogTitle, Typography, Box, IconButton, DialogActions, Button, Autocomplete } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ProfileEditor from "./ProfileEditor";
import { useMemo, useState, useEffect } from "react";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useProfileTemplates } from "./useProfileTemplates_v2";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormCheckbox from "../../../FormControls/FormCheckbox";
import { useSnackbar } from "notistack";

const schema = yup.object().shape({ //TODO: min and max dates not working
    //startDateTime: date().required('Start date is required').min(defaults.startDateTime?.format(formatString), 'Start date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Start date is outside acceptble range.'),
    //stopDateTime: date().required('Stop date is required').min(defaults.startDateTime.format(formatString), 'Stop date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Stop date is outside acceptble range.'),
    Term: yup.string().required('Term is required'),
    timezone: yup.string().required('Timezone is required'),
    mwOn: yup.number().required('MW On is required').typeError('MW On must be a number.').min(0, 'MW On must be greater than or equal to 0'),
    priceOn: yup.number().required('Price On is required').typeError('Price On must be a number.'),
    mwOff: yup.number().required('MW Off is required').typeError('MW Off must be a number.').min(0, 'MW Off must be greater than or equal to 0'),
    priceOff: yup.number().required('Price Off is required').typeError('Price Off must be a number.'),
    adder: yup.string().test(
        'is-falsey-or-numeric',
        'Adder must be a number.',
        (value) => !value || !isNaN(value),
    ),
    indexType: yup.string().required('Index Type is required'),
    forecast: yup.string().required('Forecast is required'),
})

export const ProfileEditorDialog = ({ open, handleClose, closeDialog, dropdownOptions, editMode }) => {
    const formId = 'profile-editor-form';
    const { getValues: getDefaultValues, formState: { errors, }, } = useFormContext();
    const [defaultProfile = [], defaultTimezone, defaultTerm, indexType = 'None', forecast = 'None', dynamic = false, adder = '',] = getDefaultValues(['profile', 'timezone', 'Term', 'indexType', 'forecast', 'dynamic', 'adder']);
    const [profile, setProfile] = useState(defaultProfile);
    const { enqueueSnackbar } = useSnackbar();

    const defaults = useMemo(() => {
        const firstStartDate = defaultProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.startDateTime).isBefore(dayjs(acc))) {
                return dayjs(block.startDateTime);
            }
            return acc;
        }, null);

        const lastStopDate = defaultProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.endDateTime).isAfter(dayjs(acc))) {
                return dayjs(block.endDateTime);
            }
            return acc;
        }, null);

        return {
            startDateTime: firstStartDate ?? dayjs().startOf('day'),
            stopDateTime: lastStopDate ?? dayjs().add(1, 'day').startOf('day'),
            mwOn: 0,
            mwOff: 0,
            priceOn: 0,
            priceOff: 0,
            timezone: defaultTimezone ?? 'America/Los_Angeles',
            Term: defaultTerm,
            indexType: ['null', 'undefined', null].includes(indexType) ? 'None' : indexType,
            forecast: ['null', 'undefined', null].includes(forecast) ? 'None' : forecast,
            dynamic: !!dynamic,
            adder: adder ?? '',
        }
    }, [defaultProfile, defaultTerm]);

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });

    const { handleSubmit, watch, control, getValues, reset, clearErrors, setValue } = methods;

    const { generateTransmissionBlocksUsingTemplate, updateProfileDataWithTimezone } = useProfileTemplates();

    useEffect(() => {
        const subscription = watch(handleSubmit(handleUpdateProfile, onError));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch, profile]);

    useEffect(() => {
        editMode && setProfile(defaultProfile);
        clearErrors();
    }, [open]);

    useEffect(() => {
        if (!editMode && !defaultProfile.length) {
            setValue('Term', defaultTerm);
            const tz = defaultTerm?.toLowerCase().includes('rockies') ? 'America/Denver' : defaultTimezone;
            setValue('timezone', tz);
        }
    }, [defaultTerm]);

    function handleCancel() {
        reset(defaults);
        closeDialog();
    }

    const handleUpdateProfile = (formData) => {
        let targetTz = editMode ? getValues('timezone') : defaultTimezone;
        let referenceTz = defaultTimezone;

        const term = getValues('Term');
        if (term?.toLowerCase().includes('rockies')) {
            targetTz = 'America/Denver';
            referenceTz = 'America/Los_Angeles';
        }

        let unsortedData = [];
        if (editMode) {
            //in edit mode we don't want to overwrite the profile, just update the data to fit the start and stop times and apply the new timezone
            unsortedData = updateProfileDataWithTimezone(formData, profile, referenceTz, targetTz);
        } else {
            unsortedData = generateTransmissionBlocksUsingTemplate(formData, referenceTz, targetTz);
        }

        const newData = unsortedData.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)));
        setProfile(newData);
    }

    function onSubmit(data) {
        handleClose({
            profile,
            ...data
        });
    }

    function onError(errors) {
        if (!!errors.profile) {
            enqueueSnackbar('No profile data - use the template buttons to add data to the grid.', { variant: 'error' });
        }
    }

    return (
        <FormProvider {...methods}>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                keepMounted
                component='form'
                id={formId}
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <DialogTitle sx={{ pb: 0, pt: 1, px: 2 }}>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' sx={{ flexGrow: 1, }}>Edit Profile</Typography>
                        <Box sx={{ flexGrow: 1, }} />
                        <FormControlLabel
                            control={<FormCheckbox
                                name='dynamic'
                                control={control}
                            />}
                            label="Dynamic"
                        />
                        <FormAutocomplete
                            control={control}
                            name={'indexType'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['indexType'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Index Type"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                    error={!!errors.indexType}
                                    helperText={errors.indexType?.message}
                                />
                            )}
                        />
                        <FormAutocomplete
                            control={control}
                            name={'forecast'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['forecast'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Forecast"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                    error={!!errors.forecast}
                                    helperText={errors.forecast?.message}
                                />
                            )}
                        />
                        <IconButton onClick={handleCancel} size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ height: '75vh', pb: 0, px: 0.5, }}>
                    <ProfileEditor
                        disabled={false}
                        profile={profile}
                        setProfile={setProfile}
                        editMode={editMode}
                    />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                    <Button onClick={handleCancel} color='error'>Cancel</Button>
                    <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}